import React from 'react';
import { Grid } from '@mui/material';
import Logo from '../../assets/images/sh_logo.svg';

interface LogoComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  isCommonClass?: boolean; // Add a prop to determine if the common class should be used
}

const LogoComponent: React.FC<LogoComponentProps> = ({
  isCommonClass,
  ...props
}) => {
  // Set the class name based on the isCommonClass prop
  const logoClassName = isCommonClass ? 'signupflowLogo' : 'logo';

  return (
    <Grid
      container
      spacing={2}
      sx={{ justifyContent: 'flex-start', alignItems: 'center' }}
      marginBottom={3}
      {...props}
    >
      <Grid item>
        <img src={Logo} alt="Logo" className={logoClassName} />{' '}
        {/* Use the determined class name */}
      </Grid>
    </Grid>
  );
};

export default LogoComponent;
