import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import FormField from '../common/FormField';
import CustomButton from '../common/CustomButton';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import EmailIcon from '../../assets/images/email-logo.svg';
import PersonIcon from '../../assets/images/name-logo.svg';
import PhoneIcon from '../../assets/images/phone-icon.svg';

import {
  formatPhoneNumber,
  formatPhoneNumberForSubmission,
  validateEmail,
  validateName,
  validatePhoneNumber,
} from '../../utils/commonFunctions';
import infoPng from '../../assets/images/icons/InfoOutlined.png';
import {
  IBeneficiary,
  IBeneficiaryRequestBody,
} from '../../pages/onboarding/onboarding.type';
import OnboardingService from '../../pages/onboarding/onboarding.service';
import { useHttpClient } from '../../hooks/usecorrelation';
import { useDispatch, useSelector } from 'react-redux';
import SuccessMsgInfo from '../common/SuccessMsgInfo';
import { appSliceActions } from '../../redux-store/reducers/appReducer';
import ErrorMessageInfo from '../common/ErrorMsgInfo';
import { beneficiarySliceActions } from '../../redux-store/reducers/beneficiaryReducer';
import { IUserData } from '../../pages/auth/auth.type';
import '../../pages/onboarding/onboarding.css';
import i18n from '../../i18n';
import { color } from 'framer-motion';

type IFormInput = {
  name: string;
  email: string;
  phoneNo: string;
  relationship: string;
  finalInstructions?: string;
};

const AddEditBeneficiary = () => {
  const { t } = useTranslation();
  const httpClient = useHttpClient();
  const dispatch = useDispatch();
  const location = useLocation();
  const editBeneficiary: IBeneficiary = useSelector(
    (state: any) => state.beneficiary.editBeneficiary
  );
  const userData: IUserData = useSelector((state: any) => state.auth.loginData);
  const [relationShipType, setRelationShipType] = React.useState(0);
  const beneficiaries: IBeneficiary[] = useSelector(
    (state: any) => state.beneficiary.beneficieriesList
  );
  const [message, setMessage] = useState('');
  const [errMessage, setErrMsg] = useState('');
  const formRef = useRef<HTMLFormElement>(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    clearErrors,
  } = useForm<IFormInput>({
    defaultValues: {
      email: '',
      name: '',
      phoneNo: '',
      relationship: '',
      finalInstructions: '',
    },
    mode: 'onChange',
  });

  const email = watch('email');
  const phoneNo = watch('phoneNo');
  const name = watch('name');
  const relationShipOption = [
    t('placeholder.SELECT'),
    t('label.Spouse'),
    t('label.Parent'),
    t('label.Child'),
    t('label.Relative'),
    t('label.Other'),
  ];

  useEffect(() => {
    if (editBeneficiary) {
      setValue('email', editBeneficiary.email);
      setValue('name', editBeneficiary.name);
      setValue('phoneNo', formatPhoneNumber(editBeneficiary.phoneNumber));
      const relationshipIndex = relationShipOption.indexOf(
        editBeneficiary.relationship
      );
      setValue('finalInstructions', editBeneficiary.wishNote);
      setRelationShipType(relationshipIndex !== -1 ? relationshipIndex : 0);
      setMessage('');
      setErrMsg('');
    } else {
      reset();
      setRelationShipType(0);
    }
  }, [editBeneficiary]);

  useEffect(() => {
    if (message || errMessage) {
      const timer = setTimeout(() => {
        setMessage('');
        setErrMsg('');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message, errMessage]);

  useEffect(() => {
    const handleClickInsideForm = (event: MouseEvent) => {
      if (formRef.current && formRef.current.contains(event.target as Node)) {
        setMessage('');
        setErrMsg('');
      }
    };
    document.addEventListener('mousedown', handleClickInsideForm);
    return () => {
      document.removeEventListener('mousedown', handleClickInsideForm);
    };
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setRelationShipType(Number(event.target.value));
  };

  const onSubmit = async (res: IFormInput) => {
    setMessage('');
    setErrMsg('');
    const beneficiaryDetails: IBeneficiaryRequestBody = {
      beneficiaryId: editBeneficiary?.beneficiaryId,
      name: res.name,
      email: res.email,
      phoneNumber: formatPhoneNumberForSubmission(res.phoneNo),
      relationship: relationShipOption[relationShipType],
      wishNote: res.finalInstructions || '',
      userId: userData.userId,
    };
    const existingBeneficiaryIndex = beneficiaries?.length
      ? beneficiaries
          .filter((x) => x.beneficiaryId !== beneficiaryDetails.beneficiaryId)
          .findIndex(
            (beneficiary) =>
              beneficiary.name === beneficiaryDetails.name ||
              beneficiary.email === beneficiaryDetails.email ||
              beneficiary.phoneNumber === beneficiaryDetails.phoneNumber
          )
      : -1;

    if (existingBeneficiaryIndex !== -1) {
      setErrMsg(i18n.t('duplicateBeneficiary'));
    } else {
      dispatch(appSliceActions.setIsLoader(true));
      const beneficiaryReqBody: IBeneficiaryRequestBody[] = [
        beneficiaryDetails,
      ];
      const response = await OnboardingService.postBeneficiaryData(
        httpClient,
        beneficiaryReqBody
      );
      if (response === 'success') {
        if (editBeneficiary) {
          setMessage(t('beneficiaryUpdated'));
        } else {
          setMessage(t('beneficiaryAddedSuccessfully'));
        }
        setValue('email', '');
        setValue('name', '');
        setValue('phoneNo', '');
        setValue('finalInstructions', '');
        setRelationShipType(0);
        dispatch(beneficiarySliceActions.setEditBeneficiary(null));
      } else if (response.includes('phoneNumber')) {
        setErrMsg(`${t('invalidPhoneNumber')}`);
      } else {
        setErrMsg(`${response}`);
        reset();
        setRelationShipType(0);
        dispatch(beneficiarySliceActions.setEditBeneficiary(null));
      }
      dispatch(appSliceActions.setIsLoader(false));
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.replace(/^\s+/, '');
    setValue(event.target.name as any, trimmedValue);
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value.replace(/\D/g, '');
    let formattedValue = formatPhoneNumber(inputValue);
    setValue('phoneNo', formattedValue);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    reset();
    // reset({
    //   userId: userData.userId,
    //   contactId: '',
    //   contactType: 'Select',
    //   email: '',
    //   name: '',
    //   phoneNo: '',
    //   firm: '',
    // });
    clearErrors();
    setRelationShipType(0);
    setMessage('');
    setErrMsg('');
    dispatch(beneficiarySliceActions.setEditBeneficiary(null));
  };

  const isDashboardRoute = location.pathname === '/dashboard';

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height={isDashboardRoute ? '100%' : '100%'}
      position="relative"
    >
      <Box>
        <Typography className="beneficiaryInfo">
          {editBeneficiary
            ? `Edit ${t('beneficiaryInfo')} `
            : t('beneficiaryInfo')}
        </Typography>
        <Box
          component="form"
          ref={formRef}
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: '100%',
            maxWidth: {
              xs: '100%',
              sm: '100%',
            },
          }}
        >
          <FormField
            name="name"
            control={control}
            label={t('label.name')}
            placeholder={t('placeholder.namePlaceholder')}
            error={errors.name?.message}
            icon={PersonIcon}
            rules={{
              required: t('nameRequired'),
              validate: (name) => validateName(name, t),
              maxLength: {
                value: 50,
                message: t('maxLengthExceeded', { max: 50 }),
              },
            }}
            handlePhoneNumberChange={handleInputChange}
            type="text"
          />

          <FormField
            name="email"
            control={control}
            label={t('label.email')}
            placeholder={t('placeholder.emailPlaceholder')}
            error={errors.email?.message}
            icon={EmailIcon}
            rules={{
              required: t('emailRequired'),
              validate: (email) => validateEmail(email, t),
              maxLength: {
                value: 50,
                message: t('maxLengthExceeded', { max: 50 }),
              },
            }}
            type="text"
            handlePhoneNumberChange={handleInputChange}
          />

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg={isDashboardRoute ? 6 : 12}>
              <FormField
                name="phoneNo"
                control={control}
                label={t('label.phoneNumber')}
                placeholder={t('placeholder.phonePlaceholder')}
                error={errors.phoneNo?.message}
                icon={PhoneIcon}
                rules={{
                  required: t('phoneNumberRequired'),
                  validate: (phoneNo) => validatePhoneNumber(phoneNo, t),
                  maxLength: {
                    value: 14,
                    message: t('maxLengthExceeded', { max: 14 }),
                  },
                }}
                type="text"
                handlePhoneNumberChange={handlePhoneNumberChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={isDashboardRoute ? 6 : 4}
              sx={{ mt: isDashboardRoute ? '-23px' : '-16px' }}
            >
              <FormControl className="form-control" fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  className="input-label"
                  sx={{ color: '#252B42' }}
                >
                  {t('label.relationship')}
                  <span>*</span>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="relationship"
                  label={`${t('label.relationship')} *`}
                  value={relationShipType.toString()}
                  onChange={handleChange}
                  className="custom-select"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#D0D5DD', // Default border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#0047FF', // Change border color on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#0047FF', // Change border color when focused
                    },
                  }}
                >
                  {relationShipOption.map((item, index) => (
                    <MenuItem key={index} value={index} className="menu-item">
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!isDashboardRoute && (
              <Grid item xs={12} lg={8} sx={{ mt: '-16px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2,
                  }}
                >
                  <CustomButton
                    text={t('button.cancel')}
                    buttonType="secondary"
                    handleClick={(e) => handleCancel(e)}
                    style={{
                      width: '100%',
                    }}
                  />
                  <CustomButton
                    className="Custombutton"
                    text={
                      editBeneficiary
                        ? `${t('button.save')}`
                        : `${t('button.add')}`
                    }
                    disabled={
                      !email || !name || !phoneNo || relationShipType === 0
                    }
                  />
                </Box>
              </Grid>
            )}
          </Grid>

          {isDashboardRoute && (
            <Grid container marginTop={-2}>
              <Controller
                name="finalInstructions"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={
                      <span style={{ color: '#252B42' }}>
                        {t('label.finalInstructions')}
                      </span>
                    }
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      '& .MuiFormLabel-asterisk': {
                        color: 'red',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&:hover fieldset': {
                          borderColor: '#0047FF',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#0047FF',
                        },
                      },
                    }}
                  />
                )}
              />
              <Box
                className={
                  isDashboardRoute ? 'dashboardMessageBox' : 'messagebox'
                }
                zIndex={0}
                mt="350px"
              >
                <SuccessMsgInfo
                  successMsg={message}
                  style={{ paddingTop: '1rem' }}
                />
                <ErrorMessageInfo
                  errorMessage={errMessage}
                  style={{ paddingTop: '-1.5rem' }}
                />
              </Box>
            </Grid>
          )}

          {isDashboardRoute && (
            <Box
              sx={{
                width: '700px',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                mt: 2,
                position: 'absolute',
                bottom: 0,
                right: 0,
              }}
            >
              <CustomButton
                style={{
                  width: '150px',
                }}
                buttonfullWidth={false}
                text={t('button.cancel')}
                buttonType="secondary"
                handleClick={(e) => handleCancel && handleCancel(e)}
              />

              <CustomButton
                style={{
                  width: '150px',
                }}
                className="Custombutton"
                text={
                  editBeneficiary ? `${t('button.save')}` : `${t('button.add')}`
                }
                disabled={!email || !name || !phoneNo || relationShipType === 0}
              />
            </Box>
          )}
        </Box>
      </Box>

      {!isDashboardRoute && (
        <>
          <Box className="messageBox" zIndex={0} mt="350px">
            <SuccessMsgInfo
              successMsg={message}
              style={{ paddingTop: '1rem' }}
            />
            <ErrorMessageInfo
              errorMessage={errMessage}
              style={{ paddingTop: '-1.5rem' }}
            />
          </Box>
          <Box
            className="infoNote"
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={1}
            paddingTop={4}
          >
            <Box
              component="img"
              className="infoImageIcon"
              src={infoPng}
              alt="Info Icon"
            />

            <Typography className="bottomText">
              {t('youCanEditInfoAnyTime')}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AddEditBeneficiary;
