import React from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  styled,
  Paper,
  Link,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/sh_logo.svg';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '1760px !important',
    height: '850px !important',
    maxWidth: '1760px',
    maxHeight: '940px',
    borderRadius: '20px !important',
    padding: theme.spacing(4),
    margin: theme.spacing(2),
    backgroundColor: '#FFFFFF',
    overflowY: 'none',
  },
}));

const SampleBadge = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FDE3AA',
  padding: theme.spacing(1),
  display: 'inline-block',
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const Logo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  height: '100%',
  paddingRight: theme.spacing(2),
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#555',
    },
  },
}));

interface SampleEmailOneProps {
  open: boolean;
  onClose: () => void;
}

const SampleEmailOne: React.FC<SampleEmailOneProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="email-dialog-title"
    >
      <DialogContent sx={{ padding: 0, overflow: 'hidden' }}>
        <ContentWrapper>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: '#666',
              '&:hover': {
                color: '#000',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Logo>
            <img src={logo} alt="SmartHeritance Logo" />
          </Logo>

          <SampleBadge elevation={0}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              {t('sampleEmail')}
            </Typography>
          </SampleBadge>

          <Box sx={{ color: '#4A4A4A' }}>
            <Typography variant="body1" sx={{ fontWeight: 600, mb: 3 }}>
              {t('subjectImportantInformation')}
            </Typography>

            <Typography variant="body1" sx={{ mb: 2 }}>
              {t('dearBeneficiaryName')}
            </Typography>

            <Typography variant="body1" sx={{ mb: 3 }}>
              {t('weHopeThis')}
            </Typography>

            <Typography sx={{ fontWeight: 600, mb: 2, color: '#2F3751' }}>
              {t('whatYouNeed')}
            </Typography>

            <Box component="ul" sx={{ pl: 2, mb: 4 }}>
              <li>
                <Typography sx={{ mb: 2, display: 'flex' }}>
                  <strong>{t('noImmediateAction')}</strong>
                  <Typography> {t('atThisTime')}</Typography>
                </Typography>
              </li>
              <li>
                <Typography sx={{ mb: 2, display: 'flex' }}>
                  <strong>{t('addToSafeSenders')}</strong>
                  <Typography> {t('toEnsure')}</Typography>
                </Typography>
              </li>
            </Box>

            <Typography sx={{ fontWeight: 600, mb: 2, color: '#2F3751' }}>
              {t('createYourOwn')}
            </Typography>

            <Typography variant="body1" sx={{ mb: 2 }}>
              {t('ifYouWould')}
            </Typography>

            <Box component="ul" sx={{ pl: 2, mb: 3 }}>
              <li>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {t('securelyReceiveUpdates')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {t('easilyAccessThe')}
                </Typography>
              </li>
            </Box>

            <Typography variant="body1" sx={{ mb: 2 }}>
              {t('toCreateAn')}
            </Typography>

            <Typography sx={{ display: 'block', mb: 1 }}>
              {t('createMyAccount')}
            </Typography>

            <Typography
              variant="caption"
              sx={{ display: 'block', mb: 4, color: '#666' }}
            >
              {t('linkToSignUp')}
            </Typography>

            <Typography variant="body1" sx={{ mb: 3 }}>
              {t('ifYouHave')}{' '}
              <Link
                href="mailto:support@smartheritance.com"
                sx={{
                  color: '#1976d2',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {t('supportEmail')}
              </Link>
              .
            </Typography>

            <Typography variant="body1" sx={{ mb: 4 }}>
              {t('weAppreciateYour')}
            </Typography>

            <Typography variant="body1" sx={{ mb: 2 }}>
              {t('warmRegards')}
            </Typography>
          </Box>
        </ContentWrapper>
      </DialogContent>
    </StyledDialog>
  );
};

export default SampleEmailOne;
