import { createSlice } from '@reduxjs/toolkit';
import { IBeneficiary } from '../../pages/onboarding/onboarding.type';

type IInitialState = {
  beneficieriesList: IBeneficiary[];
  editBeneficiary: IBeneficiary | null;
  deleteBeneficiaryConfirm: boolean;
  onboardingScreenTime: string;
};

const initialState: IInitialState = {
  beneficieriesList: [],
  editBeneficiary: null,
  deleteBeneficiaryConfirm: false,
  onboardingScreenTime: '',
};
const beneficiarySlice = createSlice({
  name: 'beneficiarySlice',
  initialState,
  reducers: {
    setBeneficiary(state, { payload }) {
      state.beneficieriesList = payload;
    },
    setEditBeneficiary(state, { payload }) {
      state.editBeneficiary = payload;
    },
    setDeleteBeneficiaryConfirm(state, { payload }) {
      state.deleteBeneficiaryConfirm = payload;
    },
    setOnboardingScreenTime(state, { payload }) {
      state.onboardingScreenTime = payload;
    },
    resetState(state) {
      state.beneficieriesList = initialState.beneficieriesList;
      state.editBeneficiary = initialState.editBeneficiary;
      state.deleteBeneficiaryConfirm = initialState.deleteBeneficiaryConfirm;
      state.onboardingScreenTime = initialState.onboardingScreenTime;
    },
  },
});
const { actions } = beneficiarySlice;
export const beneficiarySliceActions = actions;
export default beneficiarySlice.reducer;
