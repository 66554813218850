import React from 'react';
import plusIcon from '../../assets/images/plusIcon.png';
import { Typography, Box, Button } from '@mui/material';
type IProps = {
  onClick: () => void;
  buttonName: string;
};
const AdditionButton: React.FC<IProps> = ({ onClick, buttonName }) => {
  return (
    <Box>
      <Box display="flex">
        <Button onClick={() => onClick()}>
          <img className="plusIcon" src={plusIcon} alt="plus"></img>
          <Typography className="additionDetails" fontSize="18px">
            {buttonName}
          </Typography>
        </Button>
      </Box>
      <Typography className="moreDetailsText">
        (More details makes it easier for your beneficiaries)
      </Typography>
    </Box>
  );
};
export default AdditionButton;
