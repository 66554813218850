import { TFunction } from 'i18next';

export const api = {
  postLogin: 'auth/login',
  signUp: 'auth/signup',
  socialLogin: 'auth/social-login',
  forgotPassword: 'auth/forgot-password',
  resetPassword: 'auth/reset-password',
  setPassword: 'auth/set-password',
  getOnboardingData: 'auth/onboarding',
  postBeneficiaryData: 'user/beneficiaries',
  putBeneficiaryData: 'user/beneficiaries/beneficiary/',
  getbeneficiaries: 'user/beneficiaries',
  personalization: 'user/personalization',
  postAssetsDetails: 'user/assets',
  postProfessionalData: 'user/professionalcontacts',
  putProfessionalData: 'user/professionalcontacts/professionalContact',
  getProfessionalContactsUrl: 'user/professionalcontacts',
  getAssetsType: 'assets',
  getAssetsData: 'user/assets',
  getUserAssetsPersonalizedData: 'user/assets/personalizationcombined',
  getDashboardCounts: 'user/dashboard',
  getDocument: 'user/documents/details',
  postDocument: 'user/documents/upload',
  deleteDocument: 'user/documents/delete',
  getdocumentData: 'user/documents/names',
  editWellnessCheckDuration: 'user/wellnesscheck/edit-duration',
  getWellnessCheckData: 'user/wellnesscheck',
  sendSupportEmail: '/support',
};

export const serviceProvider = {
  google: 'google',
  facebook: 'facebook',
};

export const partners = {
  smartHertance: {
    partnerName: 'SmartHertance',
    partnerCode: '1',
  },
};

export const activeOnbordingStep = [
  'onboarding_personalization',
  'onboarding_beneficiary',
  'onboarding_asset',
  'onboarding_wellness',
  'onboarding_subscription',
  'onboarding_choose',
  'onboarding_dashboard',
];

export const FreeSubscriptionDetails = (t: TFunction) => [
  '8',
  t('offerList.unlimited'),
  t('offerList.included'),
  t('offerList.included'),
  t('offerList.included'),
  '-',
  '-',
  t('offerList.freeDays'),
  '-',
  '-',
  '-',
  '-',
  t('offerList.included'),
];

export const BasicSubscriptionDetails = (t: TFunction) => [
  '10',
  t('offerList.unlimited'),
  t('offerList.included'),
  t('offerList.included'),
  t('offerList.included'),
  t('offerList.basicDays'),
  t('offerList.included'),
  t('offerList.customizable'),
  t('offerList.emailVerification'),
  t('offerList.included'),
  '3',
  '-',
  t('offerList.included'),
];

export const PremiumSubscriptionDetails = (t: TFunction) => [
  t('offerList.unlimited'),
  t('offerList.unlimited'),
  t('offerList.included'),
  t('offerList.included'),
  t('offerList.included'),
  t('offerList.premiumDays'),
  t('offerList.included'),
  t('offerList.customizable'),
  t('offerList.autoVerification'),
  t('offerList.included'),
  '10',
  t('offerList.included'),
  t('offerList.included'),
];

export const offerList = (t: TFunction) => [
  t('offerList.assets'),
  t('offerList.beneficiaries'),
  t('offerList.bankGradeSecurity'),
  t('offerList.personalizedDashboard'),
  t('offerList.proactiveBeneficiary'),
  t('offerList.assetsScans'),
  t('offerList.wellnessCheck'),
  t('offerList.personalizedCheck'),
  t('offerList.contactVerification'),
  t('offerList.criticalContacts'),
  t('offerList.uploadDocument'),
  t('offerList.lastWishInstructions'),
  t('offerList.webMobileApp'),
];

export const avatarColors = ['#C9ABAB', '#93B1CE', '#CFEECC', '#C1BC97'];

export const propertyType = {
  main: 'main',
  optional: 'optional',
  later: 'later',
};

export const assetFormProperties = {
  bankAccounts: [
    {
      name: 'name',
      label: 'label.nameofInstitution',
      placeholder: 'placeholder.bankName',
      propertyType: propertyType.main,
    },
    {
      name: 'website',
      label: 'label.website',
      placeholder: 'placeholder.website',
      propertyType: propertyType.main,
    },
    {
      name: 'accountHolder',
      label: 'label.nameOnAccount',
      placeholder: 'placeholder.aacountHolderName',
      propertyType: propertyType.optional,
    },
    {
      name: 'accountNo',
      label: 'label.accountNumber',
      placeholder: 'placeholder.account',
      propertyType: propertyType.main,
    },
  ],
  brokerageAccounts: [
    {
      name: 'name',
      label: 'label.nameofInstitution',
      placeholder: 'placeholder.brokerageName',
      propertyType: propertyType.main,
    },
    {
      name: 'website',
      label: 'label.website',
      placeholder: 'placeholder.website',
      propertyType: propertyType.main,
    },
    {
      name: 'accountHolder',
      label: 'label.nameOnAccount',
      placeholder: 'placeholder.aacountHolderName',
      propertyType: propertyType.optional,
    },
    {
      name: 'accountNo',
      label: 'label.accountNumber',
      placeholder: 'placeholder.account',
      propertyType: propertyType.main,
    },
  ],
  insurancePolicy: [
    {
      name: 'name',
      label: 'label.providerName',
      placeholder: 'placeholder.insuranceProviderName',
      propertyType: propertyType.main,
    },
    {
      name: 'website',
      label: 'label.website',
      placeholder: 'placeholder.website',
      propertyType: propertyType.main,
    },
    {
      name: 'accountHolder',
      label: 'label.nameOnAccount',
      placeholder: 'placeholder.aacountHolderName',
      propertyType: propertyType.optional,
    },

    {
      name: 'accountNo',
      label: 'label.accountNumber',
      placeholder: 'placeholder.account',
      propertyType: propertyType.main,
    },
    {
      name: 'amountInsuredFor',
      label: 'label.amountInsuredFor',
      placeholder: 'placeholder.name',
      propertyType: propertyType.later,
    },
  ],
  retirementAccounts: [
    {
      name: 'name',
      label: 'label.nameofInstitution',
      placeholder: 'placeholder.institutionName',
      propertyType: propertyType.main,
    },
    {
      name: 'website',
      label: 'label.website',
      placeholder: 'placeholder.website',
      propertyType: propertyType.main,
    },
    {
      name: 'accountHolder',
      label: 'label.nameOnAccount',
      placeholder: 'placeholder.aacountHolderName',
      propertyType: propertyType.optional,
    },
    {
      name: 'accountNo',
      label: 'label.accountNumber',
      placeholder: 'placeholder.account',
      propertyType: propertyType.main,
    },
  ],
  cryptoAccounts: [
    {
      name: 'name',
      label: 'label.nameOfExchage',
      placeholder: 'placeholder.exchangeName',
      propertyType: propertyType.main,
    },
    {
      name: 'website',
      label: 'label.website',
      placeholder: 'placeholder.website',
      propertyType: propertyType.main,
    },
    {
      name: 'accountHolder',
      label: 'label.nameOnAccount',
      placeholder: 'placeholder.aacountHolderName',
      propertyType: propertyType.optional,
    },
    {
      name: 'accountNo',
      label: 'label.accountorWallet',
      placeholder: 'placeholder.account',
      propertyType: propertyType.main,
    },
  ],
  realEstateAccount: [
    {
      name: 'address',
      label: 'label.address',
      placeholder: 'placeholder.estateAddress',
      propertyType: propertyType.main,
    },
    {
      name: 'city',
      label: 'label.city',
      placeholder: 'placeholder.city',
      propertyType: propertyType.main,
    },
    {
      name: 'state',
      label: 'label.state',
      placeholder: 'placeholder.state',
      propertyType: propertyType.main,
    },
    {
      name: 'zip',
      label: 'label.zipcode',
      placeholder: 'placeholder.zipcode',
      propertyType: propertyType.main,
    },
    {
      name: 'country',
      label: 'label.country',
      placeholder: 'placeholder.country',
      propertyType: propertyType.main,
    },
    {
      name: 'mortageProvider',
      label: 'label.mortageProvider',
      placeholder: 'placeholder.name',
      propertyType: propertyType.later,
    },
    {
      name: 'mortageWebsite',
      label: 'label.website',
      placeholder: 'placeholder.website',
      propertyType: propertyType.later,
    },
    {
      name: 'mortageLoanNumber',
      label: 'label.mortageOrLoanNumber',
      placeholder: 'placeholder.account',
      propertyType: propertyType.later,
    },
    {
      name: 'homeInsuranceProvider',
      label: 'label.homeInsuranceProvider',
      placeholder: 'placeholder.name',
      propertyType: propertyType.later,
    },
    {
      name: 'homeInsuranceWebsite',
      label: 'label.website',
      placeholder: 'placeholder.website',
      propertyType: propertyType.later,
    },
    {
      name: 'planOrPolicy',
      label: 'label.planOrPolicy',
      placeholder: 'placeholder.account',
      propertyType: propertyType.later,
    },
  ],
  vehicalAccount: [
    {
      name: 'make',
      label: 'label.make',
      placeholder: 'placeholder.vehicalMake',
      propertyType: propertyType.main,
    },
    {
      name: 'model',
      label: 'label.model',
      placeholder: 'placeholder.vehicalModel',
      propertyType: propertyType.main,
    },
    {
      name: 'licenseNumber',
      label: 'label.licenseNumber',
      placeholder: 'placeholder.vehicleLicense',
      propertyType: propertyType.main,
    },
    {
      name: 'registered',
      label: 'label.registered',
      placeholder: 'placeholder.registeredInState',
      propertyType: propertyType.main,
    },
    {
      name: 'loanProvider',
      label: 'label.loanProvider',
      placeholder: 'placeholder.autoLoanProvider',
      propertyType: propertyType.optional,
    },
    {
      name: 'website',
      label: 'label.website',
      placeholder: 'placeholder.website',
      propertyType: propertyType.main,
    },
    {
      name: 'loanNumber',
      label: 'label.loanNumber',
      placeholder: 'placeholder.vehicalLoanNumber',
      propertyType: propertyType.optional,
    },
    {
      name: 'insuranceProvider',
      label: 'label.insuranceProvider',
      placeholder: 'placeholder.institutionName',
      propertyType: propertyType.later,
    },
    {
      name: 'insuranceWebsite',
      label: 'label.website',
      placeholder: 'placeholder.vehicalWebsite',
      propertyType: propertyType.later,
    },
    {
      name: 'policyNumber',
      label: 'label.policyNumber',
      placeholder: 'placeholder.vehicalLoanNumber',
      propertyType: propertyType.later,
    },
  ],
  professionalContactAccounts: [
    {
      name: 'name',
      label: 'label.name',
      placeholder: 'placeholder.userName',
      propertyType: propertyType.main,
    },
    {
      name: 'phoneNumber',
      label: 'label.phoneNumber',
      placeholder: 'placeholder.areaCode',
      propertyType: propertyType.main,
    },
    {
      name: 'email',
      label: 'label.email',
      placeholder: 'placeholder.emailPlaceholder',
      propertyType: propertyType.main,
    },
    {
      name: 'professionalType',
      label: 'label.professionalType',
      placeholder: '',
      propertyType: propertyType.main,
    },
  ],
  safeDepositAccount: [
    {
      name: 'name',
      label: 'label.bankName',
      placeholder: 'placeholder.bankName',
      propertyType: propertyType.main,
    },
    {
      name: 'address',
      label: 'label.address',
      placeholder: 'placeholder.estateAddress',
      propertyType: propertyType.main,
    },
    {
      name: 'city',
      label: 'label.city',
      placeholder: 'placeholder.city',
      propertyType: propertyType.main,
    },
    {
      name: 'state',
      label: 'label.state',
      placeholder: 'placeholder.state',
      propertyType: propertyType.main,
    },
    {
      name: 'zip',
      label: 'label.zipcode',
      placeholder: 'placeholder.zipcode',
      propertyType: propertyType.main,
    },
    {
      name: 'country',
      label: 'label.country',
      placeholder: 'placeholder.country',
      propertyType: propertyType.main,
    },
    {
      name: 'boxNumber',
      label: 'label.boxNumber',
      placeholder: 'placeholder.boxNumber',
      propertyType: propertyType.main,
    },
  ],
};

export const assetTypes = {
  bankAccounts: 'bankAccounts',
  brokerageAccounts: 'brokerageAccounts',
  insurancePolicy: 'insurancePolicy',
  retirementAccounts: 'retirementAccounts',
  cryptoAccounts: 'cryptoAccounts',
  realEstateAccount: 'realEstateAccount',
  vehicleAccount: 'vehicalAccount',
  professionalContactAccounts: 'professionalContactAccounts',
  safeDepositAccount: 'safeDepositAccount',
  homeVaultInformation: 'homeVaultInformation',
  trustAndWillDocuments: 'trustAndWillDocuments',
};

export const showSingUpFlowFlag = {
  'Represents the login state.': 0,
  'Represents the new registration state.': 1,
  'Represents the state where the email is not verified.': 2,
  'Represents the state where no password is set.': 3,
  'Represents the state where a password is set.': 4,
};

export const subscriptionOption = {
  free: 'free',
  basic: 'basic',
  premium: 'premium',
};
