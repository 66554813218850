import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SubscriptionComponent from '../../pages/subscription/SubscriptionComponent';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionSliceActions } from '../../redux-store/reducers/subscriptionReducer';
import './welcome.css';
import BeneficiariesLogo from '../../assets/images/card1Logo.png';
import AssetsLogo from '../../assets/images/DashboardAssets.png';
import ContactsLogo from '../../assets/images/DashboardContact.png';
import DocumentsLogo from '../../assets/images/DashboardDoc.png';
import cardArrow from '../../assets/images/cardArrow.png';
import CustomCircularProgress from './customProgress';
import bank from '../../assets/images/bankAvatar.png';
import brockrage from '../../assets/images/brockrage.png';
import piggybank from '../../assets/images/piggybank.png';
import infra from '../../assets/images/infra.png';
import pdfIcon from '../../assets/images/pdfIcon.png';
import jpgIcon from '../../assets/images/jpgIcon.png';
import CustomDashboardCard from './CustomDashboardCard';
import { UseSelector } from 'react-redux';
import { IUserData } from '../../pages/auth/auth.type';
import { api } from '../../utils/constants';
import DashboardService from '../../pages/dasboard/dashboard.service';
import { appSliceActions } from '../../redux-store/reducers/appReducer';
import OnboardingService from '../../pages/onboarding/onboarding.service';
import { useHttpClient } from '../../hooks/usecorrelation';
import i18n from '../../i18n';
import BeneficiaryUserAlive from '../beneficiary/BeneficiaryUserAlive';
import { getFileIcon, getIcon } from '../../utils/commonFunctions';
import { assetsSliceActions } from '../../redux-store/reducers/assetsreducer';

interface DashboardData {
  lastLoginTime: string;
  isBeneficiary: boolean;
  beneficiary: {
    count: number;
    names: string[];
  };
  assets: {
    count: number;
    types: string[];
  };
  professionalContacts: {
    count: number;
    names: string[];
  };
  documents: {
    count: number;
    names: string[];
  };
}
interface DashboardWelcomeProps {
  onNavigate: (index: number) => void; // Add navigation prop
}

const DashboardWelcome: React.FC<DashboardWelcomeProps> = ({ onNavigate }) => {
  const [dashboardCounts, setDashboardCounts] = useState<DashboardData | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false); // For loader
  const userData: IUserData = useSelector((state: any) => state.auth.loginData);
  const httpClient = useHttpClient();
  const dispatch = useDispatch();
  const [isUser, setIsUser] = useState('User');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    return date.toLocaleDateString('en-GB', options);
  };
  useEffect(() => {
    dispatch(assetsSliceActions.setSelectedMenuLable(''));
    const fetchUserDataCount = async () => {
      try {
        setLoading(true);
        dispatch(appSliceActions.setIsLoader(true));

        const data = await DashboardService.getDashboardCounts(
          httpClient,
          userData.userId
        );
        setDashboardCounts(data);
      } catch (error) {
        console.log('Error fetching user data count', error);
      } finally {
        setLoading(false);
        dispatch(appSliceActions.setIsLoader(false));
      }
    };

    fetchUserDataCount();
  }, []);
  const handleSwitchChange = (event: any, newValue: any) => {
    setIsUser(newValue);
    if (newValue === 'beneficiary') {
      setIsDialogOpen(true);
    } else {
      setIsDialogOpen(false);
    }
  };

  return (
    <Grid className="WelcomeOuterGrid">
      <div className="WelcomeInnerDiv">
        {/* <Box display="flex" justifyContent="space-between" alignItems="center"> */}
        <Box display="flex" gap="20px" alignItems="center" height="50px">
          <Typography
            className="DashboardWelcomeHeading"
            // color="#252B42" fontSize="32px" fontWeight={600}
          >
            Welcome, {userData.userName}
          </Typography>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Typography
            className="DashboardLastUpdatedText"
            // color="#252B42" fontSize="18px" fontWeight={600}
          >
            Last updated, {formatDate(dashboardCounts?.lastLoginTime!)}
          </Typography>
        </Box>
        {dashboardCounts?.isBeneficiary && (
          <Box className="dcard-offer-btn">
            <ToggleButtonGroup
              color="primary"
              value={isUser}
              exclusive
              onChange={handleSwitchChange}
              aria-label="Platform"
              className="dtoggle-button-group"
            >
              <ToggleButton
                className={`dtoggle-left-btn ${isUser === 'User' ? 'Mui-selected' : ''}`}
                value="User"
                sx={{
                  animationDuration: '0.5s',
                  transition: 'all 0.3s ease',
                }}
              >
                {i18n.t('User')}
              </ToggleButton>
              <ToggleButton
                className={`dtoggle-right-btn ${isUser === 'beneficiary' ? 'Mui-selected' : ''}`}
                value="beneficiary"
                sx={{
                  animationDuration: '0.5s',
                  transition: 'all 0.3s ease',
                }}
              >
                {i18n.t('Beneficiary')}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}

        {/* </Box> */}
      </div>
      <SubscriptionComponent />

      <Grid
        display="flex"
        flexDirection="column"
        className="DashboardCardsContainer"
      >
        <Grid display="flex" flexDirection="row" gap="50px">
          <CustomDashboardCard
            icon={BeneficiariesLogo}
            title="Beneficiaries"
            subtitle="View, edit or add new beneficiary information."
            progressValue={dashboardCounts?.beneficiary.count}
            progressColor="#C5E0F4"
            progressTrackColor="#4FA2DD"
            bottomContent="avatars"
            avatars={dashboardCounts?.beneficiary.names.map((name, index) => ({
              initial: name.charAt(0),
              color: ['#FED5D5', '#A5CFE1', '#C3E1A5', '#DCBEE1'][index % 4],
            }))}
            onClick={() => onNavigate(1)}
          />
          <CustomDashboardCard
            icon={AssetsLogo}
            title="Assets"
            subtitle="View, edit or add new Assets Contacts"
            progressValue={dashboardCounts?.assets.count}
            progressColor="#4FA2DD"
            progressTrackColor="#C5E0F4"
            bottomContent="icons"
            icons={dashboardCounts?.assets.types.map((type, index) => ({
              src: getIcon(type),
              alt: type,
            }))}
            onClick={() => onNavigate(2)}
          />
        </Grid>
        <Grid display="flex" flexDirection="row" className="horizontalGrid">
          <CustomDashboardCard
            icon={ContactsLogo}
            title="Professional Contact"
            subtitle="View, edit or add new Professional Contact"
            progressValue={dashboardCounts?.professionalContacts.count}
            progressColor="#4FA2DD"
            progressTrackColor="#C5E0F4"
            bottomContent="avatars"
            avatars={dashboardCounts?.professionalContacts.names.map(
              (name, index) => ({
                initial: name.charAt(0),
                color: ['#FED5D5', '#A5CFE1', '#C3E1A5', '#DCBEE1'][index % 4],
              })
            )}
            onClick={() => onNavigate(3)}
          />
          <CustomDashboardCard
            icon={DocumentsLogo}
            title="Documents"
            subtitle="View, edit or add new assets information."
            progressValue={dashboardCounts?.documents.count}
            progressColor="#4FA2DD"
            progressTrackColor="#C5E0F4"
            bottomContent="documents"
            documents={dashboardCounts?.documents.names.map((name, index) => ({
              src: getFileIcon(name),
              alt: 'jpg',
            }))}
            onClick={() => onNavigate(4)}
          />
        </Grid>
        <Typography className="DashboardbottomText">
          We use bank-grade security & encryption to protect your data. All your
          information is private ONLY to you. Your loved ones can access and use
          this information only after you pass.
        </Typography>
      </Grid>
      <BeneficiaryUserAlive
        open={isDialogOpen}
        onClose={() => {
          setIsUser('User');
          setIsDialogOpen(false);
        }}
      ></BeneficiaryUserAlive>
    </Grid>
  );
};

export default DashboardWelcome;
function dispatch(arg0: { payload: any; type: 'appSlice/setIsLoader' }) {
  throw new Error('Function not implemented.');
}
