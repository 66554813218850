import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  IconButton,
  Stack,
  Button,
  TextField,
  MenuItem,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import supportIcon from '../../assets/images/supporticon.png';
import DashboardService from './dashboard.service';
import { useHttpClient } from '../../hooks/usecorrelation';
import { appSliceActions } from '../../redux-store/reducers/appReducer';
import { useDispatch } from 'react-redux';

interface SupportDialogProps {
  open: boolean;
  onClose: () => void;
}

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '600px',
    borderRadius: '12px',
    boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.25)',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
  },
}));

const SupportDialog: React.FC<SupportDialogProps> = ({ open, onClose }) => {
  const [topic, setTopic] = React.useState('');
  const [description, setDescription] = React.useState('');
  const dispatch = useDispatch();
  const httpClient = useHttpClient();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const reqbody = {
      topic: topic,
      desciption: description,
    };
    dispatch(appSliceActions.setIsLoader(true));
    await DashboardService.sendSupoprtMail(httpClient, reqbody);
    onClose();
    dispatch(appSliceActions.setIsLoader(false));
    // Handle form submission
    console.log({ topic, description });
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth={false}>
      <Box sx={{ position: 'relative' }}>
        {/* Close button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Stack spacing={3} sx={{ pt: 3, px: 2 }}>
            {/* Icon */}
            <Box display="flex" justifyContent="center">
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: '50%',
                  bgcolor: 'grey.100',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={supportIcon}></img>
              </Box>
            </Box>

            {/* Title */}
            <Typography variant="h6" align="center" sx={{ mb: 2 }}>
              Need help?
            </Typography>

            {/* Form */}
            <form onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Box>
                  <Typography
                    variant="caption"
                    component="label"
                    sx={{ mb: 1, display: 'block' }}
                  >
                    Select a topic
                    <Box component="span" sx={{ color: 'error.main' }}>
                      *
                    </Box>
                  </Typography>
                  <StyledTextField
                    select
                    fullWidth
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                    placeholder="Select"
                    required
                  >
                    <MenuItem value="general">General Inquiry</MenuItem>
                    <MenuItem value="technical">Technical Support</MenuItem>
                    <MenuItem value="billing">Billing</MenuItem>
                  </StyledTextField>
                </Box>

                <Box>
                  <Typography
                    variant="caption"
                    component="label"
                    sx={{ mb: 1, display: 'block' }}
                  >
                    Enter a brief description
                    <Box component="span" sx={{ color: 'error.main' }}>
                      *
                    </Box>
                  </Typography>
                  <StyledTextField
                    fullWidth
                    multiline
                    rows={8}
                    placeholder="Message"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                </Box>

                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  sx={{ mt: 2 }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      height: '54px',
                      width: '150px',
                      color: '#112F8E',
                      border: '1px solid #112F8E',
                    }}
                    onClick={onClose}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    sx={{
                      height: '54px',
                      width: '150px',
                      bgcolor: '#FFC107',
                      color: 'white',
                      '&:hover': {
                        bgcolor: '#FFB000',
                      },
                    }}
                  >
                    SUBMIT
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Stack>
        </DialogContent>
      </Box>
    </StyledDialog>
  );
};

export default SupportDialog;
