import PersonalizeModel from './personalize.model';

import {
  IPersonalizeReqBody,
  IProceed,
} from '../personalize-question/personalize.type';
import { api } from '../../utils/constants';

class PersonalizeService {
  static postPersonalizeDetails = async (
    client: any,
    url: string,
    reqData: IPersonalizeReqBody
  ) => {
    try {
      const response = await client.post(
        url,
        reqData,
        PersonalizeModel.PersonalizeRespSchema
      );
      if (response) {
        // Check if the result is success or failure
        if (response.errorCode !== 200) {
          return false;
        }
        return true;
      }
    } catch (error) {
      return false;
    }
  };
  static putPersonalization = async (client: any, reqbody: IProceed) => {
    try {
      const response = await client.put(
        api.personalization,
        reqbody,
        PersonalizeModel.PersonalizeUserRespSchema
      );
      if (response) {
        // Check if the result is success or failure
        if (response.errorCode === 200) {
          return true;
        }
        return false;
      }
    } catch (err) {
      return false;
    }
  };
}

export default PersonalizeService;
