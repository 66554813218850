import React from 'react';
import {
  Autocomplete,
  Box,
  InputAdornment,
  keyframes,
  TextField,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import './common.css';

interface CustomAutocompleteProps {
  name: string;
  control: any;
  options: any[];
  value?: string;
  onChange?: (event: React.ChangeEvent<{}>, newValue: any | null) => void;
  label: string;
  placeholder?: string;
  error?: string;
  required?: boolean;
  icon?: any;
  handleAutoInputChange: (e: any, key: string) => void;
  handleAutoChange: (e: any, key: string) => void;
  showRequired?: boolean;
}

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedErrorMessage = styled(Typography)`
  animation: ${fadeInUp} 0.3s ease-out;
`;

const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({
  name,
  options,
  value,
  onChange,
  label,
  placeholder,
  error,
  required,
  icon,
  handleAutoInputChange,
  handleAutoChange,
  showRequired = true,
}) => {
  return (
    <Box sx={{ marginBottom: '23px' }}>
      <Autocomplete
        className="autocompleteContainer"
        sx={{ paddingRight: '0 !important' }}
        freeSolo
        options={options.map((city) => city.label)}
        value={value || ''}
        onChange={(e, newValue) => {
          handleAutoChange(newValue, name);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            autoComplete="off"
            name={name}
            label={
              <span className="formfieldLabel" style={{ color: '#252B42' }}>
                {label}{' '}
                {showRequired && (
                  <span style={{ color: '#FF4444', marginLeft: '-4px' }}></span>
                )}
              </span>
            }
            placeholder={placeholder}
            error={Boolean(error)}
            variant="outlined"
            onKeyDown={(e) => {
              const input = e.target as HTMLInputElement;
              const cursorPosition = input.selectionStart;

              if (
                e.key === ' ' &&
                (!input.value ||
                  cursorPosition === 0 ||
                  (cursorPosition && input.value[cursorPosition - 1] === ' '))
              ) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              handleAutoInputChange(e.target.value, name);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={icon}
                    alt="City"
                    className="fieldIcon"
                    // style={{ width: 25, height: 25, marginRight: 18 }}
                  />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
              className: 'custom-label', // Apply custom CSS class
            }}
            required
            sx={{
              '& .MuiFormLabel-asterisk': {
                color: 'red',
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export default CustomAutocomplete;
