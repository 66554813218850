import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import family1 from '../../../assets/images/carouselImage1.jpg';
import family2 from '../../../assets/images/carouselImage2.png';
import family3 from '../../../assets/images/carouselImage3.png';
import family4 from '../../../assets/images/carouselImage4.jpg';

const CustomCarousel = () => {
  const images = [family1, family2, family3, family4];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [images.length]);

  useEffect(() => {
    if (isTransitioning) {
      const timer = setTimeout(() => {
        setIsTransitioning(false);
      }, 500); // Match this with your transition duration
      return () => clearTimeout(timer);
    }
  }, [isTransitioning]);

  useEffect(() => {
    images.forEach((imgSrc) => {
      const img = new Image();
      img.src = imgSrc;
    });
  }, [images]);

  const getImageStyle = (index: number) => {
    let zIndex = 1;
    let transform = 'translateX(100%)';

    if (index === currentIndex) {
      zIndex = 3;
      transform = 'translateX(0)';
    } else if (index === (currentIndex - 1 + images.length) % images.length) {
      zIndex = 2;
      transform = isTransitioning ? 'translateX(-100%)' : 'translateX(0)';
    }

    return {
      zIndex,
      transform,
      transition: 'transform 0.5s ease-in-out',
    };
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: {
          xs: '200px',
          sm: '300px',
          md: '400px',
          lg: '100vh',
        },
        overflow: 'hidden',
      }}
    >
      {images.map((img, index) => (
        <Box
          key={index}
          component="img"
          src={img}
          alt={`carousel-${index}`}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            ...getImageStyle(index),
          }}
        />
      ))}
      <Box
        sx={{
          position: 'absolute',
          bottom: 10,
          left: 10,
          padding: '8px 12px',
          zIndex: 4,
        }}
      >
        <a href="https://smartheritance.com" style={{ textDecoration: 'none' }}>
          <Typography variant="body1" color="#fff">
            https://smartheritance.com
          </Typography>
        </a>
      </Box>
    </Box>
  );
};

export default CustomCarousel;
