import React, { useState } from 'react';
import TooltipIcon from '../../assets/images/tooltip.png';
import {
  FreeSubscriptionDetails,
  subscriptionOption,
} from '../../utils/constants';
import { BasicSubscriptionDetails } from '../../utils/constants';
import { PremiumSubscriptionDetails } from '../../utils/constants';
import { offerList } from '../../utils/constants';

import {
  Dialog,
  Button,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from '@mui/material';
import './subscriptioncomponent.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionSliceActions } from '../../redux-store/reducers/subscriptionReducer';

const SubscriptionComponent = () => {
  const { t } = useTranslation();
  const [isBasicYearly, setIsBasicYearly] = useState('Monthly');
  const [isPremiumYearly, setIsPremiumYearly] = useState('Monthly');
  const dispatch = useDispatch();
  const isDialogOpen = useSelector(
    (state: any) => state.subscription.isSubscriptionDialogOpen
  );

  const handleClose = (choosePlan: any) => {
    dispatch(subscriptionSliceActions.setSubscriptionOption(choosePlan));
    dispatch(subscriptionSliceActions.setIsSubscriptionDialogOpen(false));
  };

  const handleBasicSwitchChange = (event: any, newValue: any) => {
    if (newValue !== null) {
      setIsBasicYearly(newValue);
    }
  };

  const handlePremiumSwitchChange = (event: any, newValue: any) => {
    if (newValue !== null) {
      setIsPremiumYearly(newValue);
    }
  };

  const translatedOfferList = offerList(t);
  const translatedPremiumSubscriptionDetails = PremiumSubscriptionDetails(t);
  const translatedBasicSubscriptionDetails = BasicSubscriptionDetails(t);
  const translatedFreeSubscriptionDetails = FreeSubscriptionDetails(t);

  const tooltipTexts: { [key: string]: string } = {
    'Automatic Assets Scans': t('tooltip.assetsScansDescription'),
    'Automatic Wellness Check': t('tooltip.wellnessCheckDescription'),
    'Personalized Wellness Check': t('tooltip.personalizedWellnessDescription'),
    'Beneficiary Contact Verification': t(
      'tooltip.beneficiaryVerificationDescription'
    ),
    'Critical Contacts': t('tooltip.criticalContactsDescription'),
    'Document Upload': t('tooltip.documentUploadDescription'),
    'Last wish & instructions': t('tooltip.lastWishInstructionsDescription'),
  };

  // Calculate prices based on subscription type
  const getBasicPrice = isBasicYearly === 'Monthly' ? '$5.99' : '$60';
  const getPremiumPrice = isPremiumYearly === 'Monthly' ? '$7.99' : '$80';

  return (
    <>
      <Dialog
        open={isDialogOpen}
        PaperProps={{
          component: 'form',
          // onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          //   event.preventDefault();
          //   handleClose();
          // },
          style: { borderRadius: '12px' },
        }}
        disableEscapeKeyDown
        hideBackdrop={false}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }
          handleClose(subscriptionOption.free);
        }}
        className="model-container"
      >
        <Box className="model-holder">
          <Box className="title-conatiner">
            <Box className="title-text">{t('subscription.headerTitle')}</Box>
          </Box>
          <Box className="sub-card-conatiner">
            <Box className="sub-card-holder">
              <ul className="card-list-holder">
                <li className="card-list card-list-content">
                  {translatedOfferList.map((cur: string, index: number) => (
                    <Box className="card-info" key={index}>
                      <span>{cur}</span>
                      {tooltipTexts[cur] && (
                        <Tooltip
                          placement="right"
                          title={tooltipTexts[cur]}
                          arrow
                          slotProps={{
                            tooltip: {
                              className: 'tooltip',
                            },
                          }}
                        >
                          <img
                            className="tooltip-icon"
                            src={TooltipIcon}
                            alt="Tooltip Icon"
                          />
                        </Tooltip>
                      )}
                    </Box>
                  ))}
                </li>
                <li className="card-list card-basic">
                  <Box className="card-free-block">
                    <Box className="card-offer-title">
                      {t('subscription.free')}
                    </Box>
                  </Box>
                  <Box className="btn">
                    <Button
                      variant="outlined"
                      className="select-button"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClose(subscriptionOption.free);
                      }}
                    >
                      {t('subscription.select')}
                    </Button>
                  </Box>
                  <Box className="subscription-details-box">
                    {translatedFreeSubscriptionDetails.map((detail, index) => (
                      <div key={index} className="subscription-details">
                        {detail}
                      </div>
                    ))}
                  </Box>
                </li>
                <li className="card-list card-recommend">
                  <Box className="card-offer-block">
                    <Box className="card-tag">
                      {t('subscription.recommend')}
                    </Box>
                    <Box className="card-offer-title">
                      {t('subscription.basic')}
                    </Box>
                    <Box className="freeOffer">
                      <div>{t(`subscription.freeFor3Months`)}</div>
                    </Box>
                    <Box className="freePeriod">
                      {t(`subscription.freePeriodEnd`)}
                    </Box>
                    <Box className="card-offer-price">
                      <span>{getBasicPrice}</span>
                      <span>{isBasicYearly === 'Monthly' ? '/mo' : '/yr'}</span>
                    </Box>
                    <Box className="card-offer-sub-title">
                      {t('subscription.get2MonthsFree')}
                    </Box>
                    <Box className="card-offer-btn">
                      <ToggleButtonGroup
                        color="primary"
                        value={isBasicYearly}
                        exclusive
                        onChange={handleBasicSwitchChange}
                        aria-label="Platform"
                        className="toggle-button-group"
                      >
                        <ToggleButton
                          className="toggle-left-btn"
                          value="Monthly"
                          sx={{
                            animationDuration: '0.5s',
                            transition: 'all 0.3s ease',
                          }}
                        >
                          {t('subscription.monthly')}
                        </ToggleButton>
                        <ToggleButton
                          className="toggle-right-btn"
                          value="Yearly"
                          sx={{
                            animationDuration: '0.5s',
                            transition: 'all 0.3s ease',
                          }}
                        >
                          {t('subscription.yearly')}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>
                  <Box className="btn">
                    <Button
                      variant="outlined"
                      className="select-basic-button"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClose(subscriptionOption.basic);
                      }}
                    >
                      {t('subscription.select')}
                    </Button>
                  </Box>
                  <Box className="subscription-details-box">
                    {translatedBasicSubscriptionDetails.map((detail, index) => (
                      <div key={index} className="subscription-details">
                        {detail}
                      </div>
                    ))}
                  </Box>
                </li>
                <li className="card-list card-basic">
                  <Box className="card-offer-block">
                    <Box className="card-offer-title">
                      {t('subscription.premium')}
                    </Box>
                    <Box className="freeOffer">
                      <div>{t(`subscription.freeFor3Months`)}</div>
                    </Box>
                    <Box className="freePeriod">
                      {t(`subscription.freePeriodEnd`)}
                    </Box>
                    <Box className="card-offer-price">
                      <span>{getPremiumPrice}</span>
                      <span>
                        {isPremiumYearly === 'Monthly' ? '/mo' : '/yr'}
                      </span>
                    </Box>
                    <Box className="card-offer-sub-title">
                      {t('subscription.get2MonthsFree')}
                    </Box>
                    <Box className="card-offer-btn">
                      <ToggleButtonGroup
                        color="primary"
                        value={isPremiumYearly}
                        exclusive
                        onChange={handlePremiumSwitchChange}
                        aria-label="Platform"
                        className="toggle-button-group"
                      >
                        <ToggleButton
                          className="toggle-left-btn"
                          value="Monthly"
                          sx={{
                            animationDuration: '0.5s',
                            transition: 'all 0.5s ease',
                          }}
                        >
                          {t('subscription.monthly')}
                        </ToggleButton>
                        <ToggleButton
                          className="toggle-right-btn"
                          value="Yearly"
                          sx={{
                            animationDuration: '0.5s',
                            transition: 'all 0.5s ease',
                          }}
                        >
                          {t('subscription.yearly')}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>
                  <Box className="btn">
                    <Button
                      variant="outlined"
                      className="select-button"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClose(subscriptionOption.premium);
                      }}
                    >
                      {t('subscription.select')}
                    </Button>
                  </Box>
                  <Box className="subscription-details-box">
                    {translatedPremiumSubscriptionDetails.map(
                      (detail, index) => (
                        <div key={index} className="subscription-details">
                          {detail}
                        </div>
                      )
                    )}
                  </Box>
                </li>
              </ul>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default SubscriptionComponent;
