import React from 'react';
import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import CustomCircularProgress from './customProgress';
import cardArrow from '../../assets/images/cardArrow.png';
import './welcome.css';
import { assetsSliceActions } from '../../redux-store/reducers/assetsreducer';
import { useDispatch } from 'react-redux';

interface CustomDashboardCardProps {
  icon: string;
  title: string;
  subtitle: string;
  progressValue: any;
  progressColor: string;
  progressTrackColor: string;
  bottomContent: 'avatars' | 'icons' | 'documents';
  avatars?: Array<{ initial: string; color: string }>;
  icons?: Array<{ src: string; alt: string }>;
  documents?: Array<{ src: string; alt: string }>;
  onClick?: () => void;
}

const CustomDashboardCard: React.FC<CustomDashboardCardProps> = ({
  icon,
  title,
  subtitle,
  progressValue,
  progressColor,
  progressTrackColor,
  bottomContent,
  avatars,
  icons,
  documents,
  onClick,
}) => {
  const dispatch = useDispatch();
  const splitSubtitle = (text: string): string[] => {
    const words = text.split(' ');
    const lines: string[] = [];
    let currentLine = '';

    words.forEach((word) => {
      if ((currentLine + word).length <= 30) {
        currentLine += (currentLine ? ' ' : '') + word;
      } else {
        if (currentLine) {
          lines.push(currentLine);
        }
        currentLine = word;
      }
    });

    if (currentLine) {
      lines.push(currentLine);
    }

    return lines;
  };
  const handleIconOnClick = (e: any, label: string) => {
    e.preventDefault();
    dispatch(assetsSliceActions.setSelectedMenuLable(label));
  };
  const renderBottomContent = () => {
    switch (bottomContent) {
      case 'avatars':
        return (
          <AvatarGroup max={4}>
            {avatars?.map((avatar, index) => (
              <Avatar
                className="DashboardAvatar"
                key={index}
                sx={{
                  bgcolor: avatar.color,
                  marginLeft: index === 0 ? '-3px' : '3px',
                }}
              >
                {avatar.initial}
              </Avatar>
            ))}
          </AvatarGroup>
        );
      case 'icons':
        return (
          <Box display="flex" gap="6px">
            {icons?.map((icon, index) => (
              <Avatar
                className="DashboardAvatar"
                key={index}
                sx={{ bgcolor: '#112F8E', marginLeft: '-3px' }}
                onClick={(e) => handleIconOnClick(e, icon.alt)}
              >
                <img
                  className="CardAssetsIcons"
                  src={icon.src}
                  alt={icon.alt}
                />
              </Avatar>
            ))}
          </Box>
        );
      case 'documents':
        return (
          <AvatarGroup max={4}>
            {documents?.map((doc, index) => (
              <Avatar
                className="DashboardAvatar"
                key={index}
                sx={{ bgcolor: 'transparent' }}
              >
                <img className="CardAssetsIcons" src={doc.src} alt={doc.alt} />
              </Avatar>
            ))}
            {documents && documents.length > 3 && (
              <Avatar sx={{ bgcolor: 'white' }}>
                {' '}
                +{documents.length - 3}
              </Avatar>
            )}
          </AvatarGroup>
        );
      default:
        return null;
    }
  };

  const subtitleLines = splitSubtitle(subtitle);

  return (
    <Grid onClick={onClick} className="DashboardCard" item md={6} lg={6}>
      <Grid
        className="CardTopGrid"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          <img className="DarshboardCardIcon" src={icon} alt="" />
          <Typography
            className="DashboardCardTitle"
            // fontSize="34px" fontWeight={600}
          >
            {title}
          </Typography>
          {subtitleLines.map((line, index) => (
            <Typography
              className="DashboardCardSubTitle"
              key={index}
              // fontSize="18px"
              // fontWeight={500}
              // color="#909CAC"
            >
              {line}
            </Typography>
          ))}
        </div>
        <div className="circularBar">
          {/* <CustomCircularProgress
            value={progressValue}
            size={165.969}
            thickness={13}
            color={progressColor}
            trackColor={progressTrackColor}
          /> */}
          <Box
            className="circularBarInnerBox"
            // height="110px"
            // width="110px"
            // display="flex"
            // mt={5}
            // alignItems="center"
            // justifyContent="center"
            // borderRadius="50%"
            // bgcolor="#DEF1FF"
            // color="#4FA2DD"
          >
            <Typography
              className="progressValue"
              // fontSize="60px" fontWeight={500}
            >
              {progressValue}
            </Typography>
          </Box>
        </div>
      </Grid>
      <Divider />
      <Grid
        className="CardBottomGrid"
        // marginTop="10px"
        // display="flex"
        // flexDirection="row"
        // justifyContent="space-between"
        // alignItems="center"
      >
        {renderBottomContent()}
        <img className="arrowIcon" src={cardArrow} alt="Arrow Icon" />
      </Grid>
    </Grid>
  );
};

export default CustomDashboardCard;
