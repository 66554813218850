import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  List,
  ListItem,
  Avatar,
  Button,
} from '@mui/material';
import docUploadIcon from '../../assets/images/docUploadIcon.png';
import PdfIcon from '../../assets/images/pdfIcon.png';
import JpgIcon from '../../assets/images/jpgIcon.png';
import DeleteIcon from '../../assets/images/icons/delete.png';
import CustomButton from '../common/CustomButton';
import { useTranslation } from 'react-i18next';
import UploadFile from './UploadFile';
import { formatFileSize, getFileIcon } from '../../utils/commonFunctions';
import { useHttpClient } from '../../hooks/usecorrelation';
import DocumentService from './document.service';
import { useDispatch, useSelector } from 'react-redux';
import { IUserData } from '../../pages/auth/auth.type';
import SuccessMsgInfo from '../common/SuccessMsgInfo';
import ErrorMessageInfo from '../common/ErrorMsgInfo';
import { appSliceActions } from '../../redux-store/reducers/appReducer';
import { documentUploadSliceActions } from '../../redux-store/reducers/documentUploadReducer';
import styles from './documentUpload.module.css';

interface UploadedFile {
  name: string;
  size: number;
  type: string;
}

const DocumentUpload: React.FC = () => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [selectFiles, setSelectedFiles] = useState<any>();
  const httpClient = useHttpClient();
  const userData: IUserData = useSelector((state: any) => state.auth.loginData);
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const uploadDocumentList = useSelector(
    (state: any) => state.documentUpload.uploadDocumentList
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (message || errorMessage) {
      const timer = setTimeout(() => {
        setMessage('');
        setErrorMessage('');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message, errorMessage]);

  const handleFileUpload = (files: FileList) => {
    const newFiles = Array.from(files!).map((file) => ({
      name: file.name,
      size: file.size,
      type: file.type,
    }));

    const isFileAlreadyUploaded =
      uploadDocumentList &&
      uploadDocumentList?.length > 0 &&
      uploadDocumentList.some(
        (file: UploadedFile) => file.name === files[0].name
      );
    if (isFileAlreadyUploaded) {
      setErrorMessage(t('alreadyAddedSameFileName'));
      return;
    } else {
      setSelectedFiles(files[0]);
      setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleDelete = (index: number) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setSelectedFiles(null);
  };

  const handleCancel = () => {
    setUploadedFiles([]);
    setSelectedFiles(null);
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append('file', selectFiles);
    dispatch(appSliceActions.setIsLoader(true));
    const response = await DocumentService.postDocumentData(
      httpClient,
      userData.userId,
      formData
    );
    if (response === 200) {
      setMessage(t('fileUploadSuccessFully'));
      const updatedList = [...(uploadDocumentList || []), selectFiles];
      dispatch(documentUploadSliceActions.setDocumentUploadList(updatedList));
      setUploadedFiles([]);
      setSelectedFiles(null);
    } else {
      setErrorMessage(t('somethingWentWrong'));
    }
    dispatch(appSliceActions.setIsLoader(false));
  };

  return (
    <>
      <Grid container direction="column" className={styles.container}>
        <Grid item>
          <Typography className={`${styles.headerText}`}>
            {t('addNewDoc')}
          </Typography>
          <UploadFile
            handleUploadFile={handleFileUpload}
            className={styles.documentUploadBox}
            disable={!!selectFiles}
          />
        </Grid>

        <Grid item className={styles.fileListContainer}>
          <List>
            {uploadedFiles.map((file, index) => (
              <ListItem
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                key={index}
                className={styles.fileListItem}
              >
                <Box className={styles.fileInfoContainer}>
                  <Avatar
                    src={getFileIcon(file.type)}
                    className={styles.fileIcon}
                  />
                  <Box>
                    <Typography className={styles.fileName}>
                      {file.name}
                    </Typography>
                    <Typography className={styles.fileSize}>
                      {formatFileSize(file.size)}
                    </Typography>
                  </Box>
                </Box>
                <IconButton onClick={() => handleDelete(index)} size="small">
                  <img
                    src={DeleteIcon}
                    alt="delete"
                    className={styles.deleteIcon}
                  />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item className={styles.messageContainer}>
          <Box>
            <SuccessMsgInfo
              successMsg={message}
              style={{ paddingTop: '1rem' }}
            />
            <ErrorMessageInfo
              errorMessage={errorMessage}
              style={{ paddingTop: '-1.5rem' }}
            />
          </Box>
        </Grid>
        <Grid container>
          <Grid item xs={5}></Grid>
          <Grid item xs={7}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 3,
                mr: 2,
                mt: 2,
              }}
            >
              <CustomButton
                text={t('button.cancel')}
                buttonType="secondary"
                handleClick={() => handleCancel && handleCancel()}
              />
              <CustomButton
                text={t('button.save')}
                handleClick={() => handleSave && handleSave()}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DocumentUpload;
