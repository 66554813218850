import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  IconButton,
  Stack,
  Button,
  Alert,
  Link,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LaptopIcon from '../../assets/images/beneficiaryThanIcon.png';
import { useTranslation } from 'react-i18next';

interface WelcomeDialogProps {
  open: boolean;
  onClose: () => void;
  userName?: string;
}

const BeneficiaryUserAlive: React.FC<WelcomeDialogProps> = ({
  open,
  onClose,
  userName = 'user name',
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{
        sx: {
          width: '850px',
          borderRadius: '12px',
          position: 'relative',
          overflow: 'hidden',
        },
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
        },
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 1,
        }}
      >
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Stack spacing={3} alignItems="center" sx={{ px: 4, pb: 4 }}>
          {/* Icon */}
          <Box
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={LaptopIcon} alt="Laptop Icon" />
          </Box>

          {/* Title */}
          <Typography align="center" sx={{ fontWeight: 600 }}>
            {t('Thank_you_for_signing_up')}
          </Typography>

          {/* Subtitle */}
          <Typography align="center" sx={{ fontWeight: 600 }}>
            {t('Your_account_is_now_listed', { userName })}
          </Typography>

          {/* Alert Boxes */}
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Alert
              severity="info"
              icon={false}
              sx={{
                backgroundColor: '#FDF7D9',
                borderLeft: '8px solid #F6B423',
              }}
            >
              {t('All_asset_information_remain_private', { userName })}
            </Alert>

            <Alert
              severity="info"
              icon={false}
              sx={{
                backgroundColor: '#FDF7D9',
                borderLeft: '8px solid #F6B423',
              }}
            >
              {t('To_ensure_you_dont_miss_communications')}
            </Alert>
          </Stack>

          {/* Bottom Text */}
          <Box sx={{ textAlign: 'center' }}>
            <Typography fontWeight={600} color="text.primary" paragraph>
              {t('SmartHeritance_is_a_modern_platform')}
            </Typography>
          </Box>

          {/* Learn More Button */}
          <Link>{t('Learn_More')}</Link>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default BeneficiaryUserAlive;
