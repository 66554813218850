import { IconButton } from '@mui/material';
import React, { useCallback, useEffect, useRef } from 'react';
import facebook from '../../assets/images/facebook-logo.svg';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { ISocialLoginReqBody } from '../../pages/auth/auth.type';
import { useHttpClient } from '../../hooks/usecorrelation';
import AuthService from '../../pages/auth/auth.service';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { authSliceActions } from '../../redux-store/reducers/authReducer';
import { appSliceActions } from '../../redux-store/reducers/appReducer';
import { useTranslation } from 'react-i18next';
import { redirect, useNavigate } from 'react-router-dom';
import { partners } from '../../utils/constants';
import { redirectLink } from '../../utils/commonFunctions';

const FacebookLoginButton = () => {
  const httpClient = useHttpClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleOnResolve = async (response: any) => {
    dispatch(appSliceActions.setIsLoader(true));
    if (response?.data?.accessToken) {
      try {
        const getUserData = await axios.get(
          'https://graph.facebook.com/v11.0/me?fields=id,name,email,picture&access_token=' +
            response?.data?.accessToken
        );
        if (getUserData.data) {
          const reqBody: ISocialLoginReqBody = {
            idTokenString: response?.data?.accessToken,
            serviceProvider: response?.provider,
            userEmail: getUserData.data?.email,
            userName: getUserData.data?.name,
            userPartnerName: partners.smartHertance.partnerName,
            partnerCode: partners.smartHertance.partnerCode,
          };
          const responseData = await AuthService.postSocialLogin(
            httpClient,
            reqBody
          );
          if (responseData?.message === 'success') {
            dispatch(authSliceActions.setLoginErrorMessage(''));
            const navigatePage = redirectLink(
              responseData?.onboardingCurrentPage
            );
            navigate(navigatePage);
          } else if (responseData?.message === 'failure') {
            dispatch(
              authSliceActions.setLoginErrorMessage(
                t('socialLoginErrorMessage')
              )
            );
          }
          dispatch(appSliceActions.setIsLoader(false));
        }
      } catch (err) {
        dispatch(
          authSliceActions.setLoginErrorMessage(t('socialLoginErrorMessage'))
        );
      }
    }
  };

  const onLogoutSuccess = useCallback(() => {
    alert('logout success');
  }, []);

  return (
    <>
      <LoginSocialFacebook
        isOnlyGetToken
        appId={process.env.REACT_APP_FB_APP_ID || ''}
        // onLoginStart={onLoginStart}
        onResolve={(response: any) => {
          handleOnResolve(response);
        }}
        onReject={(err) => {
          console.log(err);
        }}
      >
        <IconButton>
          <img className="fbIcon" src={facebook} alt="Facebook" />
        </IconButton>
      </LoginSocialFacebook>
    </>
  );
};

export default FacebookLoginButton;
