import React from 'react';
import './divider.css';
import { Typography } from '@mui/material';
import '../../../pages/auth/auth.css';
type IProps = {
  text: string;
};
const SHDivider: React.FC<IProps> = ({ text }) => {
  return (
    <div className="or-section">
      <span className="line"></span>
      <Typography className="or-text ">{text}</Typography>
      <span className="line"></span>
    </div>
  );
};

export default SHDivider;
