import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import FormField from '../common/FormField';
import CustomButton from '../common/CustomButton';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cityIcon from '../../assets/images/city.png';
import zipIcon from '../../assets/images/zip.png';
import stateIcon from '../../assets/images/state.png';
import webIcon from '../../assets/images/web.png';
import countryIcon from '../../assets/images/country.png';
import addressIcon from '../../assets/images/address.png';
import accountIcon from '../../assets/images/account.png';
import SuccessMsgInfo from '../common/SuccessMsgInfo';
import ErrorMessageInfo from '../common/ErrorMsgInfo';
import './addAssets.css';
import AddAssetsWrapper from './AddAccountsWraper';
import { useDispatch, useSelector } from 'react-redux';
import {
  IAssetFormProperties,
  IAssets,
} from '../../pages/onboarding/onboarding.type';
import {
  assetsSliceActions,
  IAssetBeneficiary,
} from '../../redux-store/reducers/assetsreducer';
import { handleNumberInputChange, handleOnSubmit } from './assetCommonFunction';
import { assetFormName, validateEmptyField } from '../../utils/commonFunctions';
import { useLocation } from 'react-router-dom';
import AdditionButton from './AdditionButton';
import bankIcon from '../../assets/images/bank.png';
import CustomAutocomplete from '../common/AutoCompleteField';

type IFormInput = {
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  propertyType: string;
  mortageProvider: string;
  mortageWebsite: string;
  mortageLoanNumber: string;
  homeInsuranceProvider: string;
  homeInsuranceWebsite: string;
  planOrPolicy: string;
};

interface AddBankAccountProps {
  onDone: () => void;
  onAddEditDelete: (e: IAssets) => void;
  properties?: IAssetFormProperties[];
  selectedMenuItem: string; // Add a prop to track the selected menu item
}

const RealEstateAccount: React.FC<AddBankAccountProps> = ({
  onDone,
  onAddEditDelete,
  properties,
  selectedMenuItem, // Destructure the prop
}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [errMessage, setErrMsg] = useState('');
  const formRef = useRef<HTMLFormElement>(null);
  const assetBeneficiary: IAssetBeneficiary[] = useSelector(
    (state: any) => state.assets.assetsBeneficiaryList
  );
  let editAsset = useSelector((state: any) => state.assets.editAsset);
  const assetType = useSelector((state: any) => state.assets.assetType);
  const location = useLocation();
  const isDashboard = location.pathname === '/dashboard';
  const [visibilityIndex, setVisibilityIndex] = useState(0);
  const dispatch = useDispatch();
  const [additionButtonName, setAdditionButtonName] = useState(
    t('button.AddMortgagedetails')
  );

  const [autoData, setAutoData] = useState<any>({
    city: '',
    state: '',
    country: '',
    propertyType: 0,
  });
  const [disabled, setDisabled] = useState(true);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset, // Destructure the reset function
  } = useForm<IFormInput>({
    defaultValues: properties?.reduce((acc, property) => {
      acc[property.name as keyof IFormInput] = '';
      return acc;
    }, {} as IFormInput),
    mode: 'onChange',
  });
  const [propertyTypeOption, setPropertyTypeOption] = React.useState(0);
  const mainProperties = properties?.filter(
    (property) =>
      property.propertyType === 'main' && property.name !== 'propertyType'
  )!;
  const watchedFields = mainProperties?.reduce((acc, property) => {
    if (property.name !== 'propertyType') {
      acc[property.name as keyof IFormInput] = watch(
        property.name as keyof IFormInput
      );
    }
    return acc;
  }, {} as IFormInput)!;

  // Dummy data for countries
  const countries = [
    { label: 'United States' },
    { label: 'Canada' },
    { label: 'Australia' },
    { label: 'Germany' },
    { label: 'India' },
    { label: 'United Kingdom' },
  ];

  const states = [
    { label: 'California' },
    { label: 'Texas' },
    { label: 'Florida' },
    { label: 'New York' },
    { label: 'Illinois' },
  ];

  const cities = [
    { label: 'New York City' },
    { label: 'Los Angeles' },
    { label: 'Chicago' },
    { label: 'Houston' },
    { label: 'Phoenix' },
    { label: 'Philadelphia' },
  ];

  const resetForm = () => {
    properties?.forEach((property) =>
      setValue(property.name as keyof IFormInput, '')
    );
    setAutoData({
      city: '',
      state: '',
      country: '',
      propertyType: '',
    });
    setDisabled(true);
    setPropertyTypeOption(0);
  };
  const propertyOption = [
    t('placeholder.SELECT'),
    t('label.house'),
    t('label.townHome'),
    t('label.condo'),
    t('label.apartment'),
    t('label.land'),
    t('label.commercial'),
  ];
  useEffect(() => {
    if (editAsset) {
      if (
        JSON.stringify(editAsset.beneficiaries) !==
        JSON.stringify(assetBeneficiary)
      ) {
        setDisabled(false);
      }
    }
  }, [assetBeneficiary]);
  //clean message after 2 seconds
  useEffect(() => {
    dispatch(assetsSliceActions.setEditAssets(null));
    const handleClickInsideForm = (event: MouseEvent) => {
      if (formRef.current && formRef.current.contains(event.target as Node)) {
        setMessage('');
        setErrMsg('');
      }
    };
    document.addEventListener('mousedown', handleClickInsideForm);
    return () => {
      document.removeEventListener('mousedown', handleClickInsideForm);
    };
  }, [properties]);
  useEffect(() => {
    if (message || errMessage) {
      const timer = setTimeout(() => {
        setMessage('');
        setErrMsg('');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message, errMessage]);
  useEffect(() => {
    if (editAsset) {
      let tempAutoData: any = { ...autoData };
      properties?.forEach((property) => {
        setValue(property.name as keyof IFormInput, editAsset[property.name]);
        if (
          property.name == 'propertyType' ||
          property.name == 'city' ||
          property.name == 'state' ||
          property.name == 'country'
        ) {
          tempAutoData[property.name] = editAsset[property.name];
        }
      });

      if (editAsset.propertyType) {
        tempAutoData['propertyType'] = editAsset['propertyType'];
      }
      setAutoData({ ...tempAutoData });

      setMessage('');
      setErrMsg('');
    } else {
      resetForm();
    }
  }, [editAsset]);

  // Reset form when selectedMenuItem changes
  useEffect(() => {
    reset();
  }, [assetType, reset]);
  const selectedAssets = useSelector(
    (state: any) => state.assets.assetListbasedOnSelectType
  );
  const onSubmit = async (res: any) => {
    Object.keys(autoData).map((cur) => {
      res[cur] = autoData[cur];
    });

    const existingAssetIndex = selectedAssets?.assetDetails?.length
      ? selectedAssets.assetDetails.findIndex(
          (asset: any) => asset.address === res.address && asset.zip === res.zip
        )
      : -1;
    if (existingAssetIndex !== -1 && existingAssetIndex !== editAsset?.id) {
      setErrMsg(t('duplicateAssets'));
    } else {
      handleOnSubmit(res, setMessage, resetForm, setErrMsg, onAddEditDelete);
    }
  };

  const checkDisabled = (tempAutoData?: any) => {
    let disable = false;
    const DataObj = tempAutoData ? tempAutoData : autoData;

    Object.values(DataObj).forEach((cur) => {
      if (!cur) disable = true;
    });

    if (watch('address') === '' || watch('zip') === '') {
      disable = true;
    }

    setDisabled(disable);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.replace(/^\s+/, '');
    setValue(event.target.name as any, trimmedValue);
    checkDisabled();
  };
  const handleAdditionButtonClick = () => {
    setVisibilityIndex(visibilityIndex + 1);
    setAdditionButtonName(t('button.AddHomeInsurance'));
  };
  const handleCancel = () => {
    resetForm();
    dispatch(assetsSliceActions.setEditAssets(null));
  };

  const handleAutoChange = (newValue: string, key: string) => {
    let tempAutoData: any = { ...autoData };
    tempAutoData[key] = newValue;
    setAutoData({ ...tempAutoData });
    checkDisabled(tempAutoData);
  };

  return (
    <AddAssetsWrapper
      onDone={onDone}
      disabled={disabled}
      buttonText={t('doneAddingBankAccounts')}
      formHeaderText={assetFormName(assetType)}
      handleSubmit={handleSubmit(onSubmit)}
      onCancel={handleCancel}
    >
      <Box
        className={isDashboard ? 'formDashBoardConatiner' : 'formBoxContainer'}
        component="form"
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        key={assetType}
      >
        <Grid container columnSpacing={2} alignItems="center">
          <Grid item xs={12}>
            <FormField
              name={properties![0].name}
              control={control}
              label={t(properties![0].label)}
              placeholder={t(properties![0].placeholder)}
              error={errors.address?.message}
              icon={addressIcon}
              rules={{
                required: t('required'),
                validate: (email) =>
                  validateEmptyField(properties![0].name, t('required')),
                maxLength: {
                  value: 50,
                  message: t('maxLengthExceeded', { max: 50 }),
                },
              }}
              type="text"
              handlePhoneNumberChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomAutocomplete
              name={properties![1].name}
              control={control}
              options={cities}
              handleAutoChange={(newValue, key) =>
                handleAutoChange(newValue, key)
              }
              value={autoData.city}
              label={t(properties![1].label)}
              placeholder={t(properties![1].placeholder)}
              icon={cityIcon}
              handleAutoInputChange={handleAutoChange}
              error={errors.city?.message}
            ></CustomAutocomplete>
          </Grid>
          <Grid item xs={6}>
            <CustomAutocomplete
              name={properties![2].name}
              control={control}
              options={states}
              value={autoData.state}
              handleAutoChange={(newValue, key) =>
                handleAutoChange(newValue, key)
              }
              label={t(properties![2].label)}
              placeholder={t(properties![2].placeholder)}
              icon={stateIcon}
              handleAutoInputChange={handleAutoChange}
              error={errors.state?.message}
            ></CustomAutocomplete>
          </Grid>
          <Grid item xs={6}>
            <FormField
              name={properties![3].name}
              control={control}
              label={t(properties![3].label)}
              placeholder={t(properties![3].placeholder)}
              error={errors.zip?.message}
              icon={zipIcon}
              rules={{
                required: t('required'),
                validate: (email) =>
                  validateEmptyField(properties![3].name, t('required')),
                maxLength: {
                  value: 5,
                  message: t('maxLengthExceeded', { max: 5 }),
                },
              }}
              type="text"
              handlePhoneNumberChange={(e) => {
                handleNumberInputChange(e, setValue);
                checkDisabled();
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {/* Autocomplete for countries */}
            <CustomAutocomplete
              name={properties![4].name}
              control={control}
              options={countries}
              value={autoData.country}
              handleAutoChange={(newValue, key) =>
                handleAutoChange(newValue, key)
              }
              label={t(properties![4].label)}
              placeholder={t(properties![4].placeholder)}
              icon={countryIcon}
              handleAutoInputChange={handleAutoChange}
              error={errors.country?.message}
            ></CustomAutocomplete>
          </Grid>
          <Grid item xs={isDashboard ? 12 : 6}>
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ color: '#252B42' }}
              >
                {t('label.propertyType')}
                <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <Select
                className="selectDropdown"
                labelId="demo-simple-select-label"
                name="propertyType"
                label={`${t('label.propertyType')} *`}
                value={autoData.propertyType}
                onChange={(e) =>
                  handleAutoChange(e.target.value, 'propertyType')
                }
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#D0D5DD',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0047FF',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0047FF',
                  },
                }}
              >
                {propertyOption.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item}
                    sx={{ padding: '5px 11px !important' }}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {!isDashboard && (
            <Grid display="flex" gap={2} item xs={6}>
              <CustomButton
                className="Custombutton"
                text={t('button.cancel')}
                buttonType="secondary"
                handleClick={() => handleCancel()}
                style={{
                  width: '47%',
                }}
              />
              <CustomButton
                style={{
                  width: '47%',
                }}
                text={editAsset ? t('button.save') : t('button.add')}
                disabled={disabled}
              />
            </Grid>
          )}
        </Grid>
        {visibilityIndex > 0 && (
          <Grid
            container
            columnSpacing={2}
            alignItems="center"
            sx={{ marginTop: '20px' }}
          >
            <Grid item xs={12}>
              <FormField
                name={properties![5].name}
                control={control}
                label={t(properties![5].label)}
                placeholder={t(properties![5].placeholder)}
                error={errors[properties![5].name as keyof IFormInput]?.message}
                icon={bankIcon}
                showRequired={false}
                rules={{
                  validate: (email) =>
                    validateEmptyField(properties![5].name, t('required')),
                  maxLength: {
                    value: 25,
                    message: t('maxLengthExceeded', { max: 25 }),
                  },
                }}
                type="text"
                handlePhoneNumberChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                name={properties![6].name}
                control={control}
                label={t(properties![6].label)}
                placeholder={t(properties![6].placeholder)}
                error={errors[properties![6].name as keyof IFormInput]?.message}
                icon={webIcon}
                showRequired={false}
                rules={{
                  validate: (email) =>
                    validateEmptyField(properties![6].name, t('required')),
                  maxLength: {
                    value: 6,
                    message: t('maxLengthExceeded', { max: 6 }),
                  },
                }}
                type="text"
                handlePhoneNumberChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                name={properties![7].name}
                control={control}
                label={t(properties![7].label)}
                placeholder={t(properties![7].placeholder)}
                error={errors[properties![7].name as keyof IFormInput]?.message}
                icon={accountIcon}
                showRequired={false}
                rules={{
                  // required: t('required'),
                  maxLength: {
                    value: 25,
                    message: t('maxLengthExceeded', { max: 25 }),
                  },
                }}
                type="text"
                handlePhoneNumberChange={handleInputChange}
              />
            </Grid>
          </Grid>
        )}
        {visibilityIndex > 1 && (
          <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12}>
              <FormField
                name={properties![8].name}
                control={control}
                label={t(properties![8].label)}
                placeholder={t(properties![8].placeholder)}
                error={errors[properties![8].name as keyof IFormInput]?.message}
                icon={bankIcon}
                showRequired={false}
                rules={{
                  validate: (email) =>
                    validateEmptyField(properties![8].name, t('required')),
                  maxLength: {
                    value: 25,
                    message: t('maxLengthExceeded', { max: 25 }),
                  },
                }}
                type="text"
                handlePhoneNumberChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                name={properties![9].name}
                control={control}
                label={t(properties![9].label)}
                placeholder={t(properties![9].placeholder)}
                error={errors[properties![9].name as keyof IFormInput]?.message}
                icon={webIcon}
                showRequired={false}
                rules={{
                  validate: (email) =>
                    validateEmptyField(properties![9].name, t('required')),
                  maxLength: {
                    value: 6,
                    message: t('maxLengthExceeded', { max: 6 }),
                  },
                }}
                type="text"
                handlePhoneNumberChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                name={properties![10].name}
                control={control}
                label={t(properties![10].label)}
                placeholder={t(properties![10].placeholder)}
                error={
                  errors[properties![10].name as keyof IFormInput]?.message
                }
                icon={accountIcon}
                showRequired={false}
                rules={{
                  maxLength: {
                    value: 25,
                    message: t('maxLengthExceeded', { max: 25 }),
                  },
                }}
                type="text"
                handlePhoneNumberChange={handleInputChange}
              />
            </Grid>
          </Grid>
        )}
        {isDashboard && visibilityIndex <= 1 && (
          <Grid sx={{ mt: 2 }}>
            {' '}
            <AdditionButton
              onClick={handleAdditionButtonClick}
              buttonName={additionButtonName}
            ></AdditionButton>
          </Grid>
        )}

        <Grid container justifyContent="end">
          <SuccessMsgInfo successMsg={message}></SuccessMsgInfo>
          <ErrorMessageInfo errorMessage={errMessage}></ErrorMessageInfo>
        </Grid>
      </Box>
    </AddAssetsWrapper>
  );
};

export default RealEstateAccount;
