import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ReactComponent as BankIcon } from '../../assets/images/bank.svg';
import { ReactComponent as BankSmall } from '../../assets/images/bank_small.svg';
import { ReactComponent as Proiders } from '../../assets/images/providers_small.svg';
import { ReactComponent as CriticalSmall } from '../../assets/images/critical_small.svg';
import { ReactComponent as BrokerageIcon } from '../../assets/images/brockerage_icon.svg';
import { ReactComponent as InsuranceIcon } from '../../assets/images/insurance_icon.svg';
import { ReactComponent as RetirementIcon } from '../../assets/images/retirement_icon.svg';
import { ReactComponent as EstateIcon } from '../../assets/images/realestate_icon.svg';
import { ReactComponent as CryptoIcon } from '../../assets/images/crypto_icon.svg';
import { ReactComponent as ProfessionalIcon } from '../../assets/images/profeicon.svg';
import { ReactComponent as WillSetUpIcon } from '../../assets/images/critical.svg';
import family1 from '../../assets/images/family_image.png';
import { ReactComponent as SentIcon } from '../../assets/images/sent_icon.svg';
import { ReactComponent as CrossIcon } from '../../assets/images/cross-icon.svg';
import './personalize.css';
import { useTranslation } from 'react-i18next';
import CardComponent from './CardComponent';
import { ReactComponent as YellowBar } from '../../assets/images/yellow_bar.svg';
import { motion } from 'framer-motion';
import DashboardHeader from '../../components/common/header/DashboardHeader';

export interface PersonalResponseType {
  question: string;
  response: 'yes' | 'no';
  description?: string;
}

const Personalize: React.FC = () => {
  const { t } = useTranslation();

  const questionIconMap = {
    [t('bankAccountQuestion')]: BankIcon,
    [t('brokerageAccountQuestion')]: BrokerageIcon,
    [t('insuranceAccountQuestion')]: InsuranceIcon,
    [t('retirementAccountQuestion')]: RetirementIcon,
    [t('estateAccountQuestion')]: EstateIcon,
    [t('cryptoAccountQuestion')]: CryptoIcon,
    [t('professionalService')]: ProfessionalIcon,
    [t('WillSetUp')]: WillSetUpIcon,
  };

  const questions = [
    {
      question: t('bankAccountQuestion'),
      description: t('bankAccountDescription'),
      icon: BankSmall,
    },
    {
      question: t('brokerageAccountQuestion'),
      description: t('brokerageAccountDescription'),
      icon: BrokerageIcon,
    },
    {
      question: t('insuranceAccountQuestion'),
      description: t('insuranceAccountDescription'),
      icon: InsuranceIcon,
    },
    {
      question: t('retirementAccountQuestion'),
      description: t('retirementAccountDescription'),
      icon: RetirementIcon,
    },
    {
      question: t('estateAccountQuestion'),
      description: t('estateAccountDescription'),
      icon: EstateIcon,
    },
    {
      question: t('cryptoAccountQuestion'),
      description: t('cryptoAccountDescription'),
      icon: CryptoIcon,
    },
    {
      question: t('professionalService'),
      description: t('professionalServiceDescription'),
      icon: Proiders,
    },
    {
      question: t('WillSetUp'),
      description: t('WillSetUpDescription'),
      icon: CriticalSmall,
    },
  ];

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState<PersonalResponseType[]>([]);

  const handleYesClick = () => {
    setResponses((prevResponses) => [
      ...prevResponses,
      {
        question: questions[currentQuestionIndex].question,
        response: 'yes',
        description: questions[currentQuestionIndex].description,
      },
    ]);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleNoClick = () => {
    setResponses((prevResponses) => [
      ...prevResponses,
      {
        question: questions[currentQuestionIndex].question,
        response: 'no',
        description: questions[currentQuestionIndex].description,
      },
    ]);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const isCompleted = currentQuestionIndex >= questions.length;

  return (
    <>
      <DashboardHeader />
      <Grid
        container
        className="height-container"
        sx={{
          overflowY: {
            lg: isCompleted ? 'hidden' : 'auto',
          },
          height: '100vh',
        }}
      >
        <Grid item xs={12} sm={2} md={3} lg={3} className="image-container">
          <img src={family1} alt="Family" className="image" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={isCompleted ? 6 : 10}
          md={isCompleted ? 5 : 9}
          lg={isCompleted ? 5 : 9}
          className={`${isCompleted ? 'personalize-left-section completed' : 'personalize-left-section'} personalize-left`}
        >
          <Grid
            item
            className="content"
            sx={{
              padding: {
                xs: '1.5rem 2.25rem',
                sm: '5rem 2.5rem',
                lg: ' 6.5rem 0rem 0rem 5.8rem',
                md: '5rem 3rem',
              },
              paddingBottom: { xs: 0, md: 0 },
            }}
          >
            <Typography className="title">
              {t('welcomeToSmartHeritance')}
            </Typography>

            <Typography mb={4} variant="body2" className="subtitle">
              {t('guideText')}
            </Typography>

            <Typography
              variant="body2"
              mb={3}
              mt="-18px !important"
              className="question-title"
            >
              {t('tellUsAboutYou')}
            </Typography>

            {/* Render each response with motion */}
            {responses.map((response, index) => {
              const questionIcon = questions.find(
                (q) => q.question === response.question
              )?.icon;

              return (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{
                    duration: 0.5,
                    // delay: index * 0.1,
                    delay: 0,
                    ease: 'easeInOut',
                  }}
                >
                  <Grid item md={isCompleted ? 7.3 : 4.9}>
                    <Box className="response-item">
                      <Grid
                        item
                        xs={12}
                        md={isCompleted ? 9 : 7}
                        sm={isCompleted ? 8 : 6}
                        lg={isCompleted ? 9 : 7}
                      >
                        <Box className="response-question-box">
                          {/* Display the icon associated with the response */}
                          {questionIcon && (
                            <Box className="icon">
                              {React.createElement(questionIcon, {
                                className: 'icon',
                              })}
                            </Box>
                          )}

                          <Box>
                            <Typography
                              variant="h6"
                              className="respose-question"
                            >
                              {response.question}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Box sx={{ flex: 0.2 }}>
                        {response.response === 'yes' ? (
                          <SentIcon className="sentCrossIcon" />
                        ) : (
                          <CrossIcon className="sentCrossIcon" />
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </motion.div>
              );
            })}

            {isCompleted ? (
              <Box
                className="completionMsgBox"
                //  sx={{ mt: { xs: 4, md: 12 } }}
              >
                <Typography variant="h6" className="completion-message">
                  {t('personalize-completion-one')}
                </Typography>
                <Typography variant="h6" className="completion-message">
                  {t('personalize-completion-two')}
                </Typography>
              </Box>
            ) : (
              <motion.div
                initial={{ opacity: 1, y: 0 }}
                animate={{ opacity: 1, y: 20 }}
                transition={{ duration: 0.1, ease: 'easeInOut' }}
              >
                <Box className="setup-box-steps">
                  <Box
                    sx={{
                      flex: {
                        xs: 1,
                        sm: 0.6,
                        md: 0.6,
                        lg: 0.8,
                      },
                    }}
                  >
                    <Box className="rectangle-box">
                      <Box
                        className="yellowBarBox"
                        sx={{
                          height: {
                            xs: '3.125rem',
                            sm: '6.875rem',
                            md: '6.875rem',
                          },
                        }}
                      >
                        <YellowBar className="yellowBar" />
                      </Box>
                      <Box className="setup-icon-box">
                        {/* Use the icon associated with the current question */}
                        {questionIconMap[
                          questions[currentQuestionIndex].question
                        ] ? (
                          React.createElement(
                            questionIconMap[
                              questions[currentQuestionIndex].question
                            ],
                            {
                              className: 'rectIcon',
                            }
                          )
                        ) : (
                          <BankIcon />
                        )}
                      </Box>
                      {currentQuestionIndex < questions.length && (
                        <Box>
                          <Typography
                            variant="h6"
                            className="rect-question-title"
                          >
                            {questions[currentQuestionIndex].question}
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <Box className="buttons">
                      <Button
                        variant="outlined"
                        className="no-button"
                        onClick={handleNoClick}
                      >
                        {t('no')}
                      </Button>
                      <Button
                        variant="outlined"
                        className="yes-button"
                        onClick={handleYesClick}
                      >
                        {t('yes')}
                      </Button>
                    </Box>
                  </Box>

                  {currentQuestionIndex < questions.length && (
                    <Box sx={{ flex: { lg: 0.6, md: 0.35, sm: 0.35 }, mb: 2 }}>
                      <Typography className="setup-description" variant="body1">
                        {questions[currentQuestionIndex]?.description}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </motion.div>
            )}
          </Grid>
        </Grid>

        {isCompleted ? <CardComponent responses={responses} /> : null}
      </Grid>
    </>
  );
};

export default Personalize;
