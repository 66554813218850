import { createSlice } from '@reduxjs/toolkit';
interface Document {
  size: number;
  name: string;
  type: string;
  // Add other properties as needed
}
interface IInitialState {
  uploadDocumentList: Document[];
}
const initialState: IInitialState = {
  uploadDocumentList: [],
};
const documentUploadSlice = createSlice({
  name: 'documentUploadSlice',

  initialState,
  reducers: {
    setDocumentUploadList(state, { payload }) {
      state.uploadDocumentList = payload;
    },
    resetDocumentUploadList(state) {
      state.uploadDocumentList = [];
    },
  },
});
const { actions } = documentUploadSlice;
export const documentUploadSliceActions = actions;
export default documentUploadSlice.reducer;
