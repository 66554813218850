import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  Grid,
  Paper,
  useTheme,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  Divider,
  styled,
  StepConnector,
  StepLabelProps,
  stepConnectorClasses,
  StepButton,
} from '@mui/material';
import Notes from '../../components/notes/notes';
import beneficiaryImg from '../../assets/images/beneficiaryLogo.png';
import assetsImg from '../../assets/images/addAssetsNote.png'; // Add the new logo for assets
import CustomStepIcon from './CustomStepIcon'; // Make sure this path is correct
import CustomButton from '../../components/common/CustomButton';
import HeaderComponent from '../../components/common/header/HeaderComponent';
import OnboardingAddEditBeneficiary from '../../components/add-edit-beneficiary/OnboardingAddEditBeneficiary';
import { useDispatch, useSelector } from 'react-redux';
import { authSliceActions } from '../../redux-store/reducers/authReducer';
import { IProceed } from '../personalize-question/personalize.type';
import { activeOnbordingStep, api } from '../../utils/constants';
import PersonalizeService from '../personalize-question/pesonalize.service';
import { useHttpClient } from '../../hooks/usecorrelation';
import { appSliceActions } from '../../redux-store/reducers/appReducer';
import OnboardingAddAssets from '../../components/add-assets/OnboardingAddAssets';
import arrowImg from '../../assets/images/arrow.png';
import './onboarding.css';
import WellnessCheck from '../wellness-check/WellnessCheck';
import { useNavigate } from 'react-router-dom';
import Congratulation from '../wellness-check/Congratulation';
import DashboardHeader from '../../components/common/header/DashboardHeader';
import { beneficiarySliceActions } from '../../redux-store/reducers/beneficiaryReducer';
import { assetsSliceActions } from '../../redux-store/reducers/assetsreducer';
import { IBeneficiary } from './onboarding.type';

const steps = ['Add Beneficiary', 'Add Assets', 'Wellness Check'];

const Onboarding: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const httpClient = useHttpClient();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onboardingScreenTime = useSelector(
    (state: any) => state.beneficiary.onboardingScreenTime
  );
  const activeStep = useSelector((state: any) => state.auth.activeStep);
  const userData = useSelector((state: any) => state.auth.loginData);
  const beneficiaries: IBeneficiary[] = useSelector(
    (state: any) => state.beneficiary.beneficieriesList
  );
  // for step navigariopn
  const handleStepClick = async (clickedStep: number) => {
    if (clickedStep < activeStep) {
      dispatch(appSliceActions.setIsLoader(true));
      const reqBody: IProceed = {
        userId: userData.userId,
        onBoardingStartScreenTime: onboardingScreenTime,
        onBoardingCurrentPage: activeOnbordingStep[clickedStep],
      };
      const response = await PersonalizeService.putPersonalization(
        httpClient,
        reqBody
      );
      if (response) {
        dispatch(authSliceActions.setActiveStep(clickedStep));
      }
      dispatch(appSliceActions.setIsLoader(false));
    }
  };

  const handleNext = async () => {
    dispatch(appSliceActions.setIsLoader(true));
    const reqBody: IProceed = {
      userId: userData.userId,
      onBoardingStartScreenTime: onboardingScreenTime,
      onBoardingCurrentPage: activeOnbordingStep[activeStep + 1],
    };
    const response = await PersonalizeService.putPersonalization(
      httpClient,
      reqBody
    );
    if (response) {
      if (activeStep !== 3) {
        dispatch(authSliceActions.setActiveStep(activeStep + 1));
      } else {
        navigate('/dashboard');
      }
    }
    dispatch(appSliceActions.setIsLoader(false));
  };

  const StepButtonContainer = styled(Box)({
    position: 'relative',
    display: 'inline-block',
  });

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <OnboardingAddEditBeneficiary />;
      case 1:
        return <OnboardingAddAssets />;
      case 2:
        return <WellnessCheck></WellnessCheck>;
      case 3:
        return <Congratulation></Congratulation>;
      default:
        return <Typography>Unknown step</Typography>;
    }
  };

  const getButtonText = (step: number) => {
    switch (step) {
      case 0:
        return t('proceedToAddAssets');
      case 1:
        return t('SetWellnessCheck');
      case 2:
        return t('Proceed');
      default:
        return t('ProceedToDasboard');
    }
  };

  const getNotesText = (step: number): string[] => {
    switch (step) {
      case 0:
        return t('beneficiaryNote', { returnObjects: true }) as string[];
      case 1:
        return t('AddAssetsNote', { returnObjects: true }) as string[];
      default:
        return [];
    }
  };

  const getTitle = (step: number) => {
    switch (step) {
      case 0:
        return t('beneficiaryTitle');
      case 1:
        return t('AddAssetsTitle');
      default:
        return '';
    }
  };

  const getSubtitle = (step: number) => {
    switch (step) {
      case 0:
        return t('beneficiarySubTitle');
      case 1:
        return t('AddAssetsSubTitle');
      default:
        return '';
    }
  };
  const getSubtitle2 = (step: number) => {
    switch (step) {
      case 0:
        return t('beneficiarySubTitle2');
      case 1:
        return t('AddAssetsSubTitle2');
      default:
        return '';
    }
  };
  const getLogo = (step: number) => {
    switch (step) {
      case 0:
        return beneficiaryImg;
      case 1:
        return assetsImg;
      default:
        return beneficiaryImg;
    }
  };
  interface CustomStepLabelProps extends StepLabelProps {
    active?: boolean;
    completed?: boolean;
  }
  const CustomStepLabel = styled(StepLabel, {
    shouldForwardProp: (prop) => prop !== 'active' && prop !== 'completed',
  })<CustomStepLabelProps>(({ theme, active, completed }) => ({
    '& .MuiStepLabel-label': {
      color: active || completed ? '#112F8E !important' : '#9CA7B7 !important',
      fontWeight: active || completed ? '800 !important' : '800 !important',
      fontSize: '16px !important',
      /* Media Query for 150% Scaling */
      '@media (min-resolution: 144dpi) and (max-resolution: 149dpi)': {
        fontSize: 'calc(20px * 0.50) !important',
      },
      /* Media query for 125% display scaling */
      '@media (min-resolution: 120dpi) and (max-resolution: 124dpi)': {
        fontSize: 'calc(16px * 0.75) !important',
      },
    },
  }));

  const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#112F8E !important',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  return (
    <Grid container sx={{ height: '100%', overflowY: 'hidden' }}>
      <DashboardHeader />
      {activeStep !== 3 && (
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{
              paddingTop: theme.spacing(3),
              paddingBottom: theme.spacing(3),
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box className="StepperContainer">
              <Stepper
                activeStep={activeStep}
                alternativeLabel={isMobile}
                connector={<CustomStepConnector />}
                sx={{
                  width: { xs: '100%', sm: '40%' },
                }}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButtonContainer>
                      <StepButton
                        onClick={() => handleStepClick(index)}
                        disabled={index >= activeStep}
                      >
                        <CustomStepLabel
                          active={activeStep === index}
                          completed={activeStep > index}
                          StepIconComponent={(props) => (
                            <CustomStepIcon
                              {...props}
                              active={props.active || activeStep === index}
                              completed={activeStep > index}
                              index={index}
                            />
                          )}
                        >
                          {label}
                        </CustomStepLabel>
                      </StepButton>
                    </StepButtonContainer>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Paper>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        sx={{
          marginLeft: { xs: 0, sm: 2 },
          marginRight: { xs: 0, sm: 2 },
          height: '100%',
        }}
      >
        {(activeStep === 0 || activeStep === 1) && (
          <Grid
            className="onboardingGrid"
            container
            sx={{
              backgroundColor: '#F0F6F9',
              borderRadius: {
                xs: '12px ',
                sm: '12px ',
              },
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {!isMobile && (
              <Grid
                item
                xs={12}
                sm={4}
                md={2.4}
                sx={{
                  // padding: theme.spacing(1),
                  padding: '8px 20px',
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'flex-start',
                  position: 'relative',
                  top: '7.5rem',
                }}
              >
                <Notes
                  logo={getLogo(activeStep)}
                  noteTextArray={getNotesText(activeStep)}
                />
              </Grid>
            )}
            {!isMobile && (
              <Divider
                className="VerticalDivider"
                orientation="vertical"
                variant="middle"
                flexItem
                // sx={{
                //   marginTop: '30px',
                //   height: '62vh',
                // }}
              />
            )}
            <Grid
              item
              xs={11}
              sm={7.8}
              md={9.5}
              sx={{ padding: theme.spacing(1) }}
            >
              <Grid container>
                <Grid
                  item
                  md={12}
                  sx={{
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box className="OnboardingBox">
                    <Typography className="OnboardingTitle" variant="body1">
                      {getTitle(activeStep)}
                    </Typography>
                    <Typography variant="body1" className="OnboardingSubtitle">
                      {getSubtitle(activeStep)}
                      <br />
                      {getSubtitle2(activeStep)}
                    </Typography>
                    {renderStepContent(activeStep)}
                  </Box>
                  <Divider
                    orientation="horizontal"
                    sx={{
                      paddingTop: '10px',
                      marginLeft: '-17px',
                      marginRight: '80px',
                      // Pushes the divider to the left
                    }}
                    variant="middle"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: {
                      xs: 'flex-start',
                      sm: 'space-between',
                    },
                    flexDirection: {
                      xs: 'column',
                      sm: 'row',
                    },
                    alignItems: 'center',
                    padding: theme.spacing(2),
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexGrow: 1,
                    }}
                  >
                    <Typography className="beneficiaryBottomNote">
                      {t('beneficiaryBottomNote')}
                    </Typography>
                  </Box>

                  {/* This is the button section, placed at the bottom */}
                  <CustomButton
                    style={{
                      marginRight: '60px',
                    }}
                    handleClick={handleNext}
                    buttonfullWidth={false}
                    text={getButtonText(activeStep)}
                    icon={
                      <img
                        src={arrowImg}
                        alt="icon"
                        style={{ width: '18px', height: '18px' }}
                      />
                    }
                    disabled={activeStep === 0 && beneficiaries.length === 0}
                  />
                </Grid>
              </Grid>
            </Grid>{' '}
          </Grid>
        )}
        {(activeStep === 2 || activeStep === 3) && (
          <Grid
            container
            sx={{
              backgroundColor: '#F0F6F9',
              borderRadius: {
                xs: '12px ',
                sm: '12px ',
              },
              display: 'flex',
              justifyContent: 'center', // Center horizontally
              alignItems: 'center', // Center vertically
            }}
            className={
              activeStep === 3
                ? 'congratulationContainer'
                : 'wellNessCheckMainContainer'
            }
          >
            <Grid item xs={12} sm={12} md={12}>
              {renderStepContent(activeStep)}
              <Divider
                orientation="horizontal"
                sx={{
                  paddingTop: '30px',
                }}
                variant="fullWidth"
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: {
                  xs: 'flex-start',
                  sm: 'space-between',
                },
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
                alignItems: 'center',
                paddingTop: theme.spacing(2),
                height: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                }}
              >
                <Typography
                  className="beneficiaryBottomNote"
                  sx={{
                    width: {
                      xs: '90vw',
                      sm: '40vw',
                    },
                    textAlign: 'start', // Center the text horizontally
                  }}
                >
                  {t('beneficiaryBottomNote')}
                </Typography>
              </Box>

              {/* This is the button section, placed at the bottom */}
              <CustomButton
                handleClick={handleNext}
                buttonfullWidth={false}
                text={getButtonText(activeStep)}
                icon={
                  <img
                    src={arrowImg}
                    alt="icon"
                    style={{ width: '18px', height: '12px' }}
                  />
                }
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Onboarding;
