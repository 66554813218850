import { Dialog, DialogContent, DialogContentText, Grid } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../CustomButton';
import './confirmPopup.css';
import logoutIcon from '../../../assets/images/icons/log-out-icon.png';

type IProps = {
  isOpenDialog: boolean;
  onDialogClose: () => void;
  onConfirmationClick: () => void;
  color?: string;
};

const LogoutPopUp: FC<IProps> = ({
  isOpenDialog,
  onConfirmationClick,
  onDialogClose,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        open={isOpenDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: '20px',
            maxWidth: '500px',
            maxHeight: '320px',
            padding: '20px',
          },
        }}
        disableEscapeKeyDown
        hideBackdrop={false}
        onClose={(event, reason) => {
          // Only close the dialog if the reason is not 'backdropClick'
          if (reason === 'backdropClick') {
            return;
          }
          onDialogClose();
        }}
      >
        <Grid container textAlign="center" className="boxPop-container">
          <Grid item xs={12} md={12} sm={12}>
            <img
              src={logoutIcon}
              alt="logoutIcon"
              style={{ padding: '0rem 0rem 2rem 0rem' }}
            ></img>
            <DialogContent>
              <DialogContentText className="dialogSubTitle">
                {t('logouttitle')}
              </DialogContentText>
            </DialogContent>
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item>
                <CustomButton
                  text={t('button.cancel')}
                  handleClick={onDialogClose}
                  buttonfullWidth={false}
                  buttonType="secondary"
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <CustomButton
                  text={t('button.logout')}
                  handleClick={onConfirmationClick}
                  buttonfullWidth={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default LogoutPopUp;
