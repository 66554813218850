import yup from '../../services/validator.service';

class PersonalizeModel {
  static readonly PersonalizeRespSchema = yup.object({
    data: yup
      .object({
        bankAccounts: yup.boolean().nullable(),
        brokerageAccounts: yup.boolean().nullable(),
        insurancePolicy: yup.boolean().nullable(),
        retirementAccounts: yup.boolean().nullable(),
        realEstate: yup.boolean().nullable(),
        cryptoAccounts: yup.boolean().nullable(),
        professionalService: yup.boolean().nullable(),
        willSetUp: yup.boolean().nullable(),
      })
      .nullable(),
    result: yup.string().defined(),
    message: yup.string().defined(),
    errorCode: yup.number().defined(),
    correlationId: yup.number().nullable(),
  });
  static readonly userSchema = yup.object({
    userId: yup.number().defined(),
    email: yup.string().defined(),
    name: yup.string().defined(),
    phoneNumber: yup.string().defined(),
    address: yup.string().defined(),
    birthYear: yup.number().defined(),
    gender: yup.string().defined(),
    socialProvider: yup.string().defined(),
    socialProviderId: yup.string().defined(),
    isEmailVerified: yup.boolean().defined(),
    isSubscriptionActive: yup.boolean().defined(),
    isDeleted: yup.boolean().defined(),
    isBeneficiary: yup.boolean().defined(),
    isUserPassedAway: yup.boolean().defined(),
    isProfileComplete: yup.boolean().defined(),
    isOnboardingComplete: yup.boolean().defined(),
    dataArchivalDate: yup.date().defined(),
    createdAt: yup.date().defined(),
    updatedAt: yup.date().defined(),
    createdBy: yup.string().defined(),
    updatedBy: yup.string().defined(),
  });

  static readonly PersonalizeUserRespSchema = yup.object({
    data: yup
      .object({
        userOnboardingId: yup.number().defined(),
        user: this.userSchema,
        personalizationDetail: yup.string().defined(),
        currentScreenName: yup.string().defined(),
        onboardingStartTime: yup.date().defined(),
        onboardingEndTime: yup.date().defined(),
        onboardingScreenTime: yup.string().defined(),
        createdAt: yup.date().defined(),
        updatedAt: yup.date().defined(),
        createdBy: yup.string().defined(),
        updatedBy: yup.string().defined(),
      })
      .nullable(),
    result: yup.string().defined(),
    message: yup.string().nullable(),
    errorCode: yup.number().defined(),
    correlationId: yup.string().nullable(),
  });
}

export default PersonalizeModel;
