import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import EyeIcon from '../../assets/images/eye-icon.svg';
import { Typography, Box } from '@mui/material';
import LogoComponent from '../../components/common/LogoComponent';
import FormField from '../../components/common/FormField';
import CustomButton from '../../components/common/CustomButton';
import { redirectLink, validatePassword } from '../../utils/commonFunctions';
import Auth from './Auth';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthService from './auth.service';
import { useHttpClient } from '../../hooks/usecorrelation';
import { IResetPassword, ISetPassword, IUserData } from './auth.type';
import ErrorMessageInfo from '../../components/common/ErrorMsgInfo';
import './auth.css';
import { useSelector } from 'react-redux';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface IFormInput {
  password: string;
  confirmPassword: string;
}

const SetPassword = () => {
  const httpClient = useHttpClient();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const query = useQuery();
  const [token, setToken] = useState<string>();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const userData: IUserData = useSelector((state: any) => state.auth.loginData);
  useEffect(() => {
    setToken(query.get('token')!);
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<IFormInput>({
    defaultValues: {
      confirmPassword: '',
      password: '',
    },
    mode: 'onChange',
  });

  // Update onSubmit to use SubmitHandler<FormData>
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    let reqBody: IResetPassword | ISetPassword;
    if (token) {
      reqBody = {
        resetPasswordToken: token!,
        newPassword: data.password,
      };
    } else {
      reqBody = {
        userId: userData.userId,
        newPassword: data.password,
      };
    }

    const responseData = await AuthService.postResetPassword(
      httpClient,
      reqBody
    );
    if (responseData?.message === 'Password updated successfully') {
      const navigatePage = redirectLink(responseData?.onboardingCurrentPage);
      navigate(navigatePage);
    } else {
      setErrorMsg(responseData ? responseData?.message : '');
    }
  };

  const validateConfirmPassword = (value: string) => {
    const password = getValues('password');
    if (value !== password) {
      return t('passwordMismatch');
    }
    return true;
  };
  return (
    <Auth>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        className={'box-container'}
      >
        <LogoComponent isCommonClass={true} />
        <Typography variant="h4" className="welcomeText">
          {t('setNewPassword')}
        </Typography>
        <Typography variant="body1" gutterBottom className="getStarted">
          {t('passwordInfo')}
        </Typography>

        <FormField
          name="password"
          control={control}
          label={t('label.newPassword')}
          placeholder={t('placeholder.passwordPlaceholder')}
          type="password"
          error={errors.password?.message}
          icon={EyeIcon}
          rules={{
            validate: (password) => validatePassword(password, t),
            maxLength: {
              value: 50,
              message: t('maxLengthExceeded', { max: 50 }),
            },
          }}
          showRequired={true}
        />

        <FormField
          name="confirmPassword"
          control={control}
          label={t('label.confirmPassword')}
          placeholder={t('placeholder.confirmPassword')}
          type="password"
          error={errors.confirmPassword?.message}
          icon={EyeIcon}
          rules={{
            validate: validateConfirmPassword,
            maxLength: {
              value: 50,
              message: t('maxLengthExceeded', { max: 50 }),
            },
          }}
          showRequired={true}
        />
        {errorMsg && (
          <ErrorMessageInfo errorMessage={errorMsg}></ErrorMessageInfo>
        )}

        <CustomButton
          text={t('button.signIn')}
          style={{ mt: 2 }}
          disabled={
            !!errors.password ||
            !!errors.confirmPassword ||
            !getValues('password')
          }
        />
      </Box>
    </Auth>
  );
};

export default SetPassword;
