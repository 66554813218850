import yup from '../../services/validator.service';

class DashboardModel {
  static getSupportsResponseSchema = yup.object().shape({
    result: yup.string().required(),
    message: yup.string().required(),
    errorCode: yup.number().required(),
  });
  static getDashboardResponseSchema = yup.object().shape({
    data: yup
      .object()
      .shape({
        lastLoginTime: yup.string().required(),
        isBeneficiary: yup.boolean().required(),
        beneficiary: yup
          .object()
          .shape({
            count: yup.number().required(),
            names: yup.array().of(yup.string()).required(),
          })
          .required(),
        assets: yup
          .object()
          .shape({
            count: yup.number().required(),
            types: yup.array().of(yup.string()).required(),
          })
          .required(),
        professionalContacts: yup
          .object()
          .shape({
            count: yup.number().required(),
            names: yup.array().of(yup.string()).required(),
          })
          .required(),
        documents: yup
          .object()
          .shape({
            count: yup.number().required(),
            names: yup.array().of(yup.string()).required(),
          })
          .required(),
      })
      .required(),
    result: yup.string().required(),
    message: yup.string().required(),
    errorCode: yup.number().required(),
    correlationId: yup.mixed().nullable(),
  });
  static getWellnessCheckDataSchema = yup.object().shape({
    data: yup
      .object()
      .shape({
        wellnessCheckId: yup.number().required(),
        duration: yup.number().required(),
      })
      .required(),
    result: yup.string().required(),
    message: yup.string().required(),
    errorCode: yup.number().required(),
  });
}

export default DashboardModel;
