import React from 'react';
import {
  Box,
  Typography,
  Avatar,
  useMediaQuery,
  useTheme,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import './Notes.css';
import { useSelector } from 'react-redux';

interface NotesProps {
  logo: string;
  noteTextArray: string[];
}

const Notes: React.FC<NotesProps> = ({ logo, noteTextArray }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const activeStep = useSelector((state: any) => state.auth.activeStep);

  return (
    <Box className="notes-container" sx={{ width: { lg: '80%' } }}>
      <Avatar
        src={logo}
        alt="logo"
        className={`notes-avatar ${!isSmallScreen ? 'md' : ''}`}
      />
      <Typography
        variant="body1"
        className={`notes-text ${!isSmallScreen ? 'md' : ''}`}
      >
        {noteTextArray.map((sentence, index) => (
          <React.Fragment key={index}>
            {sentence}
            {/* {index === 0 && activeStep === 0 ? (
              <Link
                className="privacyPolicy"
                href="#"
                underline="none"
                color="primary"
                fontWeight="500"
              >
                {t('sampleEmail')}
              </Link>
            ) : null} */}
            <br />
            <br />
          </React.Fragment>
        ))}
      </Typography>
    </Box>
  );
};

export default Notes;
