import React, { useEffect } from 'react';
import { Grid, Paper } from '@mui/material';
import './welcome.css';
import BeneficiariesList from '../BeneficiariesList/BeneficiariesList';
import AddEditBeneficiary from '../add-edit-beneficiary/AddEditBeneficiary';
import DashboardWraper from './DashboardWraper';
import { useDispatch } from 'react-redux';
import { beneficiarySliceActions } from '../../redux-store/reducers/beneficiaryReducer';

const DashboardAddEditBeneficiary = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(beneficiarySliceActions.resetState());
    // dispatch(assetsSliceActions.resetState());
  }, []);
  return (
    <DashboardWraper>
      <Paper
        sx={{
          borderRadius: '12px',
          border: '1px solid #D0D5DD',
          boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Grid container flexDirection="row-reverse">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            xl={6}
            sx={{ padding: '25px 25px 20px 25px' }}
          >
            <AddEditBeneficiary />
          </Grid>
          <Grid item xs={12} sm={6} md={6} sx={{}}>
            <BeneficiariesList />
          </Grid>
        </Grid>
      </Paper>
    </DashboardWraper>
  );
};

export default DashboardAddEditBeneficiary;
