import styled from '@emotion/styled';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#252B42',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000000',
    fontSize: '14px',
  },
}));
export default BootstrapTooltip;
