import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import PersonIcon from '../../assets/images/no-users.png';
import EditSvg from '../../assets/images/icons/edit.png';
import DeletePng from '../../assets/images/icons/delete.png';
import { IBeneficiary } from '../../pages/onboarding/onboarding.type';
import { useDispatch, useSelector } from 'react-redux';
import { beneficiarySliceActions } from '../../redux-store/reducers/beneficiaryReducer';
import OnboardingService from '../../pages/onboarding/onboarding.service';
import { useHttpClient } from '../../hooks/usecorrelation';
import { appSliceActions } from '../../redux-store/reducers/appReducer';
import { useTranslation } from 'react-i18next';
import ConfirmPopUp from '../common/popup/ConfirmPopUp';
import { avatarColors } from '../../utils/constants';
import { formatPhoneNumber, getInitials } from '../../utils/commonFunctions';
import '../add-assets/addAssets.css';
import BootstrapTooltip from '../common/CommonTooltip';

const BeneficiariesList: React.FC = () => {
  const [editMode, setEditMode] = useState<number | null>(null);
  const dispatch = useDispatch();
  const httpClient = useHttpClient();
  const { t } = useTranslation();
  const location = useLocation();
  const isDashboard = location.pathname === '/dashboard';
  const beneficiaries: IBeneficiary[] = useSelector(
    (state: any) => state.beneficiary.beneficieriesList
  );
  const userData = useSelector((state: any) => state.auth.loginData);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  useEffect(() => {
    const fetchBeneficiaryData = async () => {
      let userId = userData.userId;
      dispatch(appSliceActions.setIsLoader(true));
      await OnboardingService.getBeneficiaryData(httpClient, userId);
      dispatch(appSliceActions.setIsLoader(false));
    };

    fetchBeneficiaryData();
  }, []);

  const handleEditClick = (id: number) => {
    setEditMode(id);
    if (beneficiaries) {
      let editBeneficiary = beneficiaries.find((b) => b.beneficiaryId === id)!;
      dispatch(beneficiarySliceActions.setEditBeneficiary(editBeneficiary));
    }
  };

  const handleDeleteClick = (id: number, event: React.MouseEvent) => {
    dispatch(beneficiarySliceActions.setEditBeneficiary(null));
    event.stopPropagation();
    setDeleteId(id);
    setDeletePopup(true);
  };

  const handleCancleDelete = () => {
    setDeleteId(null);
    setDeletePopup(false);
  };

  const handleConfirmDelete = async () => {
    dispatch(appSliceActions.setIsLoader(true));
    const response = await OnboardingService.deleteBeneficiary(
      httpClient,
      deleteId!
    );
    setDeletePopup(false);
    dispatch(appSliceActions.setIsLoader(false));
  };

  return (
    <Box
      className="listConatiner"
      sx={{ minHeight: isDashboard ? '69vh' : '46vh' }}
    >
      {beneficiaries.length !== 0 && (
        <Typography className="myBeneficiaries">
          {t('myBeneficiaries')}
        </Typography>
      )}

      {beneficiaries.length === 0 ? (
        <Box className="BeneListBox">
          <img className="PersonIcon" src={PersonIcon} alt="No Beneficiaries" />{' '}
          <Typography marginTop="20px" color="#9CA7B7" fontWeight={700}>
            {t('noBeneficiary')}
          </Typography>
        </Box>
      ) : (
        <Box
          className="custom-scrollbar"
          sx={{
            overflowY: 'auto',
            // minHeight:'70vh',
            maxHeight: isDashboard ? '68vh' : '38vh !important ', // Adjust the maxHeight as needed
          }}
        >
          {beneficiaries.map((beneficiary, index) => (
            <div key={index}>
              <Paper
                sx={{
                  padding: 1,
                  marginBottom: 1,
                  backgroundColor: '#ffffff',

                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                  boxShadow:
                    editMode === beneficiary.beneficiaryId
                      ? '2px 2px 4px 0px rgba(0, 0, 0, 0.25)'
                      : 'none',
                }}
                onClick={() => handleEditClick(beneficiary.beneficiaryId!)}
              >
                <Avatar
                  className="beneficiary-avatar"
                  sx={{
                    backgroundColor: avatarColors[index % avatarColors.length],
                  }}
                >
                  {getInitials(beneficiary.name)}
                </Avatar>

                {/* Beneficiary Info */}
                <Box
                  sx={{
                    marginLeft: { sm: '16px' },
                    flexGrow: 1,
                    textAlign: { xs: 'center', sm: 'left' },
                  }}
                >
                  <Typography
                    variant="body1"
                    className="BeneficiaryName"
                    fontWeight={600}
                    color={
                      editMode === beneficiary.beneficiaryId
                        ? '#112F8E'
                        : '#252B42'
                    }
                  >
                    {beneficiary.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="BeneficiaryEmail"
                    color="#9CA7B7"
                    fontWeight="500"
                  >
                    {beneficiary.email}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="BeneficiaryNum"
                    color="#9CA7B7"
                    fontWeight="500"
                  >
                    {formatPhoneNumber(beneficiary.phoneNumber)}
                  </Typography>
                </Box>

                {/* Action Buttons (Edit and Delete) */}
                <Box className="action-button-box">
                  <BootstrapTooltip title={t('button.edit')}>
                    <IconButton
                      onClick={() =>
                        handleEditClick(beneficiary.beneficiaryId!)
                      }
                      className="icon-button"
                      sx={{
                        '&:hover': {
                          backgroundColor: 'lightgray', // Change background on hover
                        },
                      }}
                    >
                      <img
                        className="editDeleteIcons"
                        src={EditSvg}
                        alt="edit"
                        // width="24px"
                        // height="24px"
                      />
                    </IconButton>
                  </BootstrapTooltip>

                  <BootstrapTooltip title={t('button.delete')}>
                    <IconButton
                      onClick={(event) =>
                        handleDeleteClick(beneficiary.beneficiaryId!, event)
                      }
                      className="icon-button"
                      sx={{
                        '&:hover': {
                          backgroundColor: 'lightgray', // Change background on hover
                        },
                      }}
                    >
                      <img
                        className="editDeleteIcons"
                        src={DeletePng}
                        alt="delete"
                      />
                    </IconButton>
                  </BootstrapTooltip>
                </Box>
              </Paper>
            </div>
          ))}
        </Box>
      )}
      {deletePopup && (
        <ConfirmPopUp
          isOpenDialog={true}
          confirmTitle={t('areYouSure')}
          onConfirmationClick={() => {
            handleConfirmDelete();
          }}
          onDialogClose={() => {
            handleCancleDelete();
          }}
          subTitle1={t('deleteBeneficiarySub1')}
          subTitle2={t('deleteBeneficiarySub2')}
        />
      )}
    </Box>
  );
};

export default BeneficiariesList;
