import ProfessionalContact from '../add-edit-beneficiary/ProfessionalContact';
import DashboardWraper from './DashboardWraper';

const DashboardProfessionalContect = () => {
  return (
    <>
      <DashboardWraper>
        <ProfessionalContact></ProfessionalContact>
      </DashboardWraper>
    </>
  );
};
export default DashboardProfessionalContect;
