import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import Loader from './components/common/loader/Loader';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from './components/common/error-boundary/ErrorBoundary';

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <BrowserRouter>
        <Loader></Loader>
        <AppRoutes></AppRoutes>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
