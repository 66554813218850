import i18n from '../../i18n';
import { authSliceActions } from '../../redux-store/reducers/authReducer';
import store from '../../redux-store/store';
import { getJwtToken } from '../../services/auth.service';
import { api } from '../../utils/constants';
import AuthModel from './auth.model';
import {
  IForgotPassReqBody,
  ILoginPostDetails,
  IResetPassword,
  ISetPassword,
  ISignInReqBody,
  ISocialLoginReqBody,
  IUserData,
} from './auth.type';

class AuthService {
  static postLoginDetails = async (client: any, data: ILoginPostDetails) => {
    let returnResponse = {
      message: '',
      onboardingCurrentPage: '',
    };
    try {
      const response = await client.post(
        api.postLogin,
        data,
        AuthModel.AuthLoginDetailSchema
      );
      returnResponse = {
        message: response.message,
        onboardingCurrentPage: '',
      };
      if (response) {
        // Check if the result is success or failure
        if (response.result === 'success') {
          // Dispatch success action with user data to Redux
          store.dispatch(authSliceActions.setUserData(response.data));
          returnResponse = {
            message: response.message,
            onboardingCurrentPage: response.data.onBoardingCurrentPage,
          };
        } else {
          // Handle failure case from response message
          store.dispatch(authSliceActions.setUserData(response.data));
        }
        return returnResponse;
      }
    } catch (error) {
      returnResponse.message = i18n.t('unExpectedError');
      return returnResponse;
    }
  };
  static postSignUpDetails = async (client: any, reqData: ISignInReqBody) => {
    // const signUpUrl = `${api.signUp}?email=${email}`;
    try {
      const response = await client.post(
        api.signUp,
        reqData,
        AuthModel.AuthSignupDetailSchema
      );
      if (response) {
        // Check if the result is success or failure
        if (response.errorCode === 200) {
          // Dispatch success action with user data to Redux
          store.dispatch(authSliceActions.setUserData(response.data));
          return response.data.signUpFlowFlag;
        } else {
          // Handle failure case from response message
          store.dispatch(authSliceActions.setUserData(response.data));
        }
      }
    } catch (error) {
      return i18n.t('unExpectedError');
    }
  };
  static postSocialLogin = async (
    httpClient: any,
    reqBody: ISocialLoginReqBody
  ) => {
    let returnResponse = {
      message: '',
      onboardingCurrentPage: '',
    };
    try {
      const response = await httpClient.post(
        api.socialLogin,
        reqBody,
        AuthModel.AuthLoginDetailSchema
      );
      returnResponse = {
        message: response.message,
        onboardingCurrentPage: '',
      };
      if (response) {
        // Check if the result is success or failure
        if (response.errorCode === 200) {
          // Dispatch success action with user data to Redux
          store.dispatch(authSliceActions.setUserData(response.data));
          returnResponse = {
            message: response.message,
            onboardingCurrentPage: response.data.onBoardingCurrentPage,
          };
        } else {
          // Handle failure case from response message
          store.dispatch(authSliceActions.setUserData(response.data));
        }
        return returnResponse;
      }
    } catch (error) {
      returnResponse.message = i18n.t('unExpectedError');
      return returnResponse;
    }
  };
  static postForgotPassword = async (
    httpClient: any,
    reqBody: IForgotPassReqBody
  ) => {
    try {
      const response = await httpClient.post(
        api.forgotPassword,
        reqBody,
        AuthModel.ForgotPassRespSchema
      );
      if (response) {
        // Check if the result is success or failure
        if (response.result === 'success') {
          // Dispatch success action with user data to Redux
          store.dispatch(authSliceActions.setUserData(response.data));
        } else {
          // Handle failure case from response message
          store.dispatch(authSliceActions.setUserData(response.data));
        }
        return response.result;
      }
    } catch (error) {
      return i18n.t('unExpectedError');
    }
  };
  static postResetPassword = async (
    httpClient: any,
    reqBody: IResetPassword | ISetPassword
  ) => {
    let returnResponse = {
      message: '',
      onboardingCurrentPage: '',
    };
    try {
      const url = (reqBody as IResetPassword).resetPasswordToken
        ? `${api.resetPassword}`
        : `${api.setPassword}`;
      const response = await httpClient.post(
        url,
        reqBody,
        AuthModel.AuthLoginDetailSchema
      );
      returnResponse = {
        message: response.message,
        onboardingCurrentPage: '',
      };
      if (response) {
        // Check if the result is success or failure
        if (response.errorCode === 200) {
          returnResponse = {
            message: response.message,
            onboardingCurrentPage: response.data.onBoardingCurrentPage,
          };
          // Dispatch success action with user data to Redux
          store.dispatch(authSliceActions.setUserData(response.data));
        } else {
          returnResponse.message = response.message;
          // Handle failure case from response message
          store.dispatch(authSliceActions.setUserData(response.data));
        }
        return returnResponse;
      }
    } catch (error) {
      returnResponse.message = i18n.t('unExpectedError');
      return returnResponse;
    }
  };
  static logout = async (httpClient: any) => {
    try {
      const jwtToken = getJwtToken();
      const response = await httpClient.post(
        '/auth/logout',
        jwtToken,
        AuthModel.logOutResSchema
      );
      // Check if the result is success or failure
      if (response && response.errorCode === 200) {
        // Dispatch success action with user data to Redux
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return i18n.t('unExpectedError');
    }
  };
  static updateUserProfile = async (
    httpClient: any,
    reqBody: any,
    userId: number
  ) => {
    try {
      const response = await httpClient.patch(
        `user/${userId}`,
        reqBody,
        AuthModel.updateUserProfileSchema
      );
      if (response && response.errorCode === 200) {
        const storedUserData: IUserData = store.getState().auth.loginData!;
        const updatedUserData: IUserData = {
          ...storedUserData,
          phone: reqBody.phoneNumber,
        };
        console.log('updatedUserData', updatedUserData);
        store.dispatch(authSliceActions.setUserData(updatedUserData));
        return response.result;
      } else {
        return response.message;
      }
    } catch (error) {
      return 'failed to update phone number , please try again';
    }
  };
}

export default AuthService;
