import React, { useEffect } from 'react';
import { Box, Grid, Paper, Typography, Link } from '@mui/material';
import DashboardWraper from '../../components/dashboard/DashboardWraper';
import { useTranslation } from 'react-i18next';
import './dashboard.css';

// Import images
import automated from '../../assets/images/wellnessIcons/automated.png';
import personalised from '../../assets/images/wellnessIcons/personalization.png';
import verified from '../../assets/images/wellnessIcons/verification.png';
import playStore from '../../assets/images/wellnessIcons/qr.png';
import appStore from '../../assets/images/wellnessIcons/android.png';
import greentick from '../../assets/images/wellnessIcons/tick.png';
import BeneficiaryUserAlive from '../../components/beneficiary/BeneficiaryUserAlive';
import appstoreimg from '../../assets/images/appstore.png';
import playstoreImage from '../../assets/images/playstoreImage.png';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionSliceActions } from '../../redux-store/reducers/subscriptionReducer';
import SubscriptionComponent from '../subscription/SubscriptionComponent';
import { activeOnbordingStep, subscriptionOption } from '../../utils/constants';
import { useHttpClient } from '../../hooks/usecorrelation';
import DashboardService from './dashboard.service';
import { IUserData } from '../auth/auth.type';
import { appSliceActions } from '../../redux-store/reducers/appReducer';

const DashboardWellness = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const httpClient = useHttpClient();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  const [selectedDay, setSelectedDay] = React.useState(0);
  useEffect(() => {
    const fetchWellnessCheckData = async () => {
      try {
        dispatch(appSliceActions.setIsLoader(true));
        const response = await DashboardService.getWellnessCheckData(
          httpClient,
          userData.userId
        );
        if (response.errorCode === 200) {
          setSelectedDay(response.data.duration);
        } else {
          setErrorMessage(t('Error_occured'));
        }
      } catch (error) {
        setErrorMessage(t('Error_occured'));
      }
      dispatch(appSliceActions.setIsLoader(false));
    };

    fetchWellnessCheckData();
  }, []);
  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage]);
  const handleUpgradeNow = () => {
    dispatch(subscriptionSliceActions.setIsSubscriptionDialogOpen(true));
  };
  const subscriptionPlan = useSelector(
    (state: any) => state.subscription.subscriptionOption
  );
  const isPaidPlan =
    subscriptionPlan === subscriptionOption.free ? false : true;
  const userData: IUserData = useSelector((state: any) => state.auth.loginData);
  const editWellCheckDuration = async (days: number) => {
    dispatch(appSliceActions.setIsLoader(true));
    const requestBody = {
      userId: userData.userId,
      duration: days,
      onBoardingCurrentPage: activeOnbordingStep[6],
      onBoardingStartScreenTime: new Date().toISOString(),
    };
    const response = await DashboardService.upgradeWellNessCheckFrequecy(
      httpClient,
      requestBody
    );
    if (response) {
      setSuccessMessage(t('Saved'));
      setErrorMessage('');
      setSelectedDay(days);
    } else {
      setErrorMessage(t('Error_occured'));
      setSuccessMessage('');
    }
    dispatch(appSliceActions.setIsLoader(false));
  };
  return (
    <DashboardWraper>
      <Paper
        sx={{
          borderRadius: '12px',
          border: '1px solid #D0D5DD',
          boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Box className="wellnessCheckBox" bgcolor="white" borderRadius="12px">
          <div className="topTextContainer">
            <Typography
              className="wellnessHeadingtexts"
              fontWeight="600 !important"
            >
              {t('SmartHeritance_conducts')}
            </Typography>
            <Typography
              className="wellnessHeadingtexts"
              fontWeight="600 !important"
            >
              {t('Upon_detecting')}
            </Typography>
          </div>

          <Grid container spacing={4} sx={{ marginTop: '40px' }}>
            <Grid item xs={12} md={4}>
              {isPaidPlan && (
                <Typography className="actionRequired">
                  {t('Your_action')}
                </Typography>
              )}
              <Box className="WellnessCard">
                <div
                  className={`cardTopChip ${isPaidPlan ? 'notactive' : 'upgradeRequired'}`}
                >
                  <p>{t(isPaidPlan ? 'NOT_ACTIVE' : 'Upgrade_now')}</p>
                </div>
                <Box className="iconBox">
                  <img
                    className="wellnessCardIcon"
                    src={automated}
                    alt="Automated"
                  />
                </Box>
                <Typography className="cardTitle">
                  {t('Automated_Check')}
                </Typography>
                <Typography className="cardText">{t('When_you')}</Typography>
              </Box>
              {isPaidPlan ? (
                // <Box className="belowCardContent">
                //   <Typography className="downloadText">
                //     {t('Download_Apps')}
                //   </Typography>
                //   <Box className="appStoreContainer">
                //     <Box className="storeItem">
                //       <img
                //         className="storeIcons"
                //         src={appStore}
                //         alt="App Store"
                //       />
                //       <Typography className="storeText">
                //         {t('Browser_Extension')}
                //       </Typography>
                //     </Box>
                //     <Box className="storeItem">
                //       <img
                //         className="storeIcons"
                //         src={playStore}
                //         alt="Play Store"
                //       />
                //       <img
                //         className="palystoreImage"
                //         src={appstoreimg}
                //         alt=""
                //       />
                //     </Box>
                //     <Box className="storeItem">
                //       <img
                //         className="storeIcons"
                //         src={playStore}
                //         alt="Play Store"
                //       />
                //       <img
                //         className="palystoreImage"
                //         src={playstoreImage}
                //         alt=""
                //       />
                //     </Box>
                //   </Box>
                // </Box> need to implememnt once app ready
                <></>
              ) : (
                <Box className="belowCardContent">
                  <Typography className="upgradeText">
                    {t('Automated_Check')}
                  </Typography>
                  <Link
                    href="#"
                    className="upgradeLink"
                    onClick={(e) => {
                      e.preventDefault();
                      handleUpgradeNow();
                    }}
                  >
                    {t('Upgrade_now')}
                  </Link>
                </Box>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <Box
                className="WellnessCard"
                marginTop={isPaidPlan ? '40px' : '0px'}
              >
                <div className="cardTopChip active">
                  <p>{t('Active')}</p>
                </div>
                <Box className="iconBox">
                  <img
                    className="wellnessCardIcon"
                    src={personalised}
                    alt="Personalized"
                  />
                </Box>
                <Typography className="cardTitle">
                  {t('Personalized_Check')}
                </Typography>
                <Typography className="cardText">{t('If_no')}</Typography>
              </Box>
              {isPaidPlan ? (
                <Box className="belowCardContent">
                  <Typography className="selectionText">
                    {t('Select_the')}
                  </Typography>
                  <Box className="circleContainer">
                    {[30, 60, 90].map((days) => (
                      <Box
                        key={days}
                        className="circleSelector"
                        onClick={() => editWellCheckDuration(days)}
                      >
                        <div
                          className={`circle ${days === selectedDay ? 'active' : ''}`}
                          style={{ position: 'relative' }}
                        >
                          {days === selectedDay && (
                            <img
                              src={greentick}
                              alt="Selected"
                              style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-5px',
                                width: '20px',
                                height: '20px',
                                zIndex: 1,
                              }}
                            />
                          )}
                          <Typography>
                            {t(`${days}_days`)} <br />
                            <span
                              style={{
                                fontSize: '10px',
                                marginTop: '-10px !important',
                              }}
                            >
                              {t('days')}
                            </span>
                          </Typography>
                        </div>
                      </Box>
                    ))}
                  </Box>
                  <Typography className={`${errorMessage ? 'error' : 'pass'}`}>
                    {errorMessage ? errorMessage : successMessage}
                  </Typography>
                </Box>
              ) : (
                <Box className="belowCardContent">
                  <Typography className="upgradeText">
                    {t('Default_duration')}
                  </Typography>
                  <Typography className="upgradeText">
                    {t('Selection_of')}
                  </Typography>
                  <Link
                    href="#"
                    className="upgradeLink"
                    onClick={(e) => {
                      e.preventDefault();
                      handleUpgradeNow();
                    }}
                  >
                    {t('Upgrade_now')}
                  </Link>
                </Box>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <Box
                className="WellnessCard"
                marginTop={isPaidPlan ? '40px' : '0px'}
              >
                <div className="cardTopChip active">
                  <p>{t('Active')}</p>
                </div>
                <Box className="iconBox">
                  <img
                    className="wellnessCardIcon"
                    src={verified}
                    alt="Verified"
                  />
                </Box>
                <Typography className="cardTitle">
                  {t('Verification_Check')}
                </Typography>
                <Typography className="cardText">{t('If_you')}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <SubscriptionComponent />
    </DashboardWraper>
  );
};

export default DashboardWellness;
