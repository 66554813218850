import React, { useEffect, useRef, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import EmailIcon from '../../assets/images/email-logo.svg';
import { useTranslation } from 'react-i18next';
import { validateEmail, validateName } from '../../utils/commonFunctions';
import { Typography, Box, Link } from '@mui/material';
import SocialLogin from '../../components/common/SocialLogin';
import CustomButton from '../../components/common/CustomButton';
import FormField from '../../components/common/FormField';
import LogoComponent from '../../components/common/LogoComponent';
import Auth from './Auth';
import AuthService from './auth.service';
import { appSliceActions } from '../../redux-store/reducers/appReducer';
import { useDispatch } from 'react-redux';
import { useHttpClient } from '../../hooks/usecorrelation';
import ErrorMessageInfo from '../../components/common/ErrorMsgInfo';
import { ISignInReqBody } from './auth.type';
import { useNavigate } from 'react-router-dom';
import { partners, showSingUpFlowFlag } from '../../utils/constants';
import './auth.css';
import PersonIcon from '../../assets/images/name-logo.svg';
import PrivacyPolicy from '../../components/common/termsAndPrivady/privacy';
import TermsAndConditions from '../../components/common/termsAndPrivady/terms';
import { authSliceActions } from '../../redux-store/reducers/authReducer';
interface IFormData {
  email: string;
  username?: string;
}

const SignUpForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const httpClient = useHttpClient();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const signUpStartTime = useRef<string>('');

  useEffect(() => {
    signUpStartTime.current = new Date().toISOString();
  }, []);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    setValue,
  } = useForm<IFormData>({
    defaultValues: {
      username: '',
      email: '',
    },
    mode: 'onChange',
  });

  // Update onSubmit to use SubmitHandler<FormData>
  const onSubmit: SubmitHandler<IFormData> = async (data) => {
    dispatch(appSliceActions.setIsLoader(true));
    const sinInRequestBody: ISignInReqBody = {
      name: data.username,
      email: data.email,
      redirectLink: `${process.env.REACT_APP_BASE_URL}/setNewPassword`,
      userPartnerName: partners.smartHertance.partnerName,
      partnerCode: partners.smartHertance.partnerCode,
    };
    //set Loader true
    const signUpFlowFlag = await AuthService.postSignUpDetails(
      httpClient,
      sinInRequestBody
    );
    switch (signUpFlowFlag) {
      case showSingUpFlowFlag['Represents the new registration state.']:
        setErrorMessage('');
        navigate('/personalize');
        break;
      case showSingUpFlowFlag[
        'Represents the state where the email is not verified.'
      ]:
        setError('email', {
          type: 'manual',
          message: `${t('loginErrorMessage')}`,
        });
        setErrorMessage(t('signUpErrorMessage'));
        break;
      case showSingUpFlowFlag['Represents the state where no password is set.']:
        setErrorMessage('');
        navigate('/setNewPassword');
        break;
      case showSingUpFlowFlag['Represents the state where a password is set.']:
        setErrorMessage('');
        dispatch(
          authSliceActions.setLoginErrorMessage(
            'This email is alreday verified please enter password and login.'
          )
        );
        navigate('/login');
        break;
      // Add more cases as needed
      default:
        setErrorMessage(t('unknownErrorMessage'));
        break;
    }
    dispatch(appSliceActions.setIsLoader(false));
  };

  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const [isTermsAndConditionOpen, setisTermsAndConditionOpen] = useState(false);

  const handlePrivacyPolicyOpen = () => {
    setIsPrivacyPolicyOpen(true);
  };
  const handleTermsAndConditionOpen = () => {
    setisTermsAndConditionOpen(true);
  };

  const handlePrivacyPolicyClose = () => {
    setIsPrivacyPolicyOpen(false);
  };
  const handleTermsAndConditionClose = () => {
    setisTermsAndConditionOpen(false);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.replace(/^\s+/, ''); // Trim only leading whitespace
    setValue(event.target.name as any, trimmedValue);
  };
  return (
    <Auth>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        className={'box-container'}
      >
        <LogoComponent isCommonClass={true} />

        <Typography className="welcomeText" variant="h4">
          {t('welcome')}
        </Typography>
        <Typography className="getStarted" variant="body1">
          {t('getStarted')}
        </Typography>

        <FormField
          name="username"
          control={control}
          label={t('label.name')}
          placeholder={t('placeholder.namePlaceholder')}
          error={errors.username?.message}
          icon={PersonIcon}
          rules={{
            required: t('nameRequired'),
            validate: (username) => validateName(username, t),
            maxLength: {
              value: 50,
              message: t('maxLengthExceeded', { max: 50 }),
            },
          }}
          type="text"
          apiValidation={!!errorMessage}
          handlePhoneNumberChange={handleInputChange}
        />

        <FormField
          name="email"
          control={control}
          label={t('label.email')}
          placeholder={t('placeholder.emailPlaceholder')}
          error={errors.email?.message}
          icon={EmailIcon}
          rules={{
            required: t('emailRequired'),
            validate: (email) => validateEmail(email, t),
            maxLength: {
              value: 50,
              message: t('maxLengthExceeded', { max: 50 }),
            },
          }}
          type="text"
          apiValidation={!!errorMessage}
          handlePhoneNumberChange={handleInputChange}
        />

        <CustomButton text={t('button.continueWithEmail')} style={{ mt: 2 }} />
        {errorMessage && (
          <ErrorMessageInfo
            errorMessage={errorMessage}
            style={{ paddingTop: '10px' }}
          ></ErrorMessageInfo>
        )}
      </Box>

      <SocialLogin></SocialLogin>

      <Typography
        className="termsAndConditionsText"
        variant="body2"
        align="center"
        // color="black"
        // sx={{ pt: 3 }}
        // fontSize="14px"
      >
        {t('termsAndConditionsText')}{' '}
        <Link
          className="privacyPolicy"
          href="#"
          underline="none"
          color="primary"
          fontWeight="700"
          onClick={handleTermsAndConditionOpen}
        >
          {t('termsAndConditions')}
        </Link>
        <TermsAndConditions
          isOpen={isTermsAndConditionOpen}
          onClose={handleTermsAndConditionClose}
        ></TermsAndConditions>{' '}
        {t('and')}
        <Typography
          variant="body2"
          align="center"
          color="black"
          fontSize="14px"
        >
          <Link
            className="privacyPolicy"
            href="#"
            underline="none"
            color="primary"
            fontWeight="700"
            onClick={handlePrivacyPolicyOpen}
          >
            {t('privacyPolicy')}
          </Link>

          {/* Privacy Policy Dialog */}
          <PrivacyPolicy
            isOpen={isPrivacyPolicyOpen}
            onClose={handlePrivacyPolicyClose}
          />
        </Typography>
      </Typography>
    </Auth>
  );
};

export default SignUpForm;
