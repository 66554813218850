import store from '../../redux-store/store';
import { assetsSliceActions } from '../../redux-store/reducers/assetsreducer';
import i18n from '../../i18n';
import { IAssets } from '../../pages/onboarding/onboarding.type';
import OnboardingService from '../../pages/onboarding/onboarding.service';
export const handleOnSubmit = async (
  res: any,
  setMessage: any,
  resetForm: any,
  setErrMsg: any,
  handleAddEditDelete: any
) => {
  const editAsset = store.getState().assets.editAsset;
  const selectedAssets = store.getState().assets.assetListbasedOnSelectType;
  const assetBeneficiary = store.getState().assets.assetsBeneficiaryList;
  const newRes = { ...res, beneficiary: assetBeneficiary };
  if (editAsset) {
    const updatedAssetDetails = [...selectedAssets.assetDetails];
    updatedAssetDetails[editAsset.id] = newRes;
    const updatedSelectedAssets = {
      ...selectedAssets,
      assetDetails: updatedAssetDetails,
    };
    await handleAddEditDelete(updatedSelectedAssets);
    setMessage(i18n.t('bankAccountInformationUpdated'));
    store.dispatch(assetsSliceActions.setEditAssets(null));
  } else {
    // Add new asset
    const updatedAssets = {
      ...selectedAssets,
      assetDetails: selectedAssets.assetDetails
        ? [...selectedAssets.assetDetails, newRes]
        : [newRes],
    };
    await handleAddEditDelete(updatedAssets);
  }
  resetForm();
};

export const handleEditDelete = async (
  httpClient: any,
  assetToBeSaved: IAssets
) => {
  const responseData = await OnboardingService.editAssetsData(
    httpClient,
    assetToBeSaved
  );
  if (responseData && typeof responseData !== 'boolean') {
    const updatedUserAssets = store
      .getState()
      .assets.userAssets.map((userAsset: IAssets) =>
        userAsset.userAssetId === assetToBeSaved.userAssetId
          ? responseData
          : userAsset
      );

    store.dispatch(assetsSliceActions.setUserAssets(updatedUserAssets));

    store.dispatch(
      assetsSliceActions.setAssetsListBasedOnSelectType(responseData)
    );
  }
};
export const handleNumberInputChange = (event: any, setValue: any) => {
  const inputValue = event.target.value.replace(/\D/g, '');
  setValue(event.target.name as any, inputValue);
};
export const handleAlphaNumericNumber = (event: any, setValue: any) => {
  const inputValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
  setValue(event.target.name as any, inputValue);
};
