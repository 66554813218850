import axios from 'axios';
import store from '../redux-store/store';

export const getToken: any = async () => {
  try {
    const url = process.env.REACT_APP_TOKEN_URL!;
    const clientId = 's3e7s88k222id1qbi7onqt0if';
    // const clientSecret = '1q8ecvnjt26vimdpfi6m0ebioqj3pvr35rev15jeietgrls9mfej';
    const credentials = `${clientId}:`;
    const encodedCredentials = btoa(credentials); // Encode credentials in Base64
    // Request parameters
    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials'); // For client credentials flow
    params.append('client_id', clientId);
    // Make the request to the auth server
    const response = await axios.get(url);
    return response.data;
    // Store the access token or handle it as needed
    // localStorage.setItem('accessToken', access_token);
  } catch (error) {
    console.error('Error fetching token:', error);
  }
};

export const getJwtToken: any = async () => {
  const storeData = store.getState();
  const token: string | undefined = storeData.auth.loginData
    ? storeData.auth.loginData.jwtToken
    : '';
  return token;
};
