import React from 'react';
import { Box, Typography } from '@mui/material';
import './common.css';

import { ReactComponent as SentIcon } from '../../assets/images/sent_icon.svg';
type IProps = {
  successMsg: string | null;
  style?: any;
};
const SuccessMsgInfo: React.FC<IProps> = ({ successMsg, style }) => {
  return (
    <>
      <Box
        sx={{
          ...style,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {successMsg && (
          <>
            <SentIcon className="sent-icon" />
            <Typography
              className="successMsgText"
              sx={{
                fontWeight: '500',
                color: '#00C851',
              }}
            >
              {successMsg}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

export default SuccessMsgInfo;
