import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './errorboundary.css';
const ErrorHandler: React.FC<any> = ({ error }) => {
  const { t } = useTranslation();
  //   useEffect(() => {
  //     ErrorHandlingService.postErrorMessage(error.message, client);
  //   }, []);
  return (
    <>
      <div role="alert">
        <div className="maindiv">
          <h1>{t('label.somethingWrong')}</h1>
          <p>{t('label.tryAgain')}</p>
        </div>
      </div>
    </>
  );
};

export default ErrorHandler;
