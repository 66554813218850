import React, { useEffect } from 'react';

import { Box, Grid, Link, Typography } from '@mui/material';
import i18n from '../../i18n';
import './wellnessCheck.css';
import automatedIcon from '../../assets/images/automated.png';
import personalizedIcon from '../../assets/images/personalized.png';
import verificationIcon from '../../assets/images/verification.png';
import { useDispatch } from 'react-redux';
import { beneficiarySliceActions } from '../../redux-store/reducers/beneficiaryReducer';

const WellnessCheck = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      beneficiarySliceActions.setOnboardingScreenTime(new Date().toISOString())
    );
  }, []);
  const boxConatent = [
    {
      boxHeaderText: i18n.t('boxHeaderTextStage1'),
      boxBottomText: i18n.t('boxBootomTextStage1'),
      icon: automatedIcon,
    },
    {
      boxHeaderText: i18n.t('boxHeaderTextStage2'),
      boxBottomText: i18n.t('boxBootomTextStage2'),
      icon: personalizedIcon,
    },
    {
      boxHeaderText: i18n.t('boxHeaderTextStage3'),
      boxBottomText: i18n.t('boxBootomTextStage3'),
      icon: verificationIcon,
    },
  ];
  return (
    <Grid container className={'wellnessCheckMainContainer'}>
      <Grid xs={12} sm={12} md={12} lg={9} item className="boxMainContainer">
        <Typography className="headerTextOne headerPadding headerFont">
          {i18n.t('wellNessCheckHeaderOne')}
        </Typography>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={9} item className="boxMainContainer">
        <Typography className="headerTextOne headerTextTwo headerFont">
          {i18n.t('wellNessCheckHeaderTwo')}
        </Typography>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={9} item className="boxMainContainer">
        <Typography className=" headerTextThree headerFont">
          {i18n.t('wellNessCheckHeaderThree')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={9}>
        <Grid
          container
          className="boxMainContainer"
          rowSpacing={3}
          columnSpacing={5}
        >
          {boxConatent.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              key={index}
              container
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <Box className="boxConatiner">
                <Box className="iconContainer">
                  <img src={item.icon} alt="Icon" className="iconImage" />
                </Box>
                <Typography className="boxHeaderText boxHeaderFont">
                  {item.boxHeaderText}
                </Typography>
                <Typography className="headerTextOne boxTextFont">
                  {item.boxBottomText}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={9} item className="boxMainContainer">
        <br></br>
        <Typography className="bottomTextOne headerFont">
          {i18n.t('bottomTextOne')}
        </Typography>
      </Grid>
      {/* <Grid xs={12} sm={12} md={12} lg={9} item className="boxMainContainer">
        <Typography className="bottomTextOne headerTextTwo headerFont">
          {i18n.t('bottomTextTwo')}
        </Typography>
      </Grid> */}
      <Grid xs={12} sm={12} md={12} lg={9} item>
        <Box className="viewSimulationBox">
          <Link href="#" className="viewSimulation headerFont">
            {i18n.t('viewSimulation')}
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

export default WellnessCheck;
