import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import DeletePng from '../../assets/images/icons/delete.png';
import '../../components/add-assets/addAssets.css';
import './DocumentList.css'; // Import the new CSS file
import { useDispatch, useSelector } from 'react-redux';
import { formatFileSize, getFileIcon } from '../../utils/commonFunctions';
import ConfirmPopUp from '../common/popup/ConfirmPopUp';
import { useTranslation } from 'react-i18next';
import DocumentService from './document.service';
import { useHttpClient } from '../../hooks/usecorrelation';
import { IUserData } from '../../pages/auth/auth.type';
import { documentUploadSliceActions } from '../../redux-store/reducers/documentUploadReducer';
import { appSliceActions } from '../../redux-store/reducers/appReducer';

const DocumentList = () => {
  const [isDialog, setIsDialog] = useState(false);
  const httpClient = useHttpClient();
  const uploadDocumentList = useSelector(
    (state: any) => state.documentUpload.uploadDocumentList
  );
  const [fileName, setFileName] = useState('');
  const { t } = useTranslation();
  const userData: IUserData = useSelector((state: any) => state.auth.loginData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appSliceActions.setIsLoader(true));
    DocumentService.getDocumentData(httpClient, userData.userId)
      .then((response) => {
        dispatch(documentUploadSliceActions.setDocumentUploadList(response));
      })
      .catch((error) => {
        dispatch(documentUploadSliceActions.setDocumentUploadList([]));
        console.error('Error fetching document list:', error);
      })
      .finally(() => {
        dispatch(appSliceActions.setIsLoader(false));
      });
  }, []);

  const handleDeleteClick = (fileName: string) => {
    setIsDialog(!isDialog);
    setFileName(fileName);
  };

  const handleCancelDelete = () => {
    setIsDialog(false);
    setFileName('');
  };

  const handleConfirmDelete = async () => {
    dispatch(appSliceActions.setIsLoader(true));
    const response = await DocumentService.deleteDocumentData(
      httpClient,
      userData.userId,
      fileName
    );
    if (response === 200) {
      const filterDocument = uploadDocumentList.filter(
        (item: any) => item.name !== fileName
      );
      dispatch(
        documentUploadSliceActions.setDocumentUploadList(filterDocument)
      );
      setIsDialog(false);
    }
    dispatch(appSliceActions.setIsLoader(false));
  };

  return (
    <>
      <Box className="listContainer">
        <Box className="custom-scrollbarrr innerBox">
          <Typography className="headerText docListHeader">
            {t('myDocument')}
          </Typography>
          {uploadDocumentList &&
            uploadDocumentList.length > 0 &&
            uploadDocumentList.map((doc: any, index: number) => (
              <Paper
                key={index}
                className="documentItem"
                sx={{
                  boxShadow: 'none',
                }}
              >
                <Box className="documentInfo">
                  <Avatar className="documentIcon">
                    <img
                      src={getFileIcon(doc.type)}
                      alt={doc.format}
                      className="documentImage"
                    />
                  </Avatar>
                  <Box ml="5px">
                    <Typography variant="body1" className="documentName">
                      {doc.name}
                    </Typography>
                    <Typography variant="body2" className="documentSize">
                      {formatFileSize(doc.size)}
                    </Typography>
                  </Box>
                </Box>
                <Tooltip title="Delete" arrow>
                  <IconButton
                    className="deleteButton"
                    onClick={() => handleDeleteClick(doc.name)}
                  >
                    <img className="deleteIcon" src={DeletePng} alt="delete" />
                  </IconButton>
                </Tooltip>
              </Paper>
            ))}
        </Box>
      </Box>
      <ConfirmPopUp
        isOpenDialog={isDialog}
        confirmTitle={t('areYouSure')}
        onConfirmationClick={handleConfirmDelete}
        onDialogClose={handleCancelDelete}
        subTitle1={t('deleteAssetSub1')}
        subTitle2={t('deleteBeneficiarySub2')}
      />
    </>
  );
};

export default DocumentList;
