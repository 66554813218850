import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as InfoIcon } from '../../assets/images/info_icon.svg';
import './common.css';
type IProps = {
  errorMessage: string | null;
  style?: any;
};
const ErrorMessageInfo: React.FC<IProps> = ({ errorMessage, style }) => {
  return (
    <Box
      sx={{
        ...style,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {errorMessage && (
        <>
          <InfoIcon className="info-icon sent-icon " />
          <Typography
            className="successMsgText"
            sx={{
              fontWeight: '600',
              color: '#FF4444',
            }}
          >
            {errorMessage}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default ErrorMessageInfo;
