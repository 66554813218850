import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Drawer,
  Box,
  Divider,
  IconButton,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { subscriptionSliceActions } from '../../redux-store/reducers/subscriptionReducer';
import SubscriptionComponent from '../subscription/SubscriptionComponent';

// Import all menu item icons
import menuItem1 from '../../assets/images/dashboardIcons/menuItem1.png';
import menuItem2 from '../../assets/images/dashboardIcons/menuItem2.png';
import menuItem3 from '../../assets/images/dashboardIcons/menuItem3.png';
import menuItem4 from '../../assets/images/dashboardIcons/menuItem4.png';
import menuItem5 from '../../assets/images/dashboardIcons/menuItem5.png';
import menuItem6 from '../../assets/images/dashboardIcons/menuItem6.png';
import menuItem7 from '../../assets/images/dashboardIcons/menuItem7.png';

// Import social media icons
import fbIcon from '../../assets/images/fb.png';
import linkdin from '../../assets/images/linkdin.png';
import xIcon from '../../assets/images/ximg.png';
import notificationIcon from '../../assets/images/yellowArrow.png';

// Import components
import DashboardHeader from '../../components/common/header/DashboardHeader';
import DashboardWelcome from '../../components/dashboard/DashboardWelcome';
import DashboardAddEditBeneficiary from '../../components/dashboard/DashboardAddEditBeneficiary';
import DashboardAddEditDocument from '../../components/add-edit-documents/DashboardAddEditDocument';
import DashboardAddEditAssets from '../../components/dashboard/DashboardAddEditAssets';
import DashboardProfessionalContect from '../../components/dashboard/DashboardProfessionalContect';
import DashboardWellness from './DashboardWellness';
import SupportDialog from './SupportDialog';

import './dashboard.css';

// Theme configuration
const theme = createTheme({
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#112F8E',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
        },
      },
    },
  },
});

// Menu items configuration
const menuItems = [
  {
    text: 'Dashboard',
    icon: menuItem1,
    component: DashboardWelcome,
    requiresSubscription: false,
  },
  {
    text: 'Beneficiaries',
    icon: menuItem2,
    component: DashboardAddEditBeneficiary,
    requiresSubscription: false,
  },
  {
    text: 'Assets',
    icon: menuItem3,
    component: DashboardAddEditAssets,
    requiresSubscription: false,
  },
  {
    text: 'Professional Contact',
    icon: menuItem4,
    component: DashboardProfessionalContect,
    requiresSubscription: false,
  },
  {
    text: 'Documents',
    icon: menuItem5,
    component: DashboardAddEditDocument,
    requiresSubscription: false,
  },
  {
    text: 'Wellness Check',
    icon: menuItem6,
    component: DashboardWellness,
    requiresSubscription: true,
  },
];

interface RootState {
  subscription: {
    subscriptionOption: any;
    isWellnessCheckDialogOpen: boolean;
  };
}

const DashboardComponent: React.FC = () => {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSupportOpen, setIsSupportOpen] = useState<boolean>(false);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const subscriptionOption = useSelector(
    (state: RootState) => state.subscription.subscriptionOption
  );

  useEffect(() => {
    if (!subscriptionOption) {
      dispatch(subscriptionSliceActions.setIsSubscriptionDialogOpen(true));
    }
  }, [dispatch, subscriptionOption]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuClick = async (index: number) => {
    setIsLoading(true);
    setSelectedIndex(index);

    if (menuItems[index].requiresSubscription) {
      dispatch(subscriptionSliceActions.setIsWellnessCheckDialogOpen(true));
    }

    if (isSmallScreen) {
      handleDrawerToggle();
    }

    setIsLoading(false);
  };

  const toggleMenu = () => {
    setIsMenuCollapsed(!isMenuCollapsed);
  };

  const handleSupportClick = () => {
    setIsSupportOpen(true);
  };

  const handleSupportClose = () => {
    setIsSupportOpen(false);
  };

  const renderMenu = () => (
    <List sx={{ marginTop: '64px' }}>
      {menuItems.map((item, index) => (
        <ListItem
          button
          key={item.text}
          selected={selectedIndex === index}
          onClick={() => handleMenuClick(index)}
          className={`menuItem ${selectedIndex === index ? 'selected' : ''}`}
        >
          <ListItemIcon
            className="menuItemIcon"
            sx={{ marginLeft: isMenuCollapsed ? '40px' : '20px' }}
          >
            <img
              src={item.icon}
              alt={item.text}
              style={{ width: '24px', height: '24px', position: 'absolute' }}
            />
          </ListItemIcon>
          {!isMenuCollapsed && (
            <ListItemText primary={item.text} className="menuItemText" />
          )}
        </ListItem>
      ))}
    </List>
  );

  const renderSocialBox = () => (
    <Box className={`socialBox ${isMenuCollapsed ? 'collapsed' : 'expanded'}`}>
      {!isMenuCollapsed && (
        <>
          <Typography className="socialBoxText" textAlign="center">
            We are on a mission to ensure a smooth transfer of personal wealth
            to the next generation.
          </Typography>
          <Typography className="socialBoxText" textAlign="center">
            Help our cause by spreading the word.
          </Typography>
          <Typography className="socialBoxText" textAlign="center">
            Thank you for being part of our mission to eliminate unclaimed
            property.
          </Typography>
        </>
      )}
      <Box
        className={`socialIcons ${isMenuCollapsed ? 'collapsed' : 'expanded'}`}
      >
        <img src={fbIcon} alt="fbIcon" className="socialIcon" />
        <Divider
          className="muiDividerrVertical"
          orientation={isMenuCollapsed ? 'horizontal' : 'vertical'}
          variant="middle"
          flexItem
        />
        <img src={linkdin} alt="linkdin" className="socialIcon" />
        <Divider
          className="muiDividerrVertical"
          orientation={isMenuCollapsed ? 'horizontal' : 'vertical'}
          variant="middle"
          flexItem
        />
        <img src={xIcon} alt="xIcon" className="socialIcon" />
      </Box>
    </Box>
  );

  const renderSupportButton = () => (
    <List>
      <ListItem
        button
        onClick={handleSupportClick}
        className="menuItem"
        sx={{ marginTop: '20px' }}
      >
        <ListItemIcon
          className="menuItemIcon"
          sx={{ marginLeft: isMenuCollapsed ? '40px' : '20px' }}
        >
          <img
            src={menuItem7}
            alt="Support"
            style={{ width: '24px', height: '24px', position: 'absolute' }}
          />
        </ListItemIcon>
        {!isMenuCollapsed && (
          <ListItemText className="menuItemText" primary="SUPPORT" />
        )}
      </ListItem>
    </List>
  );

  const SelectedComponent = menuItems[selectedIndex].component;

  return (
    <ThemeProvider theme={theme}>
      <div style={{ flexGrow: 1, overflowY: 'hidden' }}>
        <DashboardHeader />
        <Grid container style={{ height: '100vh', position: 'relative' }}>
          {!isSmallScreen && (
            <Grid
              item
              xs={isMenuCollapsed ? 0.5 : 2}
              lg={isMenuCollapsed ? 1 : 2.2}
              style={{
                backgroundColor: '#23419F',
                transition: 'all 0.3s ease-in-out',
                overflow: 'hidden',
              }}
            >
              {renderMenu()}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {renderSocialBox()}
              </div>
              {renderSupportButton()}
            </Grid>
          )}

          <Grid
            item
            xs={isSmallScreen ? 12 : isMenuCollapsed ? 11 : 9.8}
            className="mainContent"
          >
            {isLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Typography>Loading...</Typography>
              </Box>
            ) : (
              <SelectedComponent onNavigate={handleMenuClick} />
            )}
          </Grid>

          {!isSmallScreen && (
            <div
              className="toggleMenuButton"
              style={{
                left: isMenuCollapsed
                  ? 'calc(8.33% - 15px)'
                  : 'calc(18.33% - 15px)',
                top: '13%',
              }}
            >
              <IconButton onClick={toggleMenu}>
                <img
                  src={notificationIcon}
                  alt="Toggle Menu"
                  className={`toggleMenuIcon ${isMenuCollapsed ? 'collapsed' : ''}`}
                />
              </IconButton>
            </div>
          )}
        </Grid>

        {isSmallScreen && (
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: { backgroundColor: '#23419F', width: 350 },
            }}
          >
            {renderMenu()}
            {renderSupportButton()}
          </Drawer>
        )}

        <SupportDialog open={isSupportOpen} onClose={handleSupportClose} />
      </div>
      <SubscriptionComponent />
    </ThemeProvider>
  );
};

export default DashboardComponent;
