import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
  Paper,
  TextField,
  Button,
  styled,
  Slide,
  Grid,
  InputAdornment,
  keyframes,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CustomButton from '../CustomButton';
import LogoutIcon from '../../../assets/images/logouticon.png';
import { Padding } from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import { IUserData } from '../../../pages/auth/auth.type';
import store from '../../../redux-store/store';
import { appSliceActions } from '../../../redux-store/reducers/appReducer';
import { authSliceActions } from '../../../redux-store/reducers/authReducer';
import AuthService from '../../../pages/auth/auth.service';
import { useHttpClient } from '../../../hooks/usecorrelation';
import i18n from '../../../i18n';
import FormField from '../FormField';
import phoneIcon from '../../../assets/images/phone-icon.svg';
import {
  formatPhoneNumber,
  formatPhoneNumberForSubmission,
  validatePassword,
} from '../../../utils/commonFunctions';
import SuccessMsgInfo from '../SuccessMsgInfo';
import ErrorMessageInfo from '../ErrorMsgInfo';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import EyeIcon from '../../../assets/images/eye-icon.svg';
import LogoutPopUp from '../popup/LogoutPopUp';
// Types remain the same
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedErrorMessage = styled(Typography)`
  animation: ${fadeInUp} 0.3s ease-out;
`;
interface AccountSettingsDialogProps {
  open: boolean;
  onClose: () => void;
  onLogOut: () => void;
}

interface FormData {
  email: string;
  phone: string;
  password: string;
  newPassword: string;
  confirmPassword: string;
}
interface IFormInput {
  password: string;
  confirmPassword: string;
}
const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    width: '760px',
    height: '100vh',
    maxHeight: '100vh',
    margin: 0,
    position: 'fixed',
    right: 0,
    borderRadius: '12px 0 0 12px',
    overflowY: 'auto',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const AccountCard = styled(Paper)(() => ({
  padding: '20px',
  marginBottom: '24px',
  boxShadow: '4px 4px 0px 0px rgba(0, 0, 0, 0.10)',
  borderRadius: '20px',
  border: '1px solid #D0D5DD',
  borderLeft: '5px solid #F4CA6C',
  position: 'relative',
  '& .content-wrapper': {
    marginBottom: '60px',
  },
}));

const ButtonContainer = styled(Box)(() => ({
  position: 'absolute',
  bottom: '10px',
  width: '350px',
  right: '20px',
  display: 'flex',
  gap: '8px',
  marginTop: '16px',
}));

const ProfileCircle = styled(Box)(() => ({
  width: '64px',
  height: '64px',
  borderRadius: '50%',
  backgroundColor: '#F5F5F5',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  color: '#666',
  fontSize: '20px',
}));

const EditButton = styled(IconButton)(() => ({
  position: 'absolute',
  bottom: -4,
  right: -4,
  backgroundColor: '#E0E0E0',
  padding: '4px',
  '&:hover': {
    backgroundColor: '#D0D0D0',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '12px',
  },
}));

// Transition remains the same
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AccountSettingsDialog: React.FC<AccountSettingsDialogProps> = ({
  open,
  onClose,
  onLogOut,
}) => {
  const userData: IUserData = useSelector((state: any) => state.auth.loginData);
  const { t } = useTranslation();
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [tempPhone, setTempPhone] = useState(formatPhoneNumber(userData.phone));
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [passwordMsg, setPasswordMsg] = useState('');
  const [passErrMsg, setPassErrMsg] = useState('');
  const httpClient = useHttpClient();
  const [isLogoutDialog, setIsDialogPopup] = useState(false);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm<IFormInput>({
    defaultValues: {
      confirmPassword: '',
      password: '',
    },
    mode: 'onChange',
  });
  // Handlers
  useEffect(() => {
    if (passErrMsg || passwordMsg) {
      const timer = setTimeout(() => {
        setPassErrMsg('');
        setPasswordMsg('');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [passErrMsg, passwordMsg]);
  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value.replace(/\D/g, '');
    let formattedValue = formatPhoneNumber(inputValue);
    setTempPhone(formattedValue);
  };
  const handleSavePhone = async () => {
    let reqBody = { phoneNumber: formatPhoneNumberForSubmission(tempPhone) };
    const response = await AuthService.updateUserProfile(
      httpClient,
      reqBody,
      userData.userId
    );
    if (response === 'success') {
      setMessage('Phone number updated successfully');
      setError('');
    } else {
      setError(response);
      setMessage('');
    }
    setIsEditingPhone(false);
  };

  const handleCancelPhone = () => {
    setTempPhone(userData.phone);
    setIsEditingPhone(false);
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    dispatch(appSliceActions.setIsLoader(true));
    let reqBody = {
      userId: userData.userId,
      newPassword: data.password,
    };
    const responseData = await AuthService.postResetPassword(
      httpClient,
      reqBody
    );
    if (responseData?.message === 'Password updated successfully') {
      setPasswordMsg('Password updated successfully');
      setPassErrMsg('');
    } else {
      setPassErrMsg(responseData ? responseData?.message : '');
      setPasswordMsg('');
    }
    dispatch(appSliceActions.setIsLoader(false));
  };

  const handleCancelPassword = () => {
    reset();
    setIsChangingPassword(false);
  };

  const handleLogout = async () => {
    store.dispatch(appSliceActions.setIsLoader(true));
    let res = await AuthService.logout(httpClient);
    if (res) {
      store.dispatch(
        authSliceActions.setUserData({
          phone: '',
          email: '',
          userId: 0,
          userEmail: '',
          userName: '',
          jwtToken: '',
        })
      );
      window.location.href = '/login';
    }
    store.dispatch(appSliceActions.setIsLoader(false));
  };
  const validateConfirmPassword = (value: string) => {
    const password = getValues('password');
    if (value !== password) {
      return t('passwordMismatch');
    }
    return true;
  };
  const handleLogoutPopUp = () => {
    setIsDialogPopup(!isLogoutDialog);
    onClose();
  };
  const handleCancelLogoutPopUp = () => {
    setIsDialogPopup(false);
  };
  return (
    <>
      <StyledDialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        fullWidth
        maxWidth={false}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 24px',
            backgroundColor: '#F0F6F9',
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Accounts & Settings
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ padding: '24px', backgroundColor: '#F0F6F9' }}>
          {/* Account Details Card */}
          <AccountCard>
            <div className="content-wrapper">
              <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                Account Details
              </Typography>
              <Box display="flex" gap={3}>
                <Box position="relative">
                  <ProfileCircle>
                    JD
                    <EditButton size="small">
                      <EditIcon />
                    </EditButton>
                  </ProfileCircle>
                </Box>
                <Box flex={1}>
                  <Box mb={2}>
                    <Typography>{userData.userEmail}</Typography>
                    {/* <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={formData.email}
                    disabled
                    size="small"
                  /> */}
                  </Box>
                  <Box className="FormFieldOuterBox">
                    <TextField
                      className="FormField custom-textfield"
                      autoComplete="off"
                      fullWidth
                      label={
                        <span
                          className="formfieldLabel"
                          style={{ color: '#252B42' }}
                        >
                          {'Phone Number'}
                        </span>
                      }
                      value={tempPhone}
                      type="text"
                      // error={!!error}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              className="FormFieldIcon"
                              src={phoneIcon}
                              alt="icon"
                            />
                          </InputAdornment>
                        ),
                        inputProps: {
                          maxLength: 10,
                        },
                      }}
                      placeholder={'Phone Number'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handlePhoneNumberChange(e)
                      }
                      onClick={() => !isEditingPhone && setIsEditingPhone(true)}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: !error ? '#D0D5DD' : '#F44',
                          },
                          '&:hover fieldset': {
                            borderColor: !error ? '#0047FF' : '#F44',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: !error ? '#0047FF' : '#F44',
                          },
                        },
                      }}
                    />
                    <Box className="ErrorBox">
                      {error && (
                        <AnimatedErrorMessage
                          variant="caption"
                          color="error"
                          className="animated-error-message"
                        >
                          {error}
                        </AnimatedErrorMessage>
                      )}
                      {message && (
                        <SuccessMsgInfo successMsg={message}></SuccessMsgInfo>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </div>
            {isEditingPhone && (
              <ButtonContainer>
                <CustomButton
                  // buttonfullWidth={true}
                  text={i18n.t('button.cancel')}
                  buttonType="secondary"
                  handleClick={() => handleCancelPhone && handleCancelPhone()}
                />
                <CustomButton
                  // buttonfullWidth={true}
                  text={i18n.t('button.save')}
                  handleClick={() => handleSavePhone && handleSavePhone()}
                />
              </ButtonContainer>
            )}
          </AccountCard>

          {/* Password Card */}
          <AccountCard>
            <div className="content-wrapper">
              <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                Password Setting
              </Typography>
              <Grid display="flex">
                <Grid item xs={12} sm={6} md={isChangingPassword ? 3 : 8}>
                  <div
                    style={{
                      width: '100%',
                    }}
                  ></div>
                </Grid>
                <Grid item xs={12} sm={6} md={isChangingPassword ? 9 : 6}>
                  <Box display="flex" flexDirection="column" gap={2}>
                    {!isChangingPassword && (
                      <CustomButton
                        buttonType="secondary"
                        buttonfullWidth={false}
                        text={'Change Password'}
                        handleClick={() => setIsChangingPassword(true)}
                      ></CustomButton>
                    )}
                    {isChangingPassword && (
                      <>
                        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                          <FormField
                            name="password"
                            control={control}
                            label={t('label.newPassword')}
                            placeholder={t('placeholder.passwordPlaceholder')}
                            type="password"
                            error={errors.password?.message}
                            icon={EyeIcon}
                            rules={{
                              validate: (password) =>
                                validatePassword(password, t),
                              maxLength: {
                                value: 50,
                                message: t('maxLengthExceeded', { max: 50 }),
                              },
                            }}
                            showRequired={true}
                          />

                          <FormField
                            name="confirmPassword"
                            control={control}
                            label={t('label.confirmPassword')}
                            placeholder={t('placeholder.confirmPassword')}
                            type="password"
                            error={errors.confirmPassword?.message}
                            icon={EyeIcon}
                            rules={{
                              validate: validateConfirmPassword,
                              maxLength: {
                                value: 50,
                                message: t('maxLengthExceeded', { max: 50 }),
                              },
                            }}
                            showRequired={true}
                          />
                          {passErrMsg && (
                            <ErrorMessageInfo
                              errorMessage={passErrMsg}
                            ></ErrorMessageInfo>
                          )}
                          {passwordMsg && (
                            <SuccessMsgInfo
                              successMsg={passwordMsg}
                            ></SuccessMsgInfo>
                          )}
                          <ButtonContainer>
                            <CustomButton
                              // buttonfullWidth={true}
                              text={i18n.t('button.cancel')}
                              buttonType="secondary"
                              handleClick={() =>
                                handleCancelPassword && handleCancelPassword()
                              }
                            />
                            <CustomButton text={i18n.t('button.save')} />
                          </ButtonContainer>
                        </Box>
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </div>
          </AccountCard>

          {/* Logout Button */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              position: 'absolute',
              bottom: '20px',
              left: '30px',
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#F6B423',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#FFB000',
                },
                width: '150px',
                height: '54px',
                boxShadow: 'none',
              }}
              startIcon={
                <img
                  src={LogoutIcon}
                  alt="logout icon"
                  width="24"
                  height="24"
                />
              }
              onClick={handleLogoutPopUp}
            >
              LOGOUT
            </Button>
          </Box>
        </DialogContent>
      </StyledDialog>
      <LogoutPopUp
        isOpenDialog={isLogoutDialog}
        onConfirmationClick={handleLogout}
        onDialogClose={handleCancelLogoutPopUp}
      ></LogoutPopUp>
    </>
  );
};

export default AccountSettingsDialog;
