import React from 'react';
import GoogleLoginButton from './GoogleLoginButton';
import SHDivider from './divider/Divider';
import { Divider } from '@mui/material';
import FacebookLoginButton from './FacebookLoginButton';
import '../../pages/auth/auth.css';
const SocialLogin = () => {
  return (
    <>
      <SHDivider text="OR"></SHDivider>
      <div
        style={{
          display: 'flex',
          gap: '20px',
          alignItems: 'center',
          height: '30px',
        }}
      >
        <GoogleLoginButton />
        <Divider orientation="vertical" variant="middle" flexItem />
        <FacebookLoginButton></FacebookLoginButton>
      </div>
    </>
  );
};

export default SocialLogin;
