import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputAdornment,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import FormField from '../common/FormField';
import CustomButton from '../common/CustomButton';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import bankIcon from '../../assets/images/bank.png';
import webIcon from '../../assets/images/web.png';
import userIcon from '../../assets/images/user.png';
import emailicon from '../../assets/images/email.png';
import mobileIcon from '../../assets/images/mobile.png';
import accountIcon from '../../assets/images/account.png';
import SuccessMsgInfo from '../common/SuccessMsgInfo';
import ErrorMessageInfo from '../common/ErrorMsgInfo';
import './addAssets.css';
import AddAssetsWrapper from './AddAccountsWraper';
import { useDispatch, useSelector } from 'react-redux';
import {
  IAssetFormProperties,
  IAssets,
} from '../../pages/onboarding/onboarding.type';
import {
  assetsSliceActions,
  IAssetBeneficiary,
} from '../../redux-store/reducers/assetsreducer';
import {
  handleAlphaNumericNumber,
  handleNumberInputChange,
  handleOnSubmit,
} from './assetCommonFunction';
import { assetFormName, validateName } from '../../utils/commonFunctions';
import { propertyType } from '../../utils/constants';
import { useLocation } from 'react-router-dom';
import CustomAutocomplete from '../common/AutoCompleteField';

type IFormInput = {
  name: string;
  website: string;
  accountNo: string;
  accountHolder: string;
  policyNo: string;
};

interface AddBankAccountProps {
  onDone: () => void;
  onAddEditDelete: (e: IAssets) => void;
  properties?: IAssetFormProperties[];
  selectedMenuItem: string; // Add a prop to track the selected menu item
}

const getFieldIcon = (fieldName: string, assetType: string) => {
  try {
    switch (assetType) {
      case 'bankAccounts':
        switch (fieldName) {
          case 'name':
            return bankIcon;
          case 'website':
            return webIcon;
          case 'accountHolder':
            return userIcon;
          default:
            return bankIcon;
        }
      case 'professionalContactAccounts':
        switch (fieldName) {
          case 'name':
            return userIcon;
          case 'phoneNumber':
            return mobileIcon;
          case 'email':
            return emailicon;
          default:
            return bankIcon;
        }

      case 'brokerageAccounts':
        switch (fieldName) {
          case 'name':
            return bankIcon;
          case 'website':
            return webIcon;
          case 'accountHolder':
            return userIcon;
          default:
            return bankIcon;
        }

      case 'insurancePolicy':
        switch (fieldName) {
          case 'name':
            return bankIcon;
          case 'website':
            return webIcon;
          case 'accountHolder':
            return userIcon;
          default:
            return bankIcon;
        }

      case 'retirementAccounts':
        switch (fieldName) {
          case 'name':
            return bankIcon;
          case 'website':
            return webIcon;
          case 'accountHolder':
            return userIcon;
          default:
            return bankIcon;
        }

      case 'cryptoAccounts':
        switch (fieldName) {
          case 'name':
            return bankIcon;
          case 'website':
            return webIcon;
          case 'accountHolder':
            return userIcon;
          default:
            return bankIcon;
        }
      default:
        return bankIcon;
    }
  } catch (error) {
    console.warn(
      `Icon not found for field: ${fieldName} in asset type: ${assetType}. Using default icon.`
    );
    return bankIcon;
  }
};

const AddBankAccount: React.FC<AddBankAccountProps> = ({
  onDone,
  onAddEditDelete,
  properties,
  selectedMenuItem, // Destructure the prop
}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [errMessage, setErrMsg] = useState('');
  const formRef = useRef<HTMLFormElement>(null);
  const assetBeneficiary: IAssetBeneficiary[] = useSelector(
    (state: any) => state.assets.assetsBeneficiaryList
  );

  const [autoData, setAutoData] = useState<any>({});
  const [disabled, setDisabled] = useState(true);

  let editAsset = useSelector((state: any) => state.assets.editAsset);
  const assetType = useSelector((state: any) => state.assets.assetType);
  const location = useLocation();
  const isDashboard = location.pathname === '/dashboard';
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm<IFormInput>({
    defaultValues: properties?.reduce((acc, property) => {
      acc[property.name as keyof IFormInput] = '';
      return acc;
    }, {} as IFormInput),
    mode: 'onChange',
  });
  const mainProperties = properties?.filter(
    (property) => property.propertyType === propertyType.main
  )!;
  const watchedFields = mainProperties.reduce((acc, property) => {
    acc[property.name as keyof IFormInput] = watch(
      property.name as keyof IFormInput
    );
    return acc;
  }, {} as IFormInput);
  const resetForm = () => {
    properties?.forEach((property) =>
      setValue(property.name as keyof IFormInput, '')
    );
    setAutoData({});
    setDisabled(true);
  };
  //Additional Properties
  const additionalProperties = properties?.filter(
    (property) => property.propertyType === propertyType.later
  );
  const banks = [
    { label: 'JPMorgan Chase' },
    { label: 'Bank of America' },
    { label: 'Citigroup' },
    { label: 'Wells Fargo' },
    { label: 'Goldman Sachs' },
  ];

  const websites = [
    { label: 'example.com' },
    { label: 'dummywebsite.net' },
    { label: 'testsite.org' },
    { label: 'website.com' },
    { label: 'devsite.io' },
    { label: 'mywebsite.co' },
    { label: 'fakesite.info' },
    { label: 'website.net' },
  ];
  useEffect(() => {
    if (editAsset) {
      if (
        JSON.stringify(editAsset.beneficiaries) !==
        JSON.stringify(assetBeneficiary)
      ) {
        setDisabled(false);
      }
    }
  }, [assetBeneficiary]);
  //clean message after 2 seconds
  useEffect(() => {
    dispatch(assetsSliceActions.setEditAssets(null));
    const handleClickInsideForm = (event: MouseEvent) => {
      if (formRef.current && formRef.current.contains(event.target as Node)) {
        setMessage('');
        setErrMsg('');
      }
    };
    document.addEventListener('mousedown', handleClickInsideForm);
    resetForm();
    return () => {
      document.removeEventListener('mousedown', handleClickInsideForm);
    };
  }, [properties]);
  useEffect(() => {
    if (message || errMessage) {
      const timer = setTimeout(() => {
        setMessage('');
        setErrMsg('');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message, errMessage]);
  // Update form if user clicks on Edit button
  useEffect(() => {
    if (editAsset) {
      let tempAutoData: any = { ...autoData };
      properties?.forEach((property) => {
        setValue(property.name as keyof IFormInput, editAsset[property.name]);
        if (property.name == 'website' || property.name == 'name') {
          tempAutoData[property.name] = editAsset[property.name];
        }
      });
      setAutoData({ ...tempAutoData });
      setMessage('');
      setErrMsg('');
    } else {
      resetForm();
    }
  }, [editAsset]);

  // Reset form when selectedMenuItem changes
  useEffect(() => {
    reset();
  }, [assetType, reset]);
  const selectedAssets = useSelector(
    (state: any) => state.assets.assetListbasedOnSelectType
  );
  const onSubmit = async (res: any) => {
    Object.keys(autoData).forEach((cur: any) => {
      if (assetType != 'bankAccounts' && cur == 'name') {
        console.log('skip name');
      } else {
        res[cur] = autoData[cur];
      }
    });
    const existingAssetIndex =
      selectedAssets && selectedAssets?.assetDetails?.length
        ? selectedAssets.assetDetails.findIndex(
            (asset: any) =>
              asset.name === res.name && asset.accountNo === res.accountNo
          )
        : -1;
    if (existingAssetIndex !== -1 && existingAssetIndex !== editAsset?.id) {
      setErrMsg(t('duplicateAssets'));
    } else {
      handleOnSubmit(res, setMessage, resetForm, setErrMsg, onAddEditDelete);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const alphabeticFields = ['accountHolder'];
    let trimmedValue = value.trim();
    if (alphabeticFields.includes(name)) {
      trimmedValue = trimmedValue.replace(/[^a-zA-Z\s]/g, '');
    }
    setValue(name as any, trimmedValue);
    checkDisabled();
  };

  const handleCancel = () => {
    resetForm();
    dispatch(assetsSliceActions.setEditAssets(null));
  };

  const handleAutoChange = (newValue: string, key: string) => {
    let tempAutoData: any = { ...autoData };
    tempAutoData[key] = newValue;
    setAutoData({ ...tempAutoData });
    checkDisabled(tempAutoData);
  };

  const checkDisabled = (tempAutoData?: any) => {
    let disable = false;
    const DataObj = tempAutoData ? tempAutoData : autoData;
    Object.values(DataObj).map((cur: any) => {
      if (!cur) {
        disable = true;
      }
    });
    if (watch('accountNo') == '') {
      disable = true;
    }
    setDisabled(disable);
  };

  const handleAssetNum = (e: any) => {
    handleNumberInputChange(e, setValue);
    checkDisabled();
  };

  return (
    <AddAssetsWrapper
      onDone={onDone}
      disabled={disabled}
      buttonText={t('doneAddingBankAccounts')}
      formHeaderText={assetFormName(assetType)}
      onCancel={handleCancel}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Box
        className={isDashboard ? 'formDashBoardConatiner' : 'formBoxContainer'}
        component="form"
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        key={assetType}
      >
        {assetType === 'bankAccounts' ? (
          <CustomAutocomplete
            name={properties![0].name}
            control={control}
            options={banks}
            value={autoData.name}
            label={t(properties![0].label)}
            placeholder={t(properties![0].placeholder)}
            icon={bankIcon}
            handleAutoInputChange={handleAutoChange}
            handleAutoChange={handleAutoChange}
            error={errors.name?.message}
          ></CustomAutocomplete>
        ) : (
          <FormField
            name={properties![0].name}
            control={control}
            label={t(properties![0].label)}
            placeholder={t(properties![0].placeholder)}
            error={errors.name?.message}
            // icon={bankIcon}
            icon={getFieldIcon(properties![0].name, assetType)}
            rules={{
              required: t('required'),
              validate: (name) => validateName(name, t),
              maxLength: {
                value: 20,
                message: t('maxLengthExceeded', { max: 20 }),
              },
            }}
            type="text"
            handlePhoneNumberChange={handleInputChange}
          />
        )}
        <CustomAutocomplete
          name={properties![1].name}
          control={control}
          options={websites}
          value={autoData.website}
          onChange={(e, newValue) => {
            if (newValue !== null) {
              setValue('website', newValue);
            }
          }}
          label={t(properties![1].label)}
          placeholder={t(properties![1].placeholder)}
          icon={webIcon}
          handleAutoInputChange={handleAutoChange}
          handleAutoChange={handleAutoChange}
          error={errors.website?.message}
        ></CustomAutocomplete>
        <FormField
          name={properties![2].name}
          control={control}
          label={t(properties![2].label)}
          placeholder={t(properties![2].placeholder)}
          error={errors.accountHolder?.message}
          icon={getFieldIcon(properties![2].name, assetType)}
          showRequired={false}
          rules={{
            maxLength: {
              value: 25,
              message: t('maxLengthExceeded', { max: 25 }),
            },
          }}
          type="text"
          handlePhoneNumberChange={handleInputChange}
        />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={isDashboard ? 12 : 6}>
            <FormControl fullWidth>
              {assetType === 'insurancePolicy' ? (
                <FormField
                  name={properties![3].name}
                  control={control}
                  label={t('label.policyNumber')}
                  placeholder={t('placeholder.account')}
                  error={errors.policyNo?.message}
                  icon={accountIcon}
                  showRequired={false}
                  rules={{
                    maxLength: {
                      value: 15,
                      message: t('maxLengthExceeded', { max: 15 }),
                    },
                  }}
                  type="text"
                  handlePhoneNumberChange={(e) => {
                    handleAlphaNumericNumber(e, setValue);
                    checkDisabled();
                  }}
                />
              ) : (
                <FormField
                  name={properties![3].name}
                  control={control}
                  label={t(properties![3].label)}
                  placeholder={t(properties![3].placeholder)}
                  error={errors.accountNo?.message}
                  icon={accountIcon}
                  rules={{
                    required: t('required'),
                    maxLength: {
                      value: 15,
                      message: t('maxLengthExceeded', { max: 15 }),
                    },
                  }}
                  type="text"
                  handlePhoneNumberChange={(e) => handleAssetNum(e)}
                />
              )}
            </FormControl>
          </Grid>
          {!isDashboard && (
            <Grid display="flex" item xs={6} marginTop={-3} gap={2}>
              <CustomButton
                text={t('button.cancel')}
                buttonType="secondary"
                handleClick={() => handleCancel()}
                style={{
                  width: '47%',
                }}
              />
              <CustomButton
                text={editAsset ? t('button.save') : t('button.add')}
                style={{
                  width: '47%',
                }}
                // disabled={
                //   mainProperties?.some(
                //     (property) =>
                //       !(watchedFields ?? {})[property.name as keyof IFormInput]
                //   ) || assetBeneficiary.length == 0
                // }
                disabled={disabled}
              />
            </Grid>
          )}
        </Grid>
        {isDashboard &&
          additionalProperties &&
          additionalProperties.map((property, index) => (
            <FormField
              key={property.name}
              name={property.name}
              control={control}
              label={t(property.label)}
              placeholder={t(property.placeholder)}
              error={errors[property.name as keyof IFormInput]?.message}
              icon={getFieldIcon(property.name, assetType)}
              showRequired={false}
              rules={{
                maxLength: {
                  value: 25,
                  message: t('maxLengthExceeded', { max: 25 }),
                },
              }}
              type="text"
              handlePhoneNumberChange={handleInputChange}
            />
          ))}
        <Grid container justifyContent="end">
          <SuccessMsgInfo successMsg={message}></SuccessMsgInfo>
          <ErrorMessageInfo errorMessage={errMessage}></ErrorMessageInfo>
        </Grid>
      </Box>
    </AddAssetsWrapper>
  );
};

export default AddBankAccount;
