import { api } from '../../utils/constants';
import DashboardModel from './dashboard.model';

class DashboardService {
  static async getDashboardCounts(httpClient: any, userId: number) {
    try {
      const response = await httpClient.get(
        `${api.getDashboardCounts}/${userId}`,
        null,
        DashboardModel.getDashboardResponseSchema
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async upgradeWellNessCheckFrequecy(httpClient: any, requestBody: any) {
    try {
      const response = await httpClient.put(
        `${api.editWellnessCheckDuration}`,
        requestBody,
        DashboardModel.getDashboardResponseSchema
      );
      if (response && response.errorCode === 200) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }
  static async getWellnessCheckData(httpClient: any, userId: number) {
    try {
      const response = await httpClient.get(
        `${api.getWellnessCheckData}/${userId}`,
        null,
        DashboardModel.getWellnessCheckDataSchema
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  static async sendSupoprtMail(httpClient: any, requestBody: any) {
    try {
      const response = await httpClient.post(
        `${api.sendSupportEmail}`,
        requestBody,
        null
      );
      if (response && response.errorCode === 200) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }
}

export default DashboardService;
