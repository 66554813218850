import { createSlice } from '@reduxjs/toolkit';

const subscriptionSlice = createSlice({
  name: 'appSlice',
  initialState: {
    isSubscriptionDialogOpen: false,
    subscriptionOption: '',
    isWellNessCheckDialogOpen: false,
  },
  reducers: {
    setIsSubscriptionDialogOpen(state, { payload }) {
      state.isSubscriptionDialogOpen = payload;
    },
    setSubscriptionOption(state, { payload }) {
      state.subscriptionOption = payload;
    },
    setIsWellnessCheckDialogOpen(state, { payload }) {
      state.isWellNessCheckDialogOpen = payload;
    },
  },
});
const { actions } = subscriptionSlice;
export const subscriptionSliceActions = actions;
export default subscriptionSlice.reducer;
