import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import EmailIcon from '../../assets/images/email-logo.svg';
import { Typography, Box } from '@mui/material';
import LogoComponent from '../../components/common/LogoComponent';
import FormField from '../../components/common/FormField';
import CustomButton from '../../components/common/CustomButton';
import { useTranslation } from 'react-i18next';
import AuthService from './auth.service';
import { useHttpClient } from '../../hooks/usecorrelation';
import { IForgotPassReqBody } from './auth.type';
import SuccessMsgInfo from '../../components/common/SuccessMsgInfo';
import ErrorMessageInfo from '../../components/common/ErrorMsgInfo';
import { validateEmail } from '../../utils/commonFunctions';
import { useDispatch } from 'react-redux';
import { appSliceActions } from '../../redux-store/reducers/appReducer';
import { useNavigate } from 'react-router-dom';
import Auth from './Auth';
import '../auth/auth.css';

interface FormData {
  email: string;
}

const ForgotPassword = () => {
  const { t } = useTranslation();
  const httpClient = useHttpClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  });

  const [passwordReset, setPasswordReset] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    dispatch(appSliceActions.setIsLoader(true));
    setPasswordReset('');
    const requestBody: IForgotPassReqBody = {
      userEmail: data.email,
      redirectLink: `${process.env.REACT_APP_BASE_URL}/setNewPassword`,
    };
    const response = await AuthService.postForgotPassword(
      httpClient,
      requestBody
    );
    if (response === 'success') {
      setErrorMsg('');
      setPasswordReset(t('passwordResetEmailSent'));
    } else {
      setErrorMsg(t('emailNotexist'));
      setError('email', {
        type: 'manual',
        message: `${t('loginErrorMessage')}`,
      });
    }
    dispatch(appSliceActions.setIsLoader(false));
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.replace(/^\s+/, ''); // Trim only leading whitespace
    setValue(event.target.name as any, trimmedValue);
  };
  return (
    <Auth>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        className={'box-container'}
      >
        <LogoComponent isCommonClass={true} />
        <Typography variant="h4" className="welcomeText">
          {t('forgotPassword')}
        </Typography>
        <Typography variant="body1" gutterBottom className="getStarted">
          {t('forgotPasswordInfo')}
        </Typography>

        <FormField
          name="email"
          control={control}
          label={t('label.email')}
          placeholder={t('placeholder.emailPlaceholder')}
          error={errors.email?.message}
          icon={EmailIcon}
          rules={{
            validate: (email) => validateEmail(email, t),
            maxLength: {
              value: 50,
              message: t('maxLengthExceeded', { max: 50 }),
            },
          }}
          showRequired={false}
          type="text"
          apiValidation={!!errorMsg}
          handlePhoneNumberChange={handleInputChange}
        />

        <SuccessMsgInfo
          successMsg={passwordReset}
          style={{ paddingBotton: '10px' }}
        ></SuccessMsgInfo>
        <ErrorMessageInfo
          errorMessage={errorMsg}
          style={{ paddingBotton: '10px' }}
        ></ErrorMessageInfo>
        <CustomButton
          text={t('button.sendPasswordResetLink')}
          style={{ mt: 2 }}
          disabled={!getValues('email')}
        />
      </Box>
    </Auth>
  );
};

export default ForgotPassword;
