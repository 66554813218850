import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Avatar,
  Menu,
  Paper,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import customBankIcon from '../../assets/images/bankAvatar.png';
import customBrokerageIcon from '../../assets/images/BrockerageAvatar.png';
import customPolicyIcon from '../../assets/images/InsuranceAvatar.png';
import realEstateIcon from '../../assets/images/realestate.png';
import customAddIcon from '../../assets/images/AddAvatar.png';
import retirmentIcon from '../../assets/images/retirment.png';
import safeboxIcon from '../../assets/images/safebox.png';
import professionalicon from '../../assets/images/professional.png';
import vehicalIcon from '../../assets/images/vehical.png';
import cryptoIcon from '../../assets/images/crypto.png';
import AddBankAccount from './AddBankAccounts';
import { useHttpClient } from '../../hooks/usecorrelation';
import OnboardingService from '../../pages/onboarding/onboarding.service';
import { appSliceActions } from '../../redux-store/reducers/appReducer';
import { useTranslation } from 'react-i18next';
import { IUserData } from '../../pages/auth/auth.type';
import {
  IAssetFormProperties,
  IAssets,
  IAssetsType,
  IPersonalization,
} from '../../pages/onboarding/onboarding.type';
import { assetsSliceActions } from '../../redux-store/reducers/assetsreducer';
import { handleEditDelete } from './assetCommonFunction';
import { assetFormProperties, assetTypes } from '../../utils/constants';
import RealEstateAccount from './RealEstateAccount';
import AddVehicalAccount from './AddVehicalAccount';
import AddSafeBoxAccount from './AddSafeBoxAccount';
import { useLocation } from 'react-router-dom';
import { beneficiarySliceActions } from '../../redux-store/reducers/beneficiaryReducer';
import AssetsList from './AssetsList';
import ProfessionalContact from '../add-edit-beneficiary/ProfessionalContact';
import './addAssets.css';
import { use } from 'i18next';
const BankAccountForm = ({
  onDone,
  onAddEditDelete,
  properties,
  selectedMenuItem,
}: {
  onDone: () => void;
  onAddEditDelete: () => void;
  properties: IAssetFormProperties[];
  selectedMenuItem: string;
}) => (
  <AddBankAccount
    onDone={onDone}
    onAddEditDelete={onAddEditDelete}
    properties={properties}
    selectedMenuItem={selectedMenuItem}
  />
);

const SafeBoxAccountForm = ({
  onDone,
  onAddEditDelete,
  properties,
  selectedMenuItem,
}: {
  onDone: () => void;
  onAddEditDelete: () => void;
  properties: IAssetFormProperties[];
  selectedMenuItem: string;
}) => (
  <AddSafeBoxAccount
    onDone={onDone}
    onAddEditDelete={onAddEditDelete}
    properties={properties}
    selectedMenuItem={selectedMenuItem}
  />
);
const VehicalForm = ({
  onDone,
  onAddEditDelete,
  properties,
  selectedMenuItem,
}: {
  onDone: () => void;
  onAddEditDelete: () => void;
  properties: IAssetFormProperties[];
  selectedMenuItem: string;
}) => (
  <AddVehicalAccount
    onDone={onDone}
    onAddEditDelete={onAddEditDelete}
    properties={properties}
    selectedMenuItem={selectedMenuItem}
  />
);
const RealEstateForm = ({
  onDone,
  onAddEditDelete,
  properties,
  selectedMenuItem,
}: {
  onDone: () => void;
  onAddEditDelete: () => void;
  properties: IAssetFormProperties[];
  selectedMenuItem: string;
}) => (
  <RealEstateAccount
    onDone={onDone}
    onAddEditDelete={onAddEditDelete}
    properties={properties}
    selectedMenuItem={selectedMenuItem}
  />
);
const OnboardingAddAssets = () => {
  useEffect(() => {
    dispatch(
      beneficiarySliceActions.setOnboardingScreenTime(new Date().toISOString())
    );
  }, []);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const userData: IUserData = useSelector((state: any) => state.auth.loginData);
  const [assetMenu, setAssetsMenu] = useState<any[]>([]);
  const dispatch = useDispatch();
  const httpClient = useHttpClient();
  const location = useLocation();
  const isDashboard = location.pathname === '/dashboard';
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const selectedMenu = useSelector((state: any) => state.assets.selectedMenu);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  interface MenuItem {
    assetId: number;
    label: string;
    icon: string;
    component: React.FC<any>;
    isShow: boolean;
    assetType: keyof typeof assetFormProperties;
  }
  const userAssets = useSelector((state: any) => state.assets.userAssets);

  const selectAssetsComponent = (
    assetType: string,
    userPersonalizeData: IPersonalization,
    assets: IAssets
  ) => {
    let isAssets = assets && assets.assetDetails.length > 0 ? true : false;
    switch (assetType) {
      case 'Bank Accounts':
        return {
          icon: customBankIcon,
          component: BankAccountForm,
          IsShow: userPersonalizeData.bankAccounts || isAssets,
          assetType: assetTypes.bankAccounts,
        };
      case 'Brokerage Accounts':
        return {
          icon: customBrokerageIcon,
          component: BankAccountForm,
          IsShow: userPersonalizeData.brokerageAccounts || isAssets,
          assetType: assetTypes.brokerageAccounts,
        };
      case 'Insurance Policy':
        return {
          icon: customPolicyIcon,
          component: BankAccountForm,
          IsShow: userPersonalizeData.insurancePolicy || isAssets,
          assetType: assetTypes.insurancePolicy,
        };
      case 'Retirement Accounts':
        return {
          icon: retirmentIcon,
          component: BankAccountForm,
          IsShow: userPersonalizeData.retirementAccounts || isAssets,
          assetType: assetTypes.retirementAccounts,
        };
      case 'Real Estate':
        return {
          icon: realEstateIcon,
          component: RealEstateForm,
          IsShow: userPersonalizeData.realEstate || isAssets,
          assetType: assetTypes.realEstateAccount,
        };
      case 'Professional Contacts':
        return {
          icon: professionalicon,
          component: ProfessionalContact,
          IsShow: userPersonalizeData.professionalService || isAssets,
          assetType: assetTypes.professionalContactAccounts,
        };
      case 'Crypto Accounts':
        return {
          icon: cryptoIcon,
          component: BankAccountForm,
          IsShow: userPersonalizeData.cryptoAccounts || isAssets,
          assetType: assetTypes.cryptoAccounts,
        };
      case 'Safe Deposit Box':
        return {
          icon: safeboxIcon,
          component: SafeBoxAccountForm,
          IsShow: userPersonalizeData.safeDepositAccount || isAssets,
          assetType: assetTypes.safeDepositAccount,
        };
      case 'Vehicle Information':
        return {
          icon: vehicalIcon,
          component: VehicalForm,
          IsShow: userPersonalizeData.vehicalAccount || isAssets,
          assetType: assetTypes.vehicleAccount,
        };
      default:
        return null;
    }
  };

  useEffect(() => {
    dispatch(assetsSliceActions.setAssetsListBasedOnSelectType({}));
    const fetchAssetsData = async () => {
      dispatch(appSliceActions.setIsLoader(true));
      try {
        // get assets types
        await OnboardingService.getBeneficiaryData(httpClient, userData.userId);
        const response = await OnboardingService.getAllAssetsData(
          httpClient,
          userData.userId
        );
        handleAssetsResponse(response);
      } catch (error) {
        console.error('Error fetching assets data: ', error);
      } finally {
        dispatch(appSliceActions.setIsLoader(false));
      }
    };
    fetchAssetsData();
  }, []);
  const handleAssetsResponse = (response: any) => {
    let newAssets = response.assets
      .map((asset: IAssetsType) => {
        const assetComponent = selectAssetsComponent(
          asset.assetType,
          response.userPersonalization,
          response.userAssets.find(
            (userAsset: IAssets) => userAsset.assetType === asset.assetType
          )
        );
        return {
          assetId: asset.assetId,
          label: asset.assetType,
          icon: assetComponent?.icon,
          component: assetComponent?.component,
          isShow: assetComponent?.IsShow,
          assetType: assetComponent?.assetType,
        };
      })
      .sort((a: any, b: any) => a.assetId - b.assetId);
    if (isDashboard) {
      newAssets = newAssets.filter(
        (item: any) => item.assetType !== assetTypes.professionalContactAccounts
      );
    }
    const selectedMenuList = newAssets.filter((item: any) => item.isShow);
    setMenuItems(selectedMenuList);
    dispatch(assetsSliceActions.setUserAssets(response.userAssets));
    setAssetsMenu(newAssets);
    if (selectedMenu) {
      const selectedAssets = response.userAssets.find(
        (userAsset: IAssets) => userAsset.assetType === selectedMenu
      );
      dispatch(
        assetsSliceActions.setAssetsListBasedOnSelectType(
          selectedAssets ?? ({} as IAssets)
        )
      );
      dispatch(
        assetsSliceActions.setAssetType(
          selectedMenuList.find((item: any) => item.label === selectedMenu)
            .assetType
        )
      );
    } else {
      dispatch(
        assetsSliceActions.setSelectedMenuLable(selectedMenuList[0].label)
      );
      const selectedAssets = response.userAssets.find(
        (userAsset: IAssets) =>
          userAsset.assetType === selectedMenuList[0]?.label
      );
      dispatch(assetsSliceActions.setAssetType(selectedMenuList[0].assetType));
      dispatch(
        assetsSliceActions.setAssetsListBasedOnSelectType(
          selectedAssets ?? ({} as IAssets)
        )
      );
    }
  };
  const handleMenuClick = (index: number, label: string) => {
    dispatch(assetsSliceActions.setSelectedMenuLable(label));
    const selectedAssets = userAssets?.find(
      (userAsset: IAssets) => userAsset.assetType === label
    );
    dispatch(
      assetsSliceActions.setAssetsListBasedOnSelectType(
        selectedAssets ?? ({} as IAssets)
      )
    );
    dispatch(
      assetsSliceActions.setAssetType(
        menuItems.find((item: any) => item.label === label)?.assetType
      )
    );
  };

  const handleTogglePopup = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  const handleDoneAddingBankAccounts = () => {
    // dispatch(assetsSliceActions.setSelectedMenuLable(label));
    // const selectedAssets = userAssets?.find(
    //   (userAsset: IAssets) =>
    //     userAsset.assetType === menuItems[selectedMenu + 1]?.label
    // );
    // dispatch(
    //   assetsSliceActions.setAssetsListBasedOnSelectType(
    //     selectedAssets ?? ({} as IAssets)
    //   )
    // );
    // dispatch(
    //   assetsSliceActions.setAssetType(menuItems[selectedMenu + 1]?.assetType)
    // );
  };

  const handleAddAssetType = (asset: any) => {
    const isAssetAlreadyAdded = assetMenu.find(
      (item) => item.label === asset.label
    );
    isAssetAlreadyAdded.isShow = true;
    setMenuItems((prevMenuItems) => [...prevMenuItems, isAssetAlreadyAdded]);
    handleClosePopup();
  };

  const handleAddEditDelete = async (assetsTobeSaved: IAssets) => {
    dispatch(appSliceActions.setIsLoader(true));
    let assetData: IAssets = {} as IAssets;
    const selectedAsset = menuItems.find(
      (item) => item.label === selectedMenu
    )!;
    if (
      assetsTobeSaved.userAssetId === 0 ||
      assetsTobeSaved.userAssetId === undefined
    ) {
      assetData = {
        userId: userData.userId,
        assetType: selectedAsset.label,
        assetDetails: assetsTobeSaved.assetDetails,
        assetTypeId: selectedAsset.assetId,
      };
      const responseData: IAssets | boolean | undefined =
        await OnboardingService.postAssetsData(httpClient, assetData);
      if (responseData && typeof responseData !== 'boolean') {
        dispatch(
          assetsSliceActions.setUserAssets([
            ...(Array.isArray(userAssets) ? userAssets : []),
            responseData,
          ])
        );

        dispatch(
          assetsSliceActions.setAssetsListBasedOnSelectType(responseData)
        );
      }
    } else {
      await handleEditDelete(httpClient, assetsTobeSaved);
    }
    dispatch(appSliceActions.setIsLoader(false));
  };

  return (
    <Paper
      sx={{
        bgcolor: 'white',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '12px',
        border: '1px solid #D0D5DD',
        boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.05);',
        marginRight: isDashboard ? '0px' : '60px',
      }}
    >
      <Grid
        className={isDashboard ? 'dashboardOuterGrid' : 'OuterGrid'}
        container
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
          flexDirection: isMobile || isTablet ? 'column' : 'row',
        }}
      >
        <Grid
          className="menuGrid custom-scrollbar"
          item
          xs={12}
          sm={12}
          md={2.3}
          lg={2.3}
          sx={{
            bgcolor: '#F7EFE7',
            padding: {
              xs: '16px',
              sm: '16px',
              md: '16px 0px 16px 16px',
              // lg: '16px 0px 16px 16px',
            },
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            borderRadius:
              isMobile || isTablet ? '12px 12px 0 0' : '12px 0 0 12px',
            justifyContent: 'flex-start',
            // minHeight:isDashboard?'70vh':'auto',
            maxHeight: isDashboard
              ? '75vh'
              : isMobile || isTablet
                ? '30vh'
                : '53vh',
          }}
        >
          {menuItems.map((item, index) => (
            <Button
              key={index}
              startIcon={
                <Avatar
                  className="menuAvatar"
                  sx={{
                    width: 30,
                    height: 30,
                    '@media (min-resolution: 120dpi) and (max-resolution: 124dpi)':
                      {
                        width: '23px !important',
                        height: '23px !important',
                      },
                    '@media (min-resolution: 144dpi) and (max-resolution: 149dpi)':
                      {
                        width: '20px !important',
                        height: '20px !important',
                        marginLeft: '5px !important',
                      },
                  }}
                >
                  <Box
                    component="img"
                    src={item.icon}
                    alt={item.label}
                    sx={{
                      width: '30px',
                      height: '30px',
                      '@media (min-resolution: 120dpi) and (max-resolution: 124dpi)':
                        {
                          width: '22px !important',
                          height: '22px !important',
                        },
                      '@media (min-resolution: 144dpi) and (max-resolution: 149dpi)':
                        {
                          width: '20px',
                          height: '20px',
                        },
                    }}
                  />
                </Avatar>
              }
              onClick={() => handleMenuClick(index, item.label)}
              sx={{
                justifyContent: 'flex-start',
                mb: 1,
                width: '100%',
                p: 1,
                bgcolor: selectedMenu === item.label ? 'white' : 'transparent',
                color: '#112F8E',
                '&:hover': { bgcolor: 'white' },
                borderRadius: {
                  xs: '12px',
                  sm: '12px',
                  md: '12px',
                  lg: '12px 0px 0px 12px',
                },
                fontWeight: '800',
                fontSize: '14px',
                opacity: selectedMenu === item.label ? 1 : 0.5,
                '@media (min-resolution: 120dpi) and (max-resolution: 124dpi)':
                  {
                    fontSize: '10px !important', // 14px * 0.8
                    p: 0.8, // 1rem * 0.8
                  },
                '@media (min-resolution: 144dpi) and (max-resolution: 149dpi)':
                  {
                    fontSize: '9.33px', // 14px * 0.67
                    p: 0.67, // 1rem * 0.67
                  },
              }}
            >
              <Typography
                textTransform="none"
                className="menuItemLabel"
                variant="body2"
                sx={{ fontWeight: '700' }}
              >
                {item.label}
              </Typography>
            </Button>
          ))}
          {assetMenu.some((asset) => !asset.isShow) && (
            <Button
              onClick={handleTogglePopup}
              startIcon={
                <Avatar
                  sx={{
                    bgcolor: '#112F8E',
                    width: 30,
                    height: 30,
                    '@media (min-resolution: 120dpi) and (max-resolution: 124dpi)':
                      {
                        width: '22px !important',
                        height: '22px !important',
                      },
                    '@media (min-resolution: 144dpi) and (max-resolution: 149dpi)':
                      {
                        width: '20px',
                        height: '20px',
                      },
                  }}
                >
                  <Box
                    component="img"
                    src={customAddIcon}
                    alt="Add"
                    sx={{ width: '20px', height: '20px' }}
                  />
                </Avatar>
              }
              sx={{
                mt: 1,
                bgcolor: 'transparent',
                color: '#112F8E',
                width: '100%',
                justifyContent: 'flex-start',
                p: 1,
              }}
            >
              <Typography
                textTransform="none"
                className="menuItemLabel"
                variant="body2"
                sx={{ fontWeight: 'bold' }}
              >
                {t('addAssetType')}
              </Typography>
            </Button>
          )}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClosePopup}
            PaperProps={{
              sx: {
                borderRadius: '12px',
                width: '30vh',
                maxHeight: '60vh',
                display: 'flex',
                flexDirection: 'column',
                marginTop: -4,
                gap: 2,
                overflowY: 'auto',
              },
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              bgcolor="#EBD39F"
              height="30px"
              marginTop="-8px"
            >
              <Typography
                variant="body2"
                className="assetType"
                sx={{ p: 1, fontWeight: 'bold' }}
                color="#112F8E"
                fontSize="14px"
              >
                {t('assetType')}
              </Typography>
            </Box>
            <Grid container spacing={2} p={2}>
              {assetMenu
                .filter((asset) => !asset.isShow)
                .map((asset, index) => (
                  <Grid item xs={6} key={index}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      onClick={() => handleAddAssetType(asset)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <Box
                        component="img"
                        src={asset.icon}
                        alt={asset.label}
                        sx={{
                          width: '40px',
                          height: '40px',
                          '@media (min-resolution: 120dpi) and (max-resolution: 124dpi)':
                            {
                              width: '19px !important',
                              height: '19px !important',
                            },
                          '@media (min-resolution: 144dpi) and (max-resolution: 149dpi)':
                            {
                              width: '18px',
                              height: '18px',
                            },
                        }}
                      />
                      <Typography
                        variant="caption"
                        textAlign="center"
                        sx={{
                          mt: 1,
                          '@media (min-resolution: 120dpi) and (max-resolution: 124dpi)':
                            {
                              fontSize: '11px !important',
                            },
                          '@media (min-resolution: 144dpi) and (max-resolution: 149dpi)':
                            {
                              fontSize: '10px !important',
                            },
                        }}
                      >
                        {asset.label}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Menu>
        </Grid>
        <>
          {menuItems.find((item) => item.label === selectedMenu) &&
          menuItems.find((item) => item.label === selectedMenu)?.assetType !==
            assetTypes.professionalContactAccounts ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={!isDashboard ? 5.7 : 4.7}
              lg={!isDashboard ? 5.7 : 4.7}
              sx={{
                bgcolor: 'white',
              }}
            >
              {isDashboard ? (
                <AssetsList />
              ) : (
                <>
                  {menuItems.find((item) => item.label === selectedMenu) &&
                    React.createElement(
                      menuItems.find((item) => item.label === selectedMenu)!
                        .component,
                      {
                        onDone: handleDoneAddingBankAccounts,
                        onAddEditDelete: handleAddEditDelete,
                        properties:
                          assetFormProperties[
                            menuItems.find(
                              (item) => item.label === selectedMenu
                            )!.assetType
                          ],
                      }
                    )}
                </>
              )}
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={12}
              md={9.7}
              lg={9.7}
              sx={{
                bgcolor: 'white',
              }}
            >
              {menuItems.find((item) => item.label === selectedMenu) &&
                React.createElement(
                  menuItems.find((item) => item.label === selectedMenu)!
                    .component,
                  {
                    onDone: handleDoneAddingBankAccounts,
                    onAddEditDelete: handleAddEditDelete,
                    properties:
                      assetFormProperties[
                        menuItems.find((item) => item.label === selectedMenu)!
                          .assetType
                      ],
                  }
                )}
            </Grid>
          )}
        </>
        <>
          {menuItems.find((item) => item.label === selectedMenu) &&
            menuItems.find((item) => item.label === selectedMenu)!.assetType !==
              assetTypes.professionalContactAccounts && (
              <Grid
                item
                xs={12}
                sm={6}
                md={!isDashboard ? 4 : 5}
                lg={!isDashboard ? 4 : 5}
                xl={!isDashboard ? 4 : 5}
              >
                {isDashboard ? (
                  <>
                    {' '}
                    {menuItems.find((item) => item.label === selectedMenu) &&
                      React.createElement(
                        menuItems.find((item) => item.label === selectedMenu)!
                          .component,
                        {
                          onDone: handleDoneAddingBankAccounts,
                          onAddEditDelete: handleAddEditDelete,
                          properties:
                            assetFormProperties[
                              menuItems.find(
                                (item) => item.label === selectedMenu
                              )!.assetType
                            ],
                        }
                      )}
                  </>
                ) : (
                  <AssetsList></AssetsList>
                )}
              </Grid>
            )}
        </>
      </Grid>
    </Paper>
  );
};

export default OnboardingAddAssets;
