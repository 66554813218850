import { Box, Typography } from '@mui/material';
import React, { useRef } from 'react';
import docUploadIcon from '../../assets/images/docUploadIcon.png';

type IProps = {
  handleUploadFile: (files: FileList) => void;
  className: any;
  disable?: boolean;
};

const UploadFile: React.FC<IProps> = (props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (files: FileList | null) => {
    if (files && files.length > 0) {
      props.handleUploadFile(files);
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleFileUpload(event.dataTransfer.files);
  };

  return (
    <Box
      className={props.className}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <Box
        component="img"
        src={docUploadIcon}
        alt=""
        sx={{
          width: 'auto', // Default width
          height: 'auto', // Default height
          '@media (min-resolution: 120dpi) and (max-resolution: 124dpi)': {
            width: '25px', // Adjust width at specific resolution
            height: '15px',
          },
          '@media (min-resolution: 144dpi) and (max-resolution: 149dpi)': {
            width: '20px', // Adjust width at different resolution
            height: '15px',
          },
        }}
      />

      <Typography
        sx={{
          '@media (min-resolution: 120dpi) and (max-resolution: 124dpi)': {
            fontSize: '12px !important',
          },
          '@media (min-resolution: 144dpi) and (max-resolution: 149dpi)': {
            fontSize: '10px !important',
          },
          '& span': {
            color: props.disable ? '#B0B0B0' : '#2196F3',
            marginRight: '5px',
            borderBottom: props.disable
              ? '1px solid #B0B0B0'
              : '1px solid #2196F3',
            cursor: props.disable ? 'not-allowed' : 'pointer',
            '@media (min-resolution: 120dpi) and (max-resolution: 124dpi)': {
              fontSize: '12px !important',
            },
            '@media (min-resolution: 144dpi) and (max-resolution: 149dpi)': {
              fontSize: '10px !important',
            },
          },
        }}
      >
        <span onClick={!props.disable ? handleClick : undefined}>
          Click to upload
        </span>
        or Drag and Drop
      </Typography>

      <Typography
        sx={{
          fontSize: '14px',
          color: '#00000099',
          '@media (min-resolution: 120dpi) and (max-resolution: 124dpi)': {
            fontSize: '12px !important',
          },
          '@media (min-resolution: 144dpi) and (max-resolution: 149dpi)': {
            fontSize: '10px !important',
          },
        }}
      >
        PDF, PNG or JPG (max 5 MB)
      </Typography>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => handleFileUpload(e.target.files)}
        accept=".pdf,.png,.jpg,.jpeg"
        disabled={props.disable}
      />
    </Box>
  );
};

export default UploadFile;
