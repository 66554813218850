import { Grid, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import CustomCarousel from '../../components/common/carousel/carousel';
import HorizontalBar from '../../components/common/footer/HorizontalBar';
import Loader from '../../components/common/loader/Loader';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../pages/auth/auth.css';
type Iprops = { children: ReactNode };
const Auth: React.FC<Iprops> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid container sx={{ height: '100vh', width: '100vw' }}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: { xs: '10vh', md: '100vh' },
            position: 'relative',
          }}
        >
          <CustomCarousel></CustomCarousel>
        </Grid>
        <Grid
          item
          xs={12}
          xl={8}
          sm={12}
          md={8}
          sx={{
            bgcolor: 'white',
          }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: { xs: 2, md: 8, sm: 2 },
              paddingBottom: { xs: 0, md: 0 },
              height: { xs: '100vh', md: '90vh' },
            }}
          >
            {props.children}
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
              height: { md: '10vh' },
            }}
          >
            <Typography
              className="contactText"
              variant="body2"
              align="center"
              color="#7A879B"
              // sx={{ m: '15px', fontSize: '14px', fontWeight: '400' }}
            >
              {t('contact_text')}{' '}
              <Link
                to="mailto:support@martheritance.com"
                color="inherit"
                style={{ color: '#7A879B' }}
              >
                {t('support@martheritance.com')}
              </Link>
            </Typography>{' '}
            <HorizontalBar />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Auth;
