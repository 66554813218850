import { Box, Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as OneIcon } from '../../assets/images/one_icon.svg';
import { ReactComponent as TwoIcon } from '../../assets/images/two_icon.svg';
import { ReactComponent as ThreeIcon } from '../../assets/images/three_icon.svg';
import CustomButton from '../../components/common/CustomButton';
import { ReactComponent as ArrowIcon } from '../../assets/images/arrow-icon.svg';
import { useTranslation } from 'react-i18next';
import { IPersonalizeReqBody } from './personalize.type';
import { useHttpClient } from '../../hooks/usecorrelation';
import { PersonalResponseType } from './Personalize';
import { useNavigate } from 'react-router-dom';
import { appSliceActions } from '../../redux-store/reducers/appReducer';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import PersonalizeService from './pesonalize.service';
import { activeOnbordingStep, api } from '../../utils/constants';
import './card.css'; // Importing the CSS file
import { authSliceActions } from '../../redux-store/reducers/authReducer';

interface CardComponentProps {
  responses: PersonalResponseType[];
}

const CardComponent: React.FC<CardComponentProps> = ({ responses }) => {
  const userData = useSelector((state: any) => state.auth.loginData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const httpClient = useHttpClient();
  const { t } = useTranslation();
  const [visibleSteps, setVisibleSteps] = useState({
    stepOne: false,
    stepTwo: false,
    stepThree: false,
  });
  const signUpStartTime = useRef<string>('');

  useEffect(() => {
    signUpStartTime.current = new Date().toISOString();
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm<IPersonalizeReqBody>({
    defaultValues: {
      bankAccounts: false,
      brokerageAccounts: false,
      insurancePolicy: false,
      retirementAccounts: false,
      realEstate: false,
      cryptoAccounts: false,
      professionalService: false,
      willSetUp: false,
    },
    mode: 'onChange',
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [buttonVisible, setButtonVisible] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const showSteps = async () => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      setVisibleSteps((prev) => ({ ...prev, stepOne: true }));

      await new Promise((resolve) => setTimeout(resolve, 500));
      setVisibleSteps((prev) => ({ ...prev, stepTwo: true }));

      await new Promise((resolve) => setTimeout(resolve, 500));
      setVisibleSteps((prev) => ({ ...prev, stepThree: true }));

      await new Promise((resolve) => setTimeout(resolve, 500));
      setButtonVisible(true);
    };

    showSteps();
  }, []);

  const fadeInVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const onSubmit: SubmitHandler<IPersonalizeReqBody> = async (data) => {
    dispatch(appSliceActions.setIsLoader(true));
    const requestData: IPersonalizeReqBody = {
      bankAccounts: responses.some(
        (response) =>
          response.question === t('bankAccountQuestion') &&
          response.response === 'yes'
      ),
      brokerageAccounts: responses.some(
        (response) =>
          response.question === t('brokerageAccountQuestion') &&
          response.response === 'yes'
      ),
      insurancePolicy: responses.some(
        (response) =>
          response.question === t('insuranceAccountQuestion') &&
          response.response === 'yes'
      ),
      retirementAccounts: responses.some(
        (response) =>
          response.question === t('retirementAccountQuestion') &&
          response.response === 'yes'
      ),
      realEstate: responses.some(
        (response) =>
          response.question === t('estateAccountQuestion') &&
          response.response === 'yes'
      ),
      cryptoAccounts: responses.some(
        (response) =>
          response.question === t('cryptoAccountQuestion') &&
          response.response === 'yes'
      ),
      professionalService: responses.some(
        (response) =>
          response.question === t('professionalService') &&
          response.response === 'yes'
      ),
      willSetUp: responses.some(
        (response) =>
          response.question === t('WillSetUp') && response.response === 'yes'
      ),
      onBoardingCurrentPage: activeOnbordingStep[0],
      onBoardingStartScreenTime: signUpStartTime.current,
    };

    const url = `${api.personalization}/${userData.userId}`;

    const responseStatus = await PersonalizeService.postPersonalizeDetails(
      httpClient,
      url,
      requestData
    );

    if (responseStatus) {
      dispatch(authSliceActions.setActiveStep(0));
      setErrorMessage('');
      navigate('/onboarding');
    } else {
      setErrorMessage(t('ErrorMessage'));
    }
    dispatch(appSliceActions.setIsLoader(false));
  };

  return (
    <Grid
      item
      className="card-section"
      xs={12}
      md={4}
      sm={4}
      sx={{
        padding: {
          xs: '2rem 1rem',
          sm: '8rem 2rem',
          md: '6rem 4.688rem',

          // lg: '4rem 7rem',
        },
        margin: {
          xs: '0',
          sm: '0',
          md: '0rem 0rem 0rem 1rem',
          lg: '0rem 0rem 2rem 1rem !important',
        },
      }}
    >
      <Box>
        {visibleSteps.stepOne && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            <Box className="CardComponentBox" sx={{ textAlign: 'center' }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Typography
                  variant="h6"
                  className="step-title setp-titleMsg"
                  // sx={{ padding: '0 3rem 0 4rem;' }}
                >
                  {t('guideMessageOne')}
                </Typography>
                <Typography
                  variant="h6"
                  className="step-title setp-titleMsg"
                  // sx={{ padding: '0 3rem 0 4rem;' }}
                >
                  {t('guideMessageTwo')}
                </Typography>
              </Box>
              <Box
                className="card-layout-box"
                // sx={{
                //   textAlign: 'center',
                //   mt: { xs: 2, sm: 2, md: 2 },
                //   width: '300px',
                //   padding: '0 0 0 7rem',
                // }}
              >
                <Box>
                  <OneIcon className="stepIcon" />
                </Box>
                <Typography className="step-one-title step-title">
                  {t('stepOneTitle')}
                </Typography>
                <Typography className="step-description">
                  {t('stepOneDescription')}
                </Typography>
              </Box>
            </Box>
          </motion.div>
        )}
        {visibleSteps.stepTwo && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            <Box
              className="card-layout-box"
              // sx={{
              //   textAlign: 'center',
              //   mt: { xs: 2, sm: 2, md: 2 },
              //   width: '300px',
              //   padding: '0 0 0 7rem',
              // }}
            >
              <Box>
                <TwoIcon className="stepIcon" />
              </Box>
              <Typography className="step-two-title step-title">
                {t('stepTwoTitle')}
              </Typography>
              <Typography className="step-description">
                {t('stepTwoDescription')}
              </Typography>
            </Box>
          </motion.div>
        )}
        {visibleSteps.stepThree && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            <Box
              className="card-layout-box"
              // sx={{
              //   textAlign: 'center',
              //   mt: { xs: 2, sm: 4, md: 2 },
              //   width: '300px',
              //   padding: '0 0 0 7rem',
              // }}
            >
              <Box>
                <ThreeIcon className="stepIcon" />
              </Box>
              <Typography className="step-three-title step-title">
                {t('stepThreeTitle')}
              </Typography>
              <Typography className="step-description">
                {t('stepThreeDescription')}
              </Typography>
            </Box>
          </motion.div>
        )}
      </Box>

      {buttonVisible && (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeInVariants}
        >
          <Box
            className="ButtonBox card-layout-box"

            // sx={{
            //   textAlign: 'center',
            //   mt: { xs: 2, sm: 9.3, md: 3.5 },
            //   width: '300px',
            //   padding: '0 0 0 7rem',
            // }}
          >
            <CustomButton
              className="proceed-btn"
              icon={<ArrowIcon />}
              text={t('proceedButtonText')}
              handleClick={handleSubmit(onSubmit)}
            />
          </Box>
        </motion.div>
      )}
    </Grid>
  );
};

export default CardComponent;
