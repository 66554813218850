import React from 'react';
import {
  Typography,
  Box,
  Container,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material';
import logo from '../../../assets/images/sh_logo.svg';
import closeicon from '../../../assets/images/closeicon.png';
import { useTranslation } from 'react-i18next';
import './TermsAndConditions.css';

interface TermsAndConditionsProps {
  isOpen: boolean;
  onClose: () => void;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={false} // Allows setting custom width
      PaperProps={{
        style: { width: '1760px', height: '940px', borderRadius: '20px ' },
      }}
    >
      <DialogContent>
        <Container className="terms-container">
          <Box className="terms-header">
            <img className="dialogLogo" src={logo}></img>
            <IconButton className="terms-close-icon" onClick={onClose}>
              <img className="closeIcon" src={closeicon} alt="Close icon" />
            </IconButton>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Typography
              variant="h4"
              component="h1"
              className="terms-heading"
              gutterBottom
            >
              {t('termspageTitle')}
            </Typography>
          </Box>

          <Typography className="terms-subtitle">
            {t('termspageSubtitle')}
          </Typography>

          <Typography paragraph className="normalText">
            BY CLICKING "I ACCEPT," OR BY DOWNLOADING, INSTALLING, OR OTHERWISE
            ACCESSING OR USING THE SERVICE, YOU AGREE THAT YOU HAVE READ AND
            UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE SERVICE, YOU
            AGREE TO BE BOUND BY, THE FOLLOWING TERMS AND CONDITIONS, INCLUDING
            LUMEN5’S PRIVACY POLICY AND ANY ADDITIONAL TERMS AND POLICIES LUMEN5
            MAY PROVIDE FROM TIME TO TIME (TOGETHER, THESE "TERMS"). If you are
            not eligible, or do not agree to the Terms, then you do not have our
            permission to use the Service. YOUR USE OF THE SERVICE, AND
            SmartHeritance’s PROVISION OF THE SERVICE TO YOU, CONSTITUTES AN
            AGREEMENT BY LUMEN5 AND BY YOU TO BE BOUND BY THESE TERMS.
          </Typography>

          <Typography className="normalText" paragraph>
            {t('Pleaseread')}
          </Typography>

          <Typography
            variant="h5"
            component="h2"
            className="terms-section-title"
            gutterBottom
          >
            {t('title1')}
          </Typography>

          <Typography className="normalText" paragraph>
            {t('textcontent1')}
          </Typography>

          <Typography
            variant="h5"
            component="h2"
            className="terms-section-title"
            gutterBottom
          >
            {t('title2')}
          </Typography>

          <Typography className="normalText" paragraph>
            {t('textcontent2')}
          </Typography>

          <Typography
            variant="h5"
            component="h2"
            className="terms-section-title"
            gutterBottom
          >
            {t('title3')}
          </Typography>

          <Typography className="normalText" paragraph>
            {t('textcontent3')}
          </Typography>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default TermsAndConditions;
