import React from 'react';
import { Button } from '@mui/material';
import './common.css';

interface ButtonProps {
  text: string;
  className?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  style?: any;
  buttonfullWidth?: boolean;
  handleClick?: (e?: any) => void;
  buttonType?: 'primary' | 'secondary';
  variant?: 'contained' | 'outlined' | 'text';
}

const CustomButton: React.FC<ButtonProps> = ({
  text,
  disabled,
  style,
  buttonfullWidth = true,
  handleClick,
  icon,
  className,
  buttonType = 'primary',
  variant = 'contained',
}) => {
  const buttonClass =
    buttonType === 'primary' ? 'primaryButton' : 'secondaryButton';
  const disableClass = disabled ? 'disableButton' : buttonClass;
  return (
    <Button
      type="submit"
      variant={variant}
      fullWidth={buttonfullWidth}
      onClick={handleClick}
      size="large"
      endIcon={icon}
      sx={{
        ...style,
      }}
      disabled={disabled}
      className={disableClass}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
