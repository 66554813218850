import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { beneficiarySliceActions } from '../../redux-store/reducers/beneficiaryReducer';
import DashboardWraper from './DashboardWraper';
import OnboardingAddAssets from '../add-assets/OnboardingAddAssets';

const DashboardAddEditAssets = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      beneficiarySliceActions.setOnboardingScreenTime(new Date().toISOString())
    );
  }, []);
  return (
    <DashboardWraper>
      <OnboardingAddAssets></OnboardingAddAssets>
    </DashboardWraper>
  );
};

export default DashboardAddEditAssets;
