import axios from 'axios';
import { api } from '../../utils/constants';
import DocumentModel from './document.model';
import { getJwtToken, getToken } from '../../services/auth.service';
import { store } from '../../testUtils';
import { documentUploadSliceActions } from '../../redux-store/reducers/documentUploadReducer';

class DocumentService {
  static async getDocumentData(httpClient: any, userId: number) {
    try {
      const response = await httpClient.get(
        `${api.getDocument}/${userId}?s3Bucket=sh-dev-resources`,
        null,
        DocumentModel.getDocumentSchema
      );
      if (response && response.errorCode === 200) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching onboarding data: ', error);
    }
  }
  static async postDocumentData(
    httpClient: any,
    userId: number,
    fileData: any
  ) {
    try {
      const token = await getToken();
      const jwtToken = await getJwtToken();
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}${api.postDocument}/${userId}?s3Bucket=sh-dev-resources`,
        fileData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
            jwtToken: `${jwtToken}`,
          },
        }
      );
      if (response && response.data && response.data.errorCode === 200) {
        return response.data.errorCode;
      } else {
        return response.data.errorCode;
      }
    } catch (error: any) {
      console.log('error', error);
      return error.status;
    }
  }
  static async deleteDocumentData(
    httpClient: any,
    userId: number,
    fileName: string
  ) {
    try {
      const reqBody = { s3Bucket: 'sh-dev-resources', fileName: fileName };
      const response = await httpClient.delete(
        `${api.deleteDocument}/${userId}`,
        reqBody,
        DocumentModel.documentUploadResSchema
      );
      if (response && response.errorCode === 200) {
        return response.errorCode;
      } else {
        return response.errorCode;
      }
    } catch (error: any) {
      console.log('error', error);
      return error.status;
    }
  }
}
export default DocumentService;
