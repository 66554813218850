import React, { useEffect, useRef, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Box, Grid, Typography, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { appSliceActions } from '../../redux-store/reducers/appReducer';
import AuthService from './auth.service';
import { useHttpClient } from '../../hooks/usecorrelation';
import EmailIcon from '../../assets/images/email-logo.svg';
import LogoComponent from '../../components/common/LogoComponent';
import CustomButton from '../../components/common/CustomButton';
import EyeIcon from '../../assets/images/eye-icon.svg';
import './auth.css';
import SocialLogin from '../../components/common/SocialLogin';
import FormField from '../../components/common/FormField';
import {
  redirectLink,
  validateEmail,
  validatePassword,
} from '../../utils/commonFunctions';
import Auth from './Auth';
import { ILoginPostDetails } from './auth.type';
import { useTranslation } from 'react-i18next';
import ErrorMessageInfo from '../../components/common/ErrorMsgInfo';
import { useNavigate } from 'react-router-dom';
import { authSliceActions } from '../../redux-store/reducers/authReducer';
import { log } from 'console';
import PrivacyPolicy from '../../components/common/termsAndPrivady/privacy';
import TermsAndConditions from '../../components/common/termsAndPrivady/terms';

// Define the form data structure
interface IFormInput {
  email: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const errorMsg = useSelector((state: any) => state.auth.loginErrorMessage);
  const userData = useSelector((state: any) => state.auth.loginData);
  const httpClient = useHttpClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    setValue,
  } = useForm<IFormInput>({
    defaultValues: {
      email: userData ? userData.userEmail : '',
      password: '',
    },
    mode: 'onChange',
  });

  // Watch the values of email and password fields
  const email = watch('email');
  const password = watch('password');
  useEffect(() => {
    if (errorMsg) {
      const timer = setTimeout(() => {
        dispatch(authSliceActions.setLoginErrorMessage(t('')));
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errorMsg]);
  useEffect(() => {
    const handleClickInsideForm = (event: MouseEvent) => {
      if (formRef.current && formRef.current.contains(event.target as Node)) {
        dispatch(authSliceActions.setLoginErrorMessage(t('')));
      }
    };
    document.addEventListener('mousedown', handleClickInsideForm);
    return () => {
      document.removeEventListener('mousedown', handleClickInsideForm);
    };
  }, []);
  //on submit Login Form
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    dispatch(appSliceActions.setIsLoader(true));
    const userData: ILoginPostDetails = {
      userEmail: data.email,
      password: data.password,
    };
    //set Loader true
    const responseData = await AuthService.postLoginDetails(
      httpClient,
      userData
    );
    if (responseData?.message === 'Login successful') {
      dispatch(authSliceActions.setLoginErrorMessage(''));
      const navigatePage = redirectLink(responseData?.onboardingCurrentPage);
      navigate(navigatePage);
    } else if (
      responseData?.message === 'Password is wrong' ||
      'Email is not Exist'
    ) {
      setError('password', {
        type: 'manual',
        message: `${t('loginErrorMessage')}`,
      });
      setError('email', {
        type: 'manual',
        message: `${t('loginErrorMessage')}`,
      });
      dispatch(authSliceActions.setLoginErrorMessage(t('loginErrorMessage')));
    }
    dispatch(appSliceActions.setIsLoader(false));
    //Handle form submission here
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.replace(/^\s+/, ''); // Trim only leading whitespace
    setValue(event.target.name as any, trimmedValue);
  };

  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const [isTermsAndConditionOpen, setisTermsAndConditionOpen] = useState(false);

  const handlePrivacyPolicyOpen = () => {
    setIsPrivacyPolicyOpen(true);
  };
  const handleTermsAndConditionOpen = () => {
    setisTermsAndConditionOpen(true);
  };

  const handlePrivacyPolicyClose = () => {
    setIsPrivacyPolicyOpen(false);
  };
  const handleTermsAndConditionClose = () => {
    setisTermsAndConditionOpen(false);
  };
  return (
    <Auth>
      <Box
        className={'box-container'}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <LogoComponent isCommonClass={true} />

        <Typography
          className="welcomeText"
          variant="h4"
          // sx={{
          //   mb: 1,
          //   textAlign: 'start',
          //   color: 'black',
          //   fontSize: '30px',
          //   fontWeight: '500',
          // }}
        >
          {t('welcome')}
        </Typography>
        <Typography
          variant="body1"
          className="getStarted"
          // sx={{ mb: 4, textAlign: 'start', color: 'black', fontSize: '16px' }}
        >
          {t('connect_text')}
        </Typography>
        <FormField
          name="email"
          control={control}
          label={t('label.email')}
          placeholder={t('placeholder.emailPlaceholder')}
          error={errors.email?.message}
          icon={EmailIcon}
          rules={{
            required: t('emailRequired'),
            validate: (email) => validateEmail(email, t),
            maxLength: {
              value: 50,
              message: t('maxLengthExceeded', { max: 50 }),
            },
          }}
          type="text"
          apiValidation={!!errorMsg}
          handlePhoneNumberChange={handleInputChange}
        />

        <FormField
          name="password"
          control={control}
          label={t('label.password')}
          placeholder={t('placeholder.passwordPlaceholder')}
          error={errors.password?.message}
          icon={EyeIcon}
          rules={{
            required: t('passwordRequired'),
            // validate: (password) => validatePassword(password, t),
            maxLength: {
              value: 50,
              message: t('maxLengthExceeded', { max: 50 }),
            },
          }}
          type="password"
          // apiValidation={!!errorMsg}
        />

        <div className="rememberme">
          <Grid container alignItems="center">
            <Grid item style={{ marginLeft: 'auto' }}>
              <Link
                className="forgotPassword"
                href="/forgotPassword"
                underline="none"
                color="primary"
              >
                {t('forgotPassword')}
              </Link>
            </Grid>
          </Grid>
        </div>
        {errorMsg && (
          <ErrorMessageInfo errorMessage={errorMsg}></ErrorMessageInfo>
        )}
        <CustomButton
          text={t('button.signIn')}
          disabled={!email || !password}
          style={{ mt: 2 }}
        />
      </Box>

      <SocialLogin></SocialLogin>

      <Typography
        className="termsAndConditionsText"
        variant="body2"
        align="center"
        // color="black"
        // sx={{ pt: 3 }}
        // fontSize="14px"
      >
        {t('termsAndConditionsText')}{' '}
        <Link
          className="privacyPolicy"
          href="#"
          underline="none"
          color="primary"
          fontWeight="700"
          onClick={handleTermsAndConditionOpen}
        >
          {t('termsAndConditions')}
        </Link>{' '}
        {t('and')} <br />
        <Link
          className="privacyPolicy"
          href="#"
          underline="none"
          color="primary"
          fontWeight="700"
          onClick={handlePrivacyPolicyOpen}
        >
          {t('privacyPolicy')}
        </Link>
      </Typography>
      <PrivacyPolicy
        isOpen={isPrivacyPolicyOpen}
        onClose={handlePrivacyPolicyClose}
      />
      <TermsAndConditions
        isOpen={isTermsAndConditionOpen}
        onClose={handleTermsAndConditionClose}
      ></TermsAndConditions>
    </Auth>
  );
};

export default LoginForm;
