import yup from '../../services/validator.service';

class AuthModel {
  static readonly AuthLoginDetailSchema = yup.object({
    data: yup
      .object({
        userId: yup.number().nullable(),
        userEmail: yup.string().defined(),
        userName: yup.string().nullable(),
        jwtToken: yup.string().defined(),
        onBoardingCurrentPage: yup.number().nullable(),
      })
      .nullable(),
    result: yup.string().defined(),
    message: yup.string().defined(),
    errorCode: yup.number().defined(),
    correlationId: yup.number().nullable(),
  });
  static readonly AuthSignupDetailSchema = yup.object({
    data: yup
      .object({
        userId: yup.number().nullable(),
        userEmail: yup.string().defined(),
        userName: yup.string().nullable(),
        jwtToken: yup.string().defined(),
        onBoardingCurrentpage: yup.number().nullable(),
        signUpFlowFlag: yup.number().nullable(),
      })
      .nullable(),
    result: yup.string().defined(),
    message: yup.string().defined(),
    errorCode: yup.number().defined(),
    correlationId: yup.number().nullable(),
  });
  static readonly ForgotPassRespSchema = yup.object({
    data: yup
      .object({
        userId: yup.number().defined(),
        email: yup.string().defined(),
      })
      .nullable(),
    result: yup.string().defined(),
    message: yup.string().defined(),
    errorCode: yup.number().defined(),
    correlationId: yup.number().nullable(),
  });
  static readonly logOutResSchema = yup.object({
    data: yup.string().defined(),
    result: yup.string().defined(),
    message: yup.string().defined(),
    errorCode: yup.number().defined(),
    correlationId: yup.number().nullable(),
  });
  static readonly updateUserProfileSchema = yup.object({
    data: yup
      .object({
        userId: yup.number().defined(),
        email: yup.string().defined(),
        name: yup.string().defined(),
        phoneNumber: yup.string().defined(),
        address: yup.string().defined(),
        // birthYear: yup.number().defined(),
        // gender: yup.string().defined(),
        // socialProvider: yup.string().defined(),
        // socialProviderId: yup.string().defined(),
        // isEmailVerified: yup.boolean().defined(),
        // isSubscriptionActive: yup.boolean().defined(),
        // isDeleted: yup.boolean().defined(),
        // isBeneficiary: yup.boolean().defined(),
        // isUserPassedAway: yup.boolean().defined(),
        // isProfileComplete: yup.boolean().defined(),
        // isOnboardingComplete: yup.boolean().defined(),
      })
      .defined(),
    result: yup.string().defined(),
    message: yup.string().defined(),
    errorCode: yup.number().defined(),
    correlationId: yup.number().nullable(),
  });
}

export default AuthModel;
