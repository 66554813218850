import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../CustomButton';
import './confirmPopup.css';
import cross from '../../../assets/images/cross.png';
import crossIcon from '../../../assets/images/icons/cross-icon.png';

type IProps = {
  isOpenDialog: boolean;
  confirmTitle: string;
  onDialogClose: () => void;
  onConfirmationClick: () => void;
  color?: string;
  subTitle1: string;
  subTitle2: string;
};

const ConfirmPopUp: FC<IProps> = ({
  isOpenDialog,
  confirmTitle,
  onConfirmationClick,
  onDialogClose,
  subTitle1,
  subTitle2,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        open={isOpenDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: '20px',
            maxWidth: '500px',
            maxHeight: '400px',
            padding: '20px',
          },
        }}
        disableEscapeKeyDown
        hideBackdrop={false}
        onClose={(event, reason) => {
          // Only close the dialog if the reason is not 'backdropClick'
          if (reason === 'backdropClick') {
            return;
          }
          onDialogClose();
        }}
      >
        <Grid container textAlign="center" className="boxPop-container">
          <Grid item xs={12} md={12} sm={12}>
            <img
              src={cross}
              alt="cross"
              // style={{ padding: '2rem 0rem 0rem 0rem' }}
            ></img>
            <DialogTitle className="dialogTitle">{confirmTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText className="dialogSubTitle">
                {subTitle1}
                {/* <br />
                {subTitle2} */}
              </DialogContentText>
            </DialogContent>
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item>
                <CustomButton
                  text={t('button.cancel')}
                  handleClick={onDialogClose}
                  buttonfullWidth={false}
                  buttonType="secondary"
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <CustomButton
                  text={t('button.delete')}
                  handleClick={onConfirmationClick}
                  buttonfullWidth={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default ConfirmPopUp;
