import i18n from '../../i18n';
import { beneficiarySliceActions } from '../../redux-store/reducers/beneficiaryReducer';
import { professionSliceActions } from '../../redux-store/reducers/professionReducer';
import store from '../../redux-store/store';
import { api } from '../../utils/constants';
import OnboardingModel from './onboarding.model';
import { IAssets, IBeneficiary, IProfessional } from './onboarding.type';

class OnboardingService {
  static async getOnboardingData(httpClient: any, userId: number) {
    try {
      const response = httpClient.get(
        `${api.getOnboardingData}/${userId}`,
        null
      );
      return await response.json();
    } catch (error) {
      console.error('Error fetching onboarding data: ', error);
    }
  }
  static async postBeneficiaryData(
    httpClient: any,
    beneficiaryData: IBeneficiary[]
  ) {
    try {
      let response;
      if (beneficiaryData[0].beneficiaryId) {
        response = await httpClient.put(
          `${api.putBeneficiaryData}${beneficiaryData[0].beneficiaryId}`,
          beneficiaryData[0],
          OnboardingModel.editBeneficiaryUserDataSchema
        );
      } else {
        response = await httpClient.post(
          api.postBeneficiaryData,
          beneficiaryData,
          OnboardingModel.getBeneficiaryUserDataSchema
        );
      }
      if (response) {
        if (response.errorCode === 201 || response.errorCode === 200) {
          let allBeneficiaries = store.getState().beneficiary.beneficieriesList;
          let updatedBeneficiaries: IBeneficiary[] = [];
          const newBeneficiary = response.data;
          if (beneficiaryData[0].beneficiaryId) {
            allBeneficiaries = allBeneficiaries.filter(
              (item) => item.beneficiaryId !== newBeneficiary.beneficiaryId
            );
            updatedBeneficiaries = [newBeneficiary, ...allBeneficiaries];
          } else {
            updatedBeneficiaries = [newBeneficiary[0], ...allBeneficiaries];
          }
          store.dispatch(
            beneficiarySliceActions.setBeneficiary(updatedBeneficiaries)
          );
          return response.result;
        } else if (response.errorCode === 400) {
          console.log(response);
          return response.message;
        } else {
          return response.message;
        }
      }
    } catch (error) {
      return 'An unexpected error occurred.';
    }
  }
  static async getBeneficiaryData(httpClient: any, userId: number) {
    try {
      const response = await httpClient.get(
        `${api.getbeneficiaries}/${userId}`,
        null,
        OnboardingModel.getBeneficiaryUserDataSchema
      );
      if (response && response.errorCode === 200) {
        store.dispatch(beneficiarySliceActions.setBeneficiary(response.data));
      } else {
        store.dispatch(beneficiarySliceActions.setBeneficiary(response.data));
      }
    } catch (error) {
      console.error('Error posting beneficiary data: ', error);
    }
  }
  static async deleteBeneficiary(client: any, beneficiaryId: number) {
    const response = await client.delete(
      `${api.putBeneficiaryData}${beneficiaryId}`,
      null,
      OnboardingModel.deleteBeneficiaryUserDataSchema
    );
    if (response.errorCode === 200) {
      let allBeneficiaries = store.getState().beneficiary.beneficieriesList;
      allBeneficiaries = allBeneficiaries.filter(
        (item) => item.beneficiaryId !== beneficiaryId
      );
      store.dispatch(beneficiarySliceActions.setBeneficiary(allBeneficiaries));
      return true;
    } else {
      return false;
    }
  }

  static async getAssetsData(client: any, userId: number) {
    try {
      const response = await client.get(
        `${api.getAssetsData}/${userId}`,
        null,
        OnboardingModel.getUserAssetsDataSchema
      );
      if (response && response.errorCode === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (err) {
      console.error('Error fetching assets data: ', err);
    }
  }
  static async getAssetsTypes(httpClient: any) {
    try {
      const response = await httpClient.get(
        `${api.getAssetsType}`,
        null,
        OnboardingModel.getAssetDataSchema
      );
      if (response && response.errorCode === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error fetching assets data: ', error);
    }
  }
  static async getUserPersonalizationData(httpClient: any, userId: number) {
    try {
      const response = await httpClient.get(
        `${api.personalization}/${userId}`,
        null,
        OnboardingModel.getPersonalizeData
      );
      if (response && response.errorCode === 200) {
        return response.data.personalizationDetail;
      } else {
        return false;
      }
    } catch (error) {
      return i18n.t('unExpectedError');
    }
  }
  static async getAllAssetsData(httpClient: any, userId: number) {
    try {
      const response = await httpClient.get(
        `${api.getUserAssetsPersonalizedData}/${userId}`,
        null,
        OnboardingModel.getUserAssetPersonalizesDataSchema
      );
      if (response && response.errorCode === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return i18n.t('unExpectedError');
    }
  }
  static async postAssetsData(httpClient: any, assetsData: IAssets) {
    try {
      const response = await httpClient.post(
        api.getAssetsData,
        assetsData,
        OnboardingModel.createUserAssetResponseSchema
      );
      if (response && response.errorCode === 201) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return i18n.t('unExpectedError');
    }
  }
  static async editAssetsData(httpClient: any, assetDetails: IAssets) {
    try {
      const response = await httpClient.put(
        `${api.getAssetsData}/asset/${assetDetails.userAssetId}`,
        assetDetails,
        OnboardingModel.createUserAssetResponseSchema
      );
      if (response && response.errorCode === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return i18n.t('unExpectedError');
    }
  }

  static async deleteProfessionData(client: any, contactId: number | null) {
    const response = await client.delete(
      `${api.putProfessionalData}/${contactId}`,
      null,
      OnboardingModel.deleteProfessionUserDataSchema
    );
    if (response.errorCode === 200) {
      // let allBeneficiaries = store.getState().beneficiary.beneficieriesList;
      // allBeneficiaries = allBeneficiaries.filter(
      //   (item) => item.beneficiaryId !== beneficiaryId
      // );
      // store.dispatch(beneficiarySliceActions.setBeneficiary(allBeneficiaries));
      return true;
    } else {
      return false;
    }
  }
  static async getProfessionaContactData(httpClient: any, userId: number) {
    try {
      const response = await httpClient.get(
        `${api.getProfessionalContactsUrl}/${userId}`,
        null,
        OnboardingModel.getProfessionalDataSchema
      );
      if (response && response.errorCode === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return i18n.t('unExpectedError');
    }
  }
  static async postProfessionData(httpClient: any, professionaData: any) {
    try {
      let response;
      if (professionaData.length) {
        response = await httpClient.post(
          api.postProfessionalData,
          professionaData,
          OnboardingModel.getProfessionalDataSchema
        );
      } else {
        response = await httpClient.patch(
          `${api.putProfessionalData}`,
          professionaData,
          OnboardingModel.editProfessionalDataSchema
        );
      }
      if (response) {
        if (response.errorCode === 201 || response.errorCode === 200) {
          return response;
        } else if (response.errorCode === 400) {
          return response;
        } else {
          return response;
        }
      }
    } catch (error) {
      return 'An unexpected error occurred.';
    }
  }
}
export default OnboardingService;
