import { use } from 'i18next';
import yup from '../../services/validator.service';

class OnboardingModel {
  static readonly userData = yup.object({
    userId: yup.number().defined(),
    email: yup.string().defined(),
    name: yup.string().defined(),
    phoneNumber: yup.string().defined(),
    address: yup.string().defined(),
    birthYear: yup.number().defined(),
    gender: yup.string().defined(),
    socialProvider: yup.string().defined(),
    socialProviderId: yup.string().defined(),
    isEmailVerified: yup.boolean().defined(),
    isSubscriptionActive: yup.boolean().defined(),
    isDeleted: yup.boolean().defined(),
    isBeneficiary: yup.boolean().defined(),
    isUserPassedAway: yup.boolean().defined(),
    isProfileComplete: yup.boolean().defined(),
    isOnboardingComplete: yup.boolean().defined(),
    dataArchivalDate: yup.string().defined(),
    createdAt: yup.string().defined(),
    updatedAt: yup.string().defined(),
    createdBy: yup.string().defined(),
    updatedBy: yup.string().defined(),
  });
  static readonly beneficiaryDataSchema = yup.object({
    beneficiaryId: yup.number().defined(),
    name: yup.string().defined(),
    email: yup.string().defined(),
    phoneNumber: yup.string().defined(),
    relationship: yup.string().defined(),
    wishNote: yup.string().nullable(),
    createdAt: yup.string().nullable(),
    updatedAt: yup.string().nullable(),
    createdBy: yup.string().nullable(),
    updatedBy: yup.string().nullable(),
    // user: this.userData,
  });

  static readonly professionDataSchema = yup.object({
    contactId: yup.number().defined(),
    userId: yup.number().defined(),
    contactType: yup.string().defined(),
    address: yup.string().defined(),
    name: yup.string().defined(),
    email: yup.string().defined(),
    phoneNumber: yup.string().defined(),
    // relationship: yup.string().defined(),
    nameOfFirm: yup.string().defined(),
  });
  static readonly getBeneficiaryUserDataSchema = yup.object({
    data: yup.array().of(this.beneficiaryDataSchema),
    result: yup.string().defined(),
    message: yup.string().nullable(),
    errorCode: yup.number().defined(),
  });
  static readonly editBeneficiaryUserDataSchema = yup.object({
    data: this.beneficiaryDataSchema,
    result: yup.string().defined(),
    message: yup.string().nullable(),
    errorCode: yup.number().defined(),
  });
  static readonly deleteBeneficiaryUserDataSchema = yup.object({
    data: yup.string().nullable(),
    result: yup.string().defined(),
    message: yup.string().nullable(),
    errorCode: yup.number().defined(),
  });

  static readonly editProfessionalDataSchema = yup.object({
    data: this.professionDataSchema,
    result: yup.string().defined(),
    message: yup.string().nullable(),
    errorCode: yup.number().defined(),
  });
  static readonly professionalDataSchema = yup.object({
    contactId: yup.number().defined(),
    userId: yup.number().defined(),
    contactType: yup.string().defined(),
    name: yup.string().defined(),
    email: yup.string().defined(),
    phoneNumber: yup.string().defined(),
    address: yup.string().nullable(),
  });

  static readonly deleteProfessionUserDataSchema = yup.object({
    data: yup.string().nullable(),
    result: yup.string().defined(),
    message: yup.string().nullable(),
    errorCode: yup.number().defined(),
  });
  static readonly getProfessionalDataSchema = yup.object({
    data: yup.array().of(this.professionalDataSchema),
    result: yup.string().defined(),
    message: yup.string().nullable(),
    errorCode: yup.number().defined(),
  });
  static readonly userAssetDataSchema = yup.object({
    userAssetId: yup.number().defined(),
    user: this.userData,
    asset: yup
      .object({
        assetId: yup.number().defined(),
        assetType: yup.string().defined(),
      })
      .defined(),
    assetType: yup.string().defined(),
    assetDetails: yup.string().defined(),
    createdAt: yup.string().defined(),
    updatedAt: yup.string().defined(),
    createdBy: yup.string().defined(),
    updatedBy: yup.string().defined(),
  });

  static readonly getUserAssetDataSchema = yup.object({
    data: this.userAssetDataSchema,
    result: yup.string().defined(),
    message: yup.string().nullable(),
    errorCode: yup.number().defined(),
    correlationId: yup.string().defined(),
  });
  static readonly assetDataSchema = yup.object({
    assetId: yup.number().defined(),
    assetType: yup.string().defined(),
  });

  static readonly getAssetDataSchema = yup.object({
    data: yup.array().of(this.assetDataSchema).defined(),
    result: yup.string().defined(),
    message: yup.string().nullable(),
    errorCode: yup.number().defined(),
  });
  static readonly getPersonalizeData = yup.object({
    data: yup.object({
      personalizationDetail: yup.string().defined(),
    }),
    result: yup.string().defined(),
    message: yup.string().nullable(),
    errorCode: yup.number().defined(),
  });

  static readonly createUserAssetDataSchema = yup.object({
    userAssetId: yup.number().defined(),
    userId: yup.number().defined(),
    assetId: yup.number().defined(),
    assetType: yup.string().defined(),
    assetDetails: yup.array().of(yup.object().shape({})),
  });
  static readonly getUserAssetsDataSchema = yup.object({
    data: yup.array().of(this.createUserAssetDataSchema),
    result: yup.string().defined(),
    message: yup.string().nullable(),
    errorCode: yup.number().defined(),
  });

  static readonly getUserAssetPersonalizesDataSchema = yup.object({
    data: yup.object({
      userAssets: yup.array().of(this.createUserAssetDataSchema),
      userPersonalization: yup.object({
        bankAccounts: yup.boolean().defined(),
        brokerageAccounts: yup.boolean().defined(),
        insurancePolicy: yup.boolean().defined(),
        retirementAccounts: yup.boolean().defined(),
        realEstate: yup.boolean().defined(),
        cryptoAccounts: yup.boolean().defined(),
        professionalService: yup.boolean().defined(),
        willSetUp: yup.boolean().defined(),
      }),
    }),
    result: yup.string().defined(),
    message: yup.string().nullable(),
    errorCode: yup.number().defined(),
  });
  static readonly createUserAssetResponseSchema = yup.object({
    data: yup
      .object({
        userAssetId: yup.number().defined(),
        userId: yup.number().defined(),
        assetId: yup.number().defined(),
        assetType: yup.string().defined(),
        assetDetails: yup.array().of(yup.object().shape({}).defined()),
      })
      .defined(),
    result: yup.string().defined(),
    message: yup.string().nullable(),
    errorCode: yup.number().defined(),
  });
}

export default OnboardingModel;
