import React, { useState, useEffect, useId } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  FormHelperText,
} from '@mui/material';
import './professional.css';
import editIcon from '../../assets/images/icons/edit.png';
import deleteIcon from '../../assets/images/icons/delete.png';
import CustomButton from '../common/CustomButton';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  formatPhoneNumber,
  handleInputChange,
  validateEmail,
  validateName,
  validatePhoneNumber,
} from '../../utils/commonFunctions';
import EmailIcon from '../../assets/images/email-logo.svg';
import PersonIcon from '../../assets/images/name-logo.svg';
import PhoneIcon from '../../assets/images/phone-icon.svg';
import FormField from '../common/FormField';
import { useDispatch, useSelector } from 'react-redux';
import { IUserData } from '../../pages/auth/auth.type';
import OnboardingService from '../../pages/onboarding/onboarding.service';
import { useHttpClient } from '../../hooks/usecorrelation';
import { IProfessional } from '../../pages/onboarding/onboarding.type';
import { useLocation } from 'react-router-dom';
import bankIcon from '../../assets/images/bank.png';
import { professionSliceActions } from '../../redux-store/reducers/professionReducer';
import { appSliceActions } from '../../redux-store/reducers/appReducer';
import ConfirmPopUp from '../common/popup/ConfirmPopUp';
import BootstrapTooltip from '../common/CommonTooltip';
import { boolean } from 'yup';
import i18n from '../../i18n';
import SuccessMsgInfo from '../common/SuccessMsgInfo';
import ErrorMessageInfo from '../common/ErrorMsgInfo';
import { assetsSliceActions } from '../../redux-store/reducers/assetsreducer';
const ProfessionalContact = () => {
  const { t } = useTranslation();
  const httpClient = useHttpClient();
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [errMessage, setErrMsg] = useState('');
  // State to track the current contact being edited
  const [editContact, setEditContact] = useState(false); // Initially no contact is being edited
  const userData: IUserData = useSelector((state: any) => state.auth.loginData);
  const [contactList, setContactList] = useState<any>([]);

  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | null>(0);
  const location = useLocation();
  const isDashboard = location.pathname === '/dashboard';
  // Define form using react-hook-form
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userId: userData.userId,
      contactId: '',
      contactType: 'Select',
      email: '',
      name: '',
      phoneNo: '',
      firm: '',
    },
    mode: 'onChange',
  });

  const professionOption = [
    t('placeholder.SELECT'),
    t('label.lawyer'),
    t('label.doctor'),
    t('label.cpa'),
    t('label.taxAdvisor'),
    t('label.financeAdvisor'),
  ];
  const handleResetForm = () => {
    setValue('name', '');
    setValue('email', '');
    setValue('phoneNo', formatPhoneNumber(''));
    setValue('contactType', 'Select');
    dispatch(professionSliceActions.setEditProfession(null));
    setValue('firm', '');
  };
  useEffect(() => {
    let timer: any;
    clearTimeout(timer);
    if (message || errMessage) {
      timer = setTimeout(() => {
        setMessage('');
        setErrMsg('');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message, errMessage]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.replace(/^\s+/, '');
    setValue(event.target.name as any, trimmedValue);
  };
  // Handle phone number change and formatting
  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value.replace(/\D/g, '');
    let formattedValue = formatPhoneNumber(inputValue);
    setValue('phoneNo', formattedValue);
  };

  const handleCancelDelete = () => {
    setDeleteId(null);
    setDeletePopup(false);
  };

  const handleConfirmDelete = async () => {
    dispatch(appSliceActions.setIsLoader(true));
    const responseData: any = await OnboardingService.deleteProfessionData(
      httpClient,
      deleteId
    );
    if (responseData) {
      const updatedContactList = contactList.filter(
        (contact: any) => contact.contactId !== deleteId
      );
      setContactList([...updatedContactList]);
      handleResetForm();
      clearErrors();
      dispatch(appSliceActions.setIsLoader(false));
      setDeletePopup(false);
    }
  };

  // Handle relationship dropdown change
  const handleChange = (event: SelectChangeEvent) => {
    setValue('contactType', event.target.value);
  };

  // Handle Edit button click
  const handleEdit = (contact: any) => {
    setValue('userId', contact.userId);
    setValue('contactId', contact.contactId);
    setValue('contactType', contact.contactType);
    setValue('name', contact.name);
    setValue('email', contact.email);
    setValue('phoneNo', formatPhoneNumber(contact.phoneNumber));

    // setValue('profession', contact.profession); // Set profession in form
    setValue('firm', contact.firm);
    setEditContact(true);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    reset({
      userId: userData.userId,
      contactId: '',
      contactType: 'Select',
      email: '',
      name: '',
      phoneNo: '',
      firm: '',
    });
    clearErrors();
    setMessage('');
    setErrMsg('');
    dispatch(professionSliceActions.setEditProfession(null));
  };

  const handleDelete = async (contactId: number) => {
    setDeletePopup(true);
    setDeleteId(contactId);
  };

  const getAllProffesionalData = async () => {
    dispatch(appSliceActions.setIsLoader(true));
    const responseData: any = await OnboardingService.getProfessionaContactData(
      httpClient,
      userData.userId
    );
    setContactList([...responseData]);
    dispatch(appSliceActions.setIsLoader(false));
  };

  useEffect(() => {
    getAllProffesionalData();
  }, []);
  const formatPhoneNumberForSubmission = (phoneNumber: string) => {
    let phonenumbe1r = phoneNumber.replace(/\D/g, '');
    return `+1-${phonenumbe1r}`;
  };
  const onSubmit = async () => {
    const addContactList = {
      userId: watch('userId'),
      contactId: watch('contactId'),
      contactType:
        watch('contactType') === 'Select' ? '' : watch('contactType'),
      name: watch('name'),
      email: watch('email'),
      phoneNumber: formatPhoneNumberForSubmission(watch('phoneNo')),
      // profession: professionType,
      firm: watch('firm'),
    };
    const existingProfessionalContact = contactList?.length
      ? contactList
          .filter((x: any) => x.contactId !== addContactList.contactId)
          .findIndex(
            (contact: any) =>
              contact.name === addContactList.name ||
              contact.email === addContactList.email ||
              contact.phoneNumber === addContactList.phoneNumber
          )
      : -1;
    if (existingProfessionalContact !== -1) {
      setErrMsg(i18n.t('duplicateProfessionalContact'));
    } else {
      dispatch(appSliceActions.setIsLoader(true));
      const tempContactList: any = editContact
        ? addContactList
        : [addContactList];

      const responseData: any = await OnboardingService.postProfessionData(
        httpClient,
        tempContactList
      );
      if (responseData.result === 'success') {
        if (editContact) {
          let tempList = [...contactList];
          const index = tempList.findIndex(
            (cur) => cur.contactId === tempContactList.contactId
          );
          tempList[index] = tempContactList;

          setContactList([...tempList]);
          setMessage(i18n.t('professionalUpdated'));
        } else {
          setContactList([...contactList, ...responseData.data]);
        }
        handleResetForm();
      } else {
        setErrMsg(responseData.message);
      }
      dispatch(appSliceActions.setIsLoader(false));
    }
  };

  const professionalList = () => {
    return (
      <>
        <Box className="prof-title">
          {contactList.length !== 0 && <> My Professional Contacts</>}
        </Box>
        {contactList.map((contact: any, index: number) => (
          <Paper
            className="prof-list"
            key={index}
            onClick={() => handleEdit(contact)}
            sx={{
              padding: 1,
              marginBottom: 1,
              backgroundColor: '#ffffff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
              boxShadow:
                watch('contactId') == contact.contactId
                  ? '2px 2px 4px 0px rgba(0, 0, 0, 0.25)'
                  : 'none',
              // borderBottom: '1px solid #D0D5DD',
              cursor: 'pointer',
            }}
          >
            <Box className="list-text" display="flex" gap={1}>
              <Typography>
                {contact.name.charAt(0).toUpperCase() + contact.name.slice(1)}
              </Typography>
              <Typography>
                {contact.contactType.charAt(0).toUpperCase() +
                  contact.contactType.slice(1)}
              </Typography>
              <Typography>{formatPhoneNumber(contact.phoneNumber)}</Typography>
            </Box>
            <div className="list-edit">
              <BootstrapTooltip title="Edit">
                <IconButton
                  sx={{
                    color: '#fff',
                    '&:hover': { backgroundColor: '#D9D9D9' },
                  }}
                >
                  <img src={editIcon} className="editIcon" alt="edit" />
                </IconButton>
              </BootstrapTooltip>
            </div>
            <div className="list-delete">
              <BootstrapTooltip title={t('button.delete')}>
                <IconButton
                  onClick={(event) => handleDelete(contact.contactId)}
                  className="icon-button"
                  sx={{
                    '&:hover': {
                      backgroundColor: 'lightgray', // Change background on hover
                    },
                  }}
                >
                  <img
                    className="editDeleteIcons"
                    src={deleteIcon}
                    alt="delete"
                  />
                </IconButton>
              </BootstrapTooltip>
            </div>
          </Paper>
        ))}
      </>
    );
  };
  const professionalForm = () => {
    return (
      <>
        <Box
          className="prof-form-title"
          sx={{ color: '#112F8E', fontWeight: '600' }}
        >
          {editContact
            ? 'Edit Professional Contact'
            : 'Add a New Professional Contact'}
        </Box>
        <Box
          component="form"
          className="prof-form-box"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormField
            name="name"
            control={control}
            label={t('label.name')}
            placeholder={t('placeholder.namePlaceholder')}
            error={errors.name?.message}
            icon={PersonIcon}
            rules={{
              required: t('nameRequired'),
              validate: (name) => validateName(name, t),
              maxLength: {
                value: 25,
                message: t('maxLengthExceeded', { max: 25 }),
              },
            }}
            type="text"
            handlePhoneNumberChange={handleInputChange}
          />
          <FormField
            name="email"
            control={control}
            label={t('label.email')}
            placeholder={t('placeholder.emailPlaceholder')}
            error={errors.email?.message}
            icon={EmailIcon}
            rules={{
              required: t('emailRequired'),
              validate: (email) => validateEmail(email, t),
              maxLength: {
                value: 50,
                message: t('maxLengthExceeded', { max: 50 }),
              },
            }}
            type="text"
            handlePhoneNumberChange={handleInputChange}
          />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <FormField
                name="phoneNo"
                control={control}
                label={t('label.phoneNumber')}
                placeholder={t('placeholder.phonePlaceholder')}
                error={errors.phoneNo?.message}
                icon={PhoneIcon}
                rules={{
                  required: t('phoneNumberRequired'),
                  validate: (phoneNo) => validatePhoneNumber(phoneNo, t),
                  maxLength: {
                    value: 14,
                    message: t('maxLengthExceeded', { max: 14 }),
                  },
                }}
                type="text"
                handlePhoneNumberChange={handlePhoneNumberChange}
              />
            </Grid>
            <Grid item xs={isDashboard ? 12 : 6} className="prof-dropdown">
              <FormControl className="form-control">
                <InputLabel
                  id="relationship-label"
                  className="input-label"
                  shrink={true}
                  sx={{ color: '#252B42' }}
                >
                  Profession <span>*</span>
                </InputLabel>
                <Select
                  labelId="relationship-label"
                  name="profession"
                  label={`${t('label.relationship')} *`}
                  value={watch('contactType')}
                  onChange={handleChange}
                  className="custom-select"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#D0D5DD', // Default border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#0047FF', // Change border color on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#0047FF', // Change border color when focused
                    },
                  }}
                >
                  {professionOption.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item}
                      sx={{ padding: '5px 11px !important' }}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!isDashboard && (
              <Grid
                display="flex"
                gap={2}
                item
                xs={6}
                className="prof-dropdown"
              >
                <CustomButton
                  className="Custombutton"
                  text={t('button.cancel')}
                  buttonType="secondary"
                  handleClick={(e) => handleCancel(e)}
                  style={{
                    width: '47%',
                  }}
                />
                <CustomButton
                  className="Custombutton"
                  handleClick={onSubmit}
                  style={{
                    width: '47%',
                  }}
                  text={editContact ? t('button.save') : t('button.add')}
                  disabled={
                    !watch('email') ||
                    !watch('name') ||
                    !watch('phoneNo') ||
                    watch('contactType') == 'Select'
                      ? true
                      : !watch('contactType')
                  }
                />
              </Grid>
            )}
            {isDashboard && (
              <Grid item xs={12}>
                <FormField
                  name="firm"
                  control={control}
                  label={t('label.firm')}
                  placeholder={t('placeholder.name')}
                  error={errors.firm?.message}
                  icon={bankIcon}
                  showRequired={false}
                  rules={{
                    maxLength: {
                      value: 17,
                      message: t('maxLengthExceeded', { max: 17 }),
                    },
                  }}
                  type="text"
                  handlePhoneNumberChange={handleInputChange}
                />
              </Grid>
            )}
            {isDashboard && (
              <Grid
                item
                xs={7}
                sx={{ position: 'absolute', bottom: '0', right: '0' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 3,
                    mr: 2,
                  }}
                >
                  <CustomButton
                    text={t('button.cancel')}
                    buttonType="secondary"
                    handleClick={(e) => handleCancel(e)}
                  />
                  <CustomButton
                    text={t('button.save')}
                    disabled={
                      !watch('email') ||
                      !watch('name') ||
                      !watch('phoneNo') ||
                      watch('contactType') == 'Select'
                        ? true
                        : !watch('contactType')
                    }
                    handleClick={() => handleSubmit && handleSubmit(onSubmit)}
                  />
                </Box>
              </Grid>
            )}

            <Grid container justifyContent="end">
              <SuccessMsgInfo successMsg={message}></SuccessMsgInfo>
              <ErrorMessageInfo errorMessage={errMessage}></ErrorMessageInfo>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };
  return (
    <>
      {isDashboard ? (
        <Paper className="prof-paper">
          <Grid container className="prof-main-container">
            <Grid item xs={12} md={6} className="add-list-container">
              {professionalList()}
            </Grid>
            <Grid item xs={12} md={6} className="add-contact-container">
              {professionalForm()}
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Grid container className="asset-prof-main-container ">
          <Grid item xs={12} md={6} className="add-contact-container">
            {professionalForm()}
          </Grid>
          <Grid item xs={12} md={6} className="add-list-container">
            {professionalList()}
          </Grid>
        </Grid>
      )}
      <ConfirmPopUp
        isOpenDialog={deletePopup}
        confirmTitle={t('areYouSure')}
        onConfirmationClick={handleConfirmDelete}
        onDialogClose={handleCancelDelete}
        subTitle1={t('deleteProfessionalSub1')}
        subTitle2={t('deleteProfessionalSub2')}
      />
    </>
  );
};
export default ProfessionalContact;
function resetForm() {
  throw new Error('Function not implemented.');
}
