import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';
import FormField from '../common/FormField';
import CustomButton from '../common/CustomButton';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import makeIcon from '../../assets/images/makeIcon.png';
import modelIcon from '../../assets/images/modelIcon.png';
import accountIcon from '../../assets/images/account.png';
import licenseIcon from '../../assets/images/licenseIcon.png';
import stateIcon from '../../assets/images/stateIcon.png';
import loanIcon from '../../assets/images/loanIcon.png';
import webIcon from '../../assets/images/web.png';
import SuccessMsgInfo from '../common/SuccessMsgInfo';
import ErrorMessageInfo from '../common/ErrorMsgInfo';
import './addAssets.css';
import AddAssetsWrapper from './AddAccountsWraper';
import { useDispatch, useSelector } from 'react-redux';
import {
  IAssetFormProperties,
  IAssets,
} from '../../pages/onboarding/onboarding.type';
import {
  assetsSliceActions,
  IAssetBeneficiary,
} from '../../redux-store/reducers/assetsreducer';
import {
  handleAlphaNumericNumber,
  handleNumberInputChange,
  handleOnSubmit,
} from './assetCommonFunction';
import { assetFormName, validateEmptyField } from '../../utils/commonFunctions';
import { propertyType } from '../../utils/constants';
import AdditionButton from './AdditionButton';
import { useLocation } from 'react-router-dom';

const vehicleMakesAndModels = [
  {
    make: 'Toyota',
    models: [
      'Camry',
      'Corolla',
      'RAV4',
      'Highlander',
      'Tacoma',
      'Land Cruiser',
    ],
  },
  {
    make: 'Honda',
    models: ['Civic', 'Accord', 'CR-V', 'Pilot', 'Odyssey', 'HR-V'],
  },
  {
    make: 'Ford',
    models: ['F-150', 'Mustang', 'Explorer', 'Escape', 'Focus', 'Fusion'],
  },
  {
    make: 'BMW',
    models: ['3 Series', '5 Series', 'X5', 'X3', 'M3', '7 Series'],
  },
  {
    make: 'Mercedes-Benz',
    models: ['C-Class', 'E-Class', 'S-Class', 'GLE', 'GLC', 'A-Class'],
  },
  {
    make: 'Chevrolet',
    models: ['Silverado', 'Equinox', 'Malibu', 'Traverse', 'Tahoe', 'Camaro'],
  },
];

type IFormInput = {
  make: string;
  model: string;
  licenseNumber: string;
  registered: string;
  loanProvider: string;
  website: string;
  loanNumber: string;
  insuranceProvider: string;
  insuranceWebsite: string;
  policyNumber: string;
};

interface AddBankAccountProps {
  onDone: () => void;
  onAddEditDelete: (e: IAssets) => void;
  properties?: IAssetFormProperties[];
  selectedMenuItem: string; // Add a prop to track the selected menu item
}

const AddVehicalAccount: React.FC<AddBankAccountProps> = ({
  onDone,
  onAddEditDelete,
  properties,
  selectedMenuItem, // Destructure the prop
}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [errMessage, setErrMsg] = useState('');
  const [availableModels, setAvailableModels] = useState<string[]>([]);
  const formRef = useRef<HTMLFormElement>(null);
  const [disable, setDisable] = useState(true);
  const assetBeneficiary: IAssetBeneficiary[] = useSelector(
    (state: any) => state.assets.assetsBeneficiaryList
  );
  let editAsset = useSelector((state: any) => state.assets.editAsset);
  const assetType = useSelector((state: any) => state.assets.assetType);
  const location = useLocation();
  const isDashboard = location.pathname === '/dashboard';
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset, // Destructure the reset function
  } = useForm<IFormInput>({
    defaultValues: properties?.reduce((acc, property) => {
      acc[property.name as keyof IFormInput] = '';
      return acc;
    }, {} as IFormInput),
    mode: 'onChange',
  });

  const mainProperties = properties?.filter(
    (property) => property.propertyType === propertyType.main
  )!;
  const watchedFields = mainProperties.reduce((acc, property) => {
    acc[property.name as keyof IFormInput] = watch(
      property.name as keyof IFormInput
    );
    return acc;
  }, {} as IFormInput);
  //Additional Properties
  const additionalProperties = properties?.filter(
    (property) => property.propertyType === propertyType.later
  );
  const resetForm = () => {
    properties?.forEach((property) =>
      setValue(property.name as keyof IFormInput, '')
    );
  };
  console.log(watchedFields, 'zzzzzzzzzzzzzzzzzzzzzz');

  const websites = [
    { label: 'example.com' },
    { label: 'dummywebsite.net' },
    { label: 'testsite.org' },
    { label: 'website.com' },
    { label: 'devsite.io' },
    { label: 'mywebsite.co' },
    { label: 'fakesite.info' },
  ];

  const states = [
    { label: 'California' },
    { label: 'Texas' },
    { label: 'Florida' },
    { label: 'New York' },
    { label: 'Illinois' },
    { label: 'Pennsylvania' },
  ];
  useEffect(() => {
    if (editAsset) {
      if (
        JSON.stringify(editAsset.beneficiaries) !==
        JSON.stringify(assetBeneficiary)
      ) {
        setDisable(false);
      }
    }
  }, [assetBeneficiary]);
  //clean message after 2 seconds
  useEffect(() => {
    dispatch(assetsSliceActions.setEditAssets(null));
    const handleClickInsideForm = (event: MouseEvent) => {
      if (formRef.current && formRef.current.contains(event.target as Node)) {
        setMessage('');
        setErrMsg('');
      }
    };
    document.addEventListener('mousedown', handleClickInsideForm);
    return () => {
      document.removeEventListener('mousedown', handleClickInsideForm);
    };
  }, [properties]);

  const handleMakeChange = (e: any, newMake: string | null) => {
    setValue('make', newMake || '');
    const makeData = vehicleMakesAndModels.find(
      (item) => item.make === newMake
    );
    setAvailableModels(makeData ? makeData.models : []);
  };

  useEffect(() => {
    if (message || errMessage) {
      const timer = setTimeout(() => {
        setMessage('');
        setErrMsg('');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message, errMessage]);

  useEffect(() => {
    if (editAsset) {
      properties?.forEach((property) => {
        setValue(property.name as keyof IFormInput, editAsset[property.name]);
      });
      setMessage('');
      setErrMsg('');
    } else {
      resetForm();
    }
  }, [editAsset]);

  // Reset form when selectedMenuItem changes
  useEffect(() => {
    reset();
  }, [assetType, reset]);
  const selectedAssets = useSelector(
    (state: any) => state.assets.assetListbasedOnSelectType
  );
  const onSubmit = async (res: any) => {
    const existingAssetIndex =
      selectedAssets && selectedAssets?.assetDetails?.length
        ? selectedAssets.assetDetails.findIndex(
            (asset: any) =>
              asset.make === res.make &&
              asset.model === res.model &&
              asset.licenseNumber === res.licenseNumber
          )
        : -1;
    if (existingAssetIndex !== -1 && existingAssetIndex !== editAsset.id) {
      setErrMsg(t('duplicateAssets'));
    } else {
      handleOnSubmit(res, setMessage, resetForm, setErrMsg, onAddEditDelete);
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.replace(/^\s+/, ''); // Trim only leading whitespace
    setValue(event.target.name as any, trimmedValue);
    checkDisabled();
  };
  const handleCancel = () => {
    resetForm();
    dispatch(assetsSliceActions.setEditAssets(null));
  };

  const checkDisabled = () => {
    let disable = false;
    mainProperties.map((cur: any) => {
      if (!watch(cur.name)) {
        disable = true;
      }
    });
    setDisable(disable);
  };
  return (
    <AddAssetsWrapper
      onDone={onDone}
      disabled={disable}
      buttonText={t('doneAddingBankAccounts')}
      formHeaderText={assetFormName(assetType)}
      handleSubmit={handleSubmit(onSubmit)}
      onCancel={handleCancel}
    >
      <Box
        className={isDashboard ? 'formDashBoardConatiner' : 'formBoxContainer'}
        component="form"
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        key={assetType}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Box sx={{ marginBottom: '23px' }}>
              <Autocomplete
                sx={{ paddingRight: '0 !important' }}
                freeSolo
                options={vehicleMakesAndModels.map((item) => item.make)}
                value={watchedFields.make || ''}
                onChange={(e, newMake) => {
                  setValue('make', newMake || '');
                  setValue('model', '');
                  setAvailableModels(
                    newMake
                      ? vehicleMakesAndModels.find(
                          (item) => item.make === newMake
                        )?.models || []
                      : []
                  );
                  checkDisabled();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={properties![0].name}
                    label={
                      <span className="formfieldLabel">
                        {t(properties![0].label)}
                      </span>
                    }
                    placeholder={t(properties![0].placeholder)}
                    error={Boolean(errors.make?.message)}
                    variant="outlined"
                    onChange={(e) => {
                      // Ensure form state is updated when the user types
                      setValue('make', e.target.value);
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            className="fieldIcon"
                            src={makeIcon}
                            alt="Make"
                            style={{ width: 25, height: 25, marginRight: 18 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    sx={{
                      '& .MuiFormLabel-asterisk': {
                        color: 'red',
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ marginBottom: '23px' }}>
              <Autocomplete
                sx={{ paddingRight: '0 !important' }}
                freeSolo
                options={availableModels}
                value={watchedFields.model || ''}
                onChange={(e, newModel) => {
                  setValue('model', newModel || '');
                  checkDisabled();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={properties![1].name}
                    label={
                      <span className="formfieldLabel">
                        {t(properties![1].label)}
                      </span>
                    }
                    placeholder={t(properties![1].placeholder)}
                    error={Boolean(errors.model?.message)}
                    variant="outlined"
                    onChange={(e) => {
                      // Ensure form state is updated when the user types
                      setValue('model', e.target.value);
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            className="fieldIcon"
                            src={stateIcon}
                            alt="Model"
                            style={{ width: 25, height: 25, marginRight: 18 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    sx={{
                      '& .MuiFormLabel-asterisk': {
                        color: 'red',
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <FormField
              name={properties![2].name}
              control={control}
              label={t(properties![2].label)}
              placeholder={t(properties![2].placeholder)}
              error={errors.licenseNumber?.message}
              icon={licenseIcon}
              rules={{
                required: t('required'),
                validate: (email) =>
                  validateEmptyField(properties![2].name, t('required')),
                maxLength: {
                  value: 25,
                  message: t('maxLengthExceeded', { max: 25 }),
                },
              }}
              type="text"
              handlePhoneNumberChange={(e) => {
                handleAlphaNumericNumber(e, setValue);
                checkDisabled();
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ marginBottom: '23px' }}>
              <Autocomplete
                sx={{ paddingRight: '0 !important' }}
                freeSolo
                options={states.map((registered) => registered.label)}
                value={watchedFields.registered || ''}
                onChange={(e, newValue) => {
                  if (newValue !== null) {
                    setValue('registered', newValue);
                    checkDisabled();
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={properties![3].name}
                    label={
                      <span className="formfieldLabel">
                        {t(properties![3].label)}{' '}
                      </span>
                    }
                    placeholder={t(properties![3].placeholder)}
                    error={Boolean(errors.registered?.message)}
                    variant="outlined"
                    onChange={(e) => {
                      // Ensure form state is updated when the user types
                      setValue('registered', e.target.value);
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            className="fieldIcon"
                            src={stateIcon}
                            alt="State"
                            style={{ width: 25, height: 25, marginRight: 18 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    sx={{
                      '& .MuiFormLabel-asterisk': {
                        color: 'red',
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <FormField
              name={properties![4].name}
              control={control}
              label={t(properties![4].label)}
              placeholder={t(properties![4].placeholder)}
              error={errors.loanProvider?.message}
              icon={loanIcon}
              showRequired={false}
              rules={{
                maxLength: {
                  value: 25,
                  message: t('maxLengthExceeded', { max: 25 }),
                },
              }}
              type="text"
              handlePhoneNumberChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ marginBottom: '23px' }}>
              <Autocomplete
                sx={{ paddingRight: '0 !important' }}
                freeSolo
                options={websites.map((website) => website.label)}
                value={watchedFields.website || ''}
                onChange={(e, newValue) => {
                  if (newValue !== null) {
                    setValue('website', newValue);
                    checkDisabled();
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={properties![5].name}
                    label={
                      <span className="formfieldLabel">
                        {t(properties![5].label)}
                      </span>
                    }
                    placeholder={t(properties![5].placeholder)}
                    error={Boolean(errors.website?.message)}
                    variant="outlined"
                    onChange={(e) => {
                      // Ensure form state is updated when the user types
                      setValue('website', e.target.value);
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            className="fieldIcon"
                            src={webIcon}
                            alt="Website"
                            style={{ width: 25, height: 25, marginRight: 18 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // required
                    // sx={{
                    //   '& .MuiFormLabel-asterisk': {
                    //     color: 'red',
                    //   },
                    // }}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={isDashboard ? 12 : 6}>
            <FormControl fullWidth>
              <FormField
                name={properties![6].name}
                control={control}
                label={t(properties![6].label)}
                placeholder={t(properties![6].placeholder)}
                error={errors.loanNumber?.message}
                icon={licenseIcon}
                showRequired={false}
                rules={{
                  maxLength: {
                    value: 15,
                    message: t('maxLengthExceeded', { max: 15 }),
                  },
                }}
                type="text"
                handlePhoneNumberChange={(e) => {
                  handleNumberInputChange(e, setValue);
                  checkDisabled();
                }}
              />
            </FormControl>
          </Grid>
          {!isDashboard && (
            <Grid display="flex" item xs={6} marginTop={-3} gap={2}>
              <CustomButton
                className="Custombutton"
                text={t('button.cancel')}
                buttonType="secondary"
                handleClick={() => handleCancel()}
                style={{
                  width: '47%',
                }}
              />
              <CustomButton
                text={editAsset ? t('button.save') : t('button.add')}
                disabled={disable}
                style={{
                  width: '47%',
                }}
              />
            </Grid>
          )}
        </Grid>
        {isDashboard && (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <FormField
                name={properties![7].name}
                control={control}
                label={t(properties![7].label)}
                placeholder={t(properties![7].placeholder)}
                error={errors.insuranceProvider?.message}
                icon={loanIcon}
                showRequired={false}
                rules={{
                  maxLength: {
                    value: 25,
                    message: t('maxLengthExceeded', { max: 25 }),
                  },
                }}
                type="text"
                handlePhoneNumberChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                name={properties![8].name}
                control={control}
                label={t(properties![8].label)}
                placeholder={t(properties![8].placeholder)}
                error={errors.insuranceWebsite?.message}
                icon={webIcon}
                showRequired={false}
                rules={{
                  maxLength: {
                    value: 25,
                    message: t('maxLengthExceeded', { max: 25 }),
                  },
                }}
                type="text"
                handlePhoneNumberChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                name={properties![9].name}
                control={control}
                label={t(properties![9].label)}
                placeholder={t(properties![9].placeholder)}
                error={errors.policyNumber?.message}
                icon={accountIcon}
                showRequired={false}
                rules={{
                  maxLength: {
                    value: 25,
                    message: t('maxLengthExceeded', { max: 25 }),
                  },
                }}
                type="text"
                handlePhoneNumberChange={(e) =>
                  handleNumberInputChange(e, setValue)
                }
              />
            </Grid>
          </Grid>
        )}
        <Grid container justifyContent="end">
          <SuccessMsgInfo successMsg={message}></SuccessMsgInfo>
          <ErrorMessageInfo errorMessage={errMessage}></ErrorMessageInfo>
        </Grid>
      </Box>
    </AddAssetsWrapper>
  );
};

export default AddVehicalAccount;
