import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'appSlice',

  initialState: {
    correlation: {},
    isLoader: false,
  },
  reducers: {
    setCorrelationId(state, { payload }) {
      state.correlation = payload;
    },
    setIsLoader(state, { payload }) {
      state.isLoader = payload;
    },
  },
});
const { actions } = appSlice;
export const appSliceActions = actions;
export default appSlice.reducer;
