import React from 'react';
import './footer.css';
const HorizontalBar = () => {
  return (
    <div className="footer-strips">
      <div className="yellow-strip"></div>
      <div className="blue-strip"></div>
    </div>
  );
};

export default HorizontalBar;
