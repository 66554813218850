import { createSlice } from '@reduxjs/toolkit';
import { IAssets, IBeneficiary } from '../../pages/onboarding/onboarding.type';
export type IAssetBeneficiary = {
  beneficiaryId: number;
  name: string;
};

type IInitialState = {
  assetsBeneficiaryList: IAssetBeneficiary[];
  editAsset: any;
  assetListbasedOnSelectType: IAssets;
  userAssets: IAssets[];
  assetType: string;
  selectedMenu: string;
};

const initialState: IInitialState = {
  assetsBeneficiaryList: [],
  editAsset: null,
  assetListbasedOnSelectType: {
    userAssetId: 0,
    userId: 0,
    assetTypeId: 0,
    assetType: '',
    assetDetails: [],
  },
  selectedMenu: '',
  userAssets: [],
  assetType: '',
};
const assetsSlice = createSlice({
  name: 'assetsSlice',
  initialState,
  reducers: {
    setBeneficiary(state, { payload }) {
      state.assetsBeneficiaryList = payload;
    },
    setEditAssets(state, { payload }) {
      state.editAsset = payload;
    },
    setAssetsListBasedOnSelectType(state, { payload }) {
      state.assetListbasedOnSelectType = payload;
    },
    setUserAssets(state, { payload }) {
      state.userAssets = payload;
    },
    setAssetType(state, { payload }) {
      state.assetType = payload;
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
    setSelectedMenuLable(state, { payload }) {
      state.selectedMenu = payload;
    },
  },
});
const { actions } = assetsSlice;
export const assetsSliceActions = actions;
export default assetsSlice.reducer;
