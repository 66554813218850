import { createSlice } from '@reduxjs/toolkit';
import { IProfessional } from '../../pages/onboarding/onboarding.type';

type IInitialState = {
  professionList: IProfessional[];
  editProfession: IProfessional | null;
  deleteProfessionConfirm: boolean;
  professionScreenTime: string;
};

const initialState: IInitialState = {
  professionList: [],
  editProfession: null,
  deleteProfessionConfirm: false,
  professionScreenTime: '',
};
const professionSlice = createSlice({
  name: 'professionSlice',
  initialState,
  reducers: {
    setProfession(state, { payload }) {
      state.professionList = payload;
    },
    setEditProfession(state, { payload }) {
      state.editProfession = payload;
    },
    setDeleteProfessionConfirm(state, { payload }) {
      state.deleteProfessionConfirm = payload;
    },
    setProfessionScreenTime(state, { payload }) {
      state.professionScreenTime = payload;
    },
    resetState(state) {
      state.professionList = initialState.professionList;
      state.editProfession = initialState.editProfession;
      state.deleteProfessionConfirm = initialState.deleteProfessionConfirm;
      state.professionScreenTime = initialState.professionScreenTime;
    },
  },
});
const { actions } = professionSlice;
export const professionSliceActions = actions;
export default professionSlice.reducer;
