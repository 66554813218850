// GoogleLoginButton.tsx
import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { IconButton } from '@mui/material';
import google from '../../assets/images/google-logo.svg';
import { useHttpClient } from '../../hooks/usecorrelation';
import { partners, serviceProvider } from '../../utils/constants';
import { ISocialLoginReqBody } from '../../pages/auth/auth.type';
import AuthService from '../../pages/auth/auth.service';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { authSliceActions } from '../../redux-store/reducers/authReducer';
import { useNavigate } from 'react-router-dom';
import { appSliceActions } from '../../redux-store/reducers/appReducer';
import '../../pages/auth/auth.css';
import { redirectLink } from '../../utils/commonFunctions';

const GoogleLoginButton: React.FC = () => {
  const httpClient = useHttpClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // // Initialize the Google login hook with the appropriate configuration
  const login = useGoogleLogin({
    onSuccess: async (response) => {
      dispatch(appSliceActions.setIsLoader(true));
      const reqBody: ISocialLoginReqBody = {
        idTokenString: response.access_token,
        serviceProvider: serviceProvider.google,
        userPartnerName: partners.smartHertance.partnerName,
        partnerCode: partners.smartHertance.partnerCode,
      };
      const responseData = await AuthService.postSocialLogin(
        httpClient,
        reqBody
      );
      if (responseData?.message === 'Login successful') {
        dispatch(authSliceActions.setLoginErrorMessage(''));
        const navigatePage = redirectLink(responseData?.onboardingCurrentPage);
        navigate(navigatePage);
      } else if (responseData?.message === 'failure') {
        dispatch(
          authSliceActions.setLoginErrorMessage(t('socialLoginErrorMessage'))
        );
      }
      dispatch(appSliceActions.setIsLoader(false));
    },
    onError: (error) => {
      dispatch(
        authSliceActions.setLoginErrorMessage(t('socialLoginErrorMessage'))
      );
      console.error('Login failed', error);
    },
    flow: 'implicit', // This is important to get the ID token (JWT)
    scope: 'openid email profile', // These scopes ensure you get email and profile info
  });

  return (
    <div>
      <IconButton onClick={() => login()}>
        <img className="goolgeIcon" src={google} alt="Google" />
      </IconButton>
    </div>
  );
};

export default GoogleLoginButton;
