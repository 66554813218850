import React, { useState } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Typography,
  styled,
  keyframes,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import './formfield.css';
import { useSelector } from 'react-redux';

interface FormFieldProps {
  name: string;
  control: any;
  label: string;
  placeholder: string;
  error?: string;
  icon?: string;
  type: 'text' | 'email' | 'number' | 'password' | 'tel';
  children?: React.ReactNode;
  rules?: {
    required?: string;
    validate?: (value: any) => boolean | string;
    maxLength?: { value: number; message: string };
  };
  showRequired?: boolean;
  apiValidation?: boolean;
  handlePhoneNumberChange?: (e: any) => void;
}

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedErrorMessage = styled(Typography)`
  animation: ${fadeInUp} 0.3s ease-out;
`;

const FormField: React.FC<FormFieldProps> = ({
  name,
  control,
  label,
  placeholder,
  error,
  icon,
  rules,
  type,
  showRequired = true,
  apiValidation,
  handlePhoneNumberChange,
  children,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    if (type === 'password') {
      setShowPassword(!showPassword);
    }
  };
  let editAsset = useSelector((state: any) => state.assets.editAsset);
  return (
    <Box className="FormFieldOuterBox">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <TextField
            className="FormField custom-textfield"
            autoComplete="off"
            {...field}
            fullWidth
            label={
              <span className="formfieldLabel" style={{ color: '#252B42' }}>
                {label}{' '}
                {showRequired && (
                  <span style={{ color: '#FF4444', marginLeft: '-4px' }}>
                    *
                  </span>
                )}
              </span>
            }
            type={showPassword ? 'text' : type}
            error={!!error}
            helperText={' '} // This empty space ensures consistent height
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: icon ? (
                <InputAdornment position="end">
                  {type === 'password' ? (
                    <IconButton onClick={handleClickShowPassword}>
                      <img className="FormFieldIcon" src={icon} alt="icon" />
                    </IconButton>
                  ) : (
                    <img className="FormFieldIcon" src={icon} alt="icon" />
                  )}
                </InputAdornment>
              ) : null,
              inputProps: {
                maxLength: rules?.maxLength?.value,
              },
            }}
            placeholder={placeholder}
            onChange={(e) => {
              field.onChange(e);
              handlePhoneNumberChange && handlePhoneNumberChange(e);
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: !error ? '#D0D5DD' : '#F44',
                },
                '&:hover fieldset': {
                  borderColor: !error ? '#0047FF' : '#F44',
                },
                '&.Mui-focused fieldset': {
                  borderColor: !error ? '#0047FF' : '#F44',
                },
              },
            }}
          />
        )}
      />
      {children && <Box className="childrenBox">{children}</Box>}
      <Box className="ErrorBox">
        {error && !apiValidation && (
          <AnimatedErrorMessage
            variant="caption"
            color="error"
            className="animated-error-message"
          >
            {error}
          </AnimatedErrorMessage>
        )}
      </Box>
    </Box>
  );
};

export default FormField;
