import { Dialog, CircularProgress } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import './loader.css';

type IProps = {
  size?: string;
  padding?: string;
};
const Loader: React.FC<IProps> = () => {
  const isLoading = useSelector((state: any) => state.app.isLoader);
  return (
    <Dialog
      data-testid="loader"
      open={isLoading}
      sx={{
        '& .MuiBackdrop-root': {
          opacity: '0.1 !important',
        },
      }}
      PaperProps={{
        style: {
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: 'none',
          overflow: 'inherit',
          backgroundColor: 'transparent',
        },
      }}
    >
      <CircularProgress className="progressbar" />
    </Dialog>
  );
};

export default Loader;
