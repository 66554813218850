import yup from '../../services/validator.service';

class DocumentModel {
  static readonly documentUploadResSchema = yup.object({
    data: yup.string().nullable(),
    result: yup.string().defined(),
    message: yup.string().nullable(),
    errorCode: yup.number().defined(),
  });
  static readonly documentGetSchema = yup.object({
    name: yup.string().defined(),
    type: yup.string().defined(),
    size: yup.string().defined(),
  });
  static readonly getDocumentSchema = yup.object({
    data: yup.array().of(this.documentGetSchema).nullable(),
    result: yup.string().defined(),
    message: yup.string().nullable(),
    errorCode: yup.number().defined(),
  });
}
export default DocumentModel;
