import React from 'react';
import { styled } from '@mui/material';
import IconStep1 from '../../assets/images/add bene_icon.svg';
import IconStep2 from '../../assets/images/add assets_icon.svg';
import IconStep3 from '../../assets/images/wellness_icon.svg';
import CompletedTick from '../../assets/images/completedtick.png';

const icons: string[] = [IconStep1, IconStep2, IconStep3];

// Styled container for the step icon to handle the completion tick positioning
const StepIconContainer = styled('div')({
  position: 'relative',
  display: 'inline-flex',
});

// Styled completion tick component
const CompletionTickContainer = styled('div')({
  position: 'absolute',
  top: '-8px',
  right: '-7px',
  width: '16px',
  height: '16px',
  zIndex: 1,
  '& img': {
    width: '100%',
    height: '100%',
  },
});

interface CustomStepIconProps {
  active: boolean;
  completed: boolean;
  index: number;
}

const CustomStepIcon: React.FC<CustomStepIconProps> = ({
  active,
  completed,
  index,
}) => {
  const iconSrc = icons[index];
  const backgroundColor = completed || active ? '#00C851' : '#9CA7B7';

  return (
    <StepIconContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          backgroundColor: backgroundColor,
        }}
      >
        {iconSrc && (
          <img
            src={iconSrc}
            alt={`Step ${index + 1}`}
            style={{ width: '20px', height: '20px' }}
          />
        )}
      </div>

      {completed && (
        <CompletionTickContainer>
          <img src={CompletedTick} alt="completed" />
        </CompletionTickContainer>
      )}
    </StepIconContainer>
  );
};

export default CustomStepIcon;
