import React from 'react';
import {
  Typography,
  Box,
  Container,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material';

import closeicon from '../../../assets/images/closeicon.png';
import { useTranslation } from 'react-i18next';
import './privacy.css';
import logo from '../../../assets/images/sh_logo.svg';

interface PrivacyPolicyProps {
  isOpen: boolean;
  onClose: () => void;
}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={false} // Allows setting custom width
      PaperProps={{
        style: { width: '1760px', height: '940px', borderRadius: '20px' },
      }}
    >
      <DialogContent>
        <Container className="privacy-policy-container">
          <Box className="privacy-policy-header">
            <img src={logo} className="dialogLogo"></img>
            <IconButton className="privacy-policy-close-icon" onClick={onClose}>
              <img className="closeIcon" src={closeicon} alt="Close icon" />
            </IconButton>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Typography
              variant="h4"
              component="h1"
              className="privacy-policy-heading"
              gutterBottom
            >
              {t('Headings.Privacy_policy')}
            </Typography>
            <Typography className="privacy-policy-updated-text normalTextContent">
              {t('Headings.Recently_updated')}
            </Typography>
          </Box>

          <Typography paragraph className="normalTextContent">
            {t('Smartheritance_provides')}
          </Typography>
          <Typography paragraph className="normalTextContent">
            {t('This_Privacy')}
          </Typography>
          <Typography paragraph className="normalTextContent">
            {t('Registration_with')}
          </Typography>

          <Typography
            variant="h5"
            component="h2"
            className="privacy-policy-subheading"
            gutterBottom
          >
            {t('Headings.Personal_information_we_may_collect')}
          </Typography>

          <Typography paragraph className="normalTextContent">
            {t('We_use')}
          </Typography>

          <Typography
            variant="h5"
            component="h2"
            className="privacy-policy-subheading"
            gutterBottom
          >
            {t('Headings.Personal_information_provided_by_you')}
          </Typography>

          <Typography className="privacy-policy-section-title" gutterBottom>
            {t('Headings.Registration')}
          </Typography>

          <Typography paragraph className="normalTextContent">
            {t('If_you')}
          </Typography>

          <Typography className="privacy-policy-section-title" gutterBottom>
            {t('Headings.Customer_support')}
          </Typography>

          <Typography paragraph className="normalTextContent">
            {t('We_may')}
          </Typography>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default PrivacyPolicy;
