import { Grid, Typography } from '@mui/material';
import React from 'react';
import i18n from '../../i18n';
type IProps = {
  children: any;
};
const DashboardWraper: React.FC<IProps> = ({ children }) => {
  return (
    <Grid className="AddEditOuterGrid">
      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid item>
        <Typography
          className="DashboardBeneficiaryBottomNote"
          sx={{
            width: {
              xs: '90vw',
              sm: '40vw',
              color: '#7D7B7B',
            },

            textAlign: 'start', // Center the text horizontally
          }}
        >
          {i18n.t('beneficiaryBottomNote')}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default DashboardWraper;
