import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { appSliceActions } from '../redux-store/reducers/appReducer';
import { httpClient } from '../services/http.service';
// Define the type for correlation object
interface CorrelationObject {
  id: string;
  timestamp: number;
}

function useCorrelation() {
  const correlation = useSelector((state: any) => state.app.correlation);
  const dispatch = useDispatch();

  function generateCorrelationId(): string {
    const correlationObject: CorrelationObject = {
      id: uuidv4(),
      timestamp: new Date().getTime(), // Current timestamp in milliseconds
    };
    dispatch(appSliceActions.setCorrelationId(correlationObject));
    // storeInLocalStorage('correlationId', correlationObject);
    return correlationObject.id;
  }

  function getCorrelationId(): string {
    if (
      correlation?.id &&
      correlation?.timestamp &&
      isCorrelationActive(correlation.timestamp)
    ) {
      return correlation.id;
    }
    // Generate a new correlation ID if not found or expired
    return generateCorrelationId();
  }

  function isCorrelationActive(storedTimestamp: number): boolean {
    const currentTimestamp = new Date().getTime();
    const timeDifference = currentTimestamp - storedTimestamp;
    const expirationTime = 6 * 60 * 60 * 1000; // 6 hours in milliseconds
    return timeDifference < expirationTime;
  }

  return getCorrelationId();
}

export default useCorrelation;

export function useHttpClient() {
  const correlationId = useCorrelation();
  return httpClient(correlationId);
}
