import React, { useEffect, useState } from 'react';
import {
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import AssetIcon from '../../assets/images/noAssets.png';
import EditSvg from '../../assets/images/icons/edit.png';
import DeletePng from '../../assets/images/icons/delete.png';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IAssets } from '../../pages/onboarding/onboarding.type';
import { assetsSliceActions } from '../../redux-store/reducers/assetsreducer';
import ConfirmPopUp from '../common/popup/ConfirmPopUp';
import { handleEditDelete } from './assetCommonFunction';
import { useHttpClient } from '../../hooks/usecorrelation';
import { appSliceActions } from '../../redux-store/reducers/appReducer';
import { assetTypes } from '../../utils/constants';
import { useLocation } from 'react-router-dom';
import BootstrapTooltip from '../common/CommonTooltip';

const avatarColors = ['#C9ABAB', '#93B1CE', '#CFEECC', '#C1BC97'];

const getInitials = (name: string) => {
  const initials = name
    .split(' ')
    .map((n) => n[0])
    .join('');
  return initials.toUpperCase();
};

// Static data for assets
const AssetsList: React.FC = () => {
  const [editMode, setEditMode] = useState<number | null>(null);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const { t } = useTranslation();
  const location = useLocation();
  const selectedAssets: IAssets = useSelector(
    (state: any) => state.assets.assetListbasedOnSelectType
  );
  const dispatch = useDispatch();
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const httpClient = useHttpClient();
  const assetType = useSelector((state: any) => state.assets.assetType);
  const handleEditClick = (id: number) => {
    dispatch(
      assetsSliceActions.setEditAssets({
        id: id,
        ...selectedAssets.assetDetails[id],
      })
    );
    setEditMode(id);
  };
  const handleDeleteClick = (id: number, event: React.MouseEvent) => {
    event.stopPropagation();
    setDeleteId(id);
    setDeletePopup(true);
  };
  const handleConfirmDelete = async () => {
    dispatch(appSliceActions.setIsLoader(true));
    const updatedAssets = selectedAssets.assetDetails.filter(
      (_: any, index: number) => index !== deleteId
    );
    const updatedSelectedAssets = {
      ...selectedAssets,
      assetDetails: updatedAssets,
    };
    await handleEditDelete(httpClient, updatedSelectedAssets);
    setDeletePopup(false);
    setDeleteId(null);
    dispatch(assetsSliceActions.setEditAssets(null));
    dispatch(appSliceActions.setIsLoader(false));
  };
  const handleCancleDelete = () => {
    setDeleteId(null);
    setDeletePopup(false);
  };

  const getAssetListDetails = () => {
    switch (assetType) {
      case assetTypes.bankAccounts:
        return selectedAssets.assetDetails.map((assets: any) => ({
          name: `${assets.name}`,
          accountNo: `A/C #${assets.accountNo}`,
          beneficiary: assets.beneficiary,
        }));
      case assetTypes.brokerageAccounts:
        return selectedAssets.assetDetails.map((assets: any) => ({
          name: `${assets.name} `,
          accountNo: `A/C #${assets.accountNo}`,
          beneficiary: assets.beneficiary,
        }));
      case assetTypes.insurancePolicy:
        return selectedAssets.assetDetails.map((assets: any) => ({
          name: `${assets.name}`,
          accountNo: `Policy #${assets.accountNo}`,
          beneficiary: assets.beneficiary,
        }));
      case assetTypes.retirementAccounts:
        return selectedAssets.assetDetails.map((assets: any) => ({
          name: `${assets.name}`,
          accountNo: `A/C #${assets.accountNo}`,
          beneficiary: assets.beneficiary,
        }));
      case assetTypes.cryptoAccounts:
        return selectedAssets.assetDetails.map((assets: any) => ({
          name: `${assets.name}`,
          accountNo: `A/C #${assets.accountNo}`,
          beneficiary: assets.beneficiary,
        }));
      case assetTypes.realEstateAccount:
        return selectedAssets.assetDetails.map((assets: any) => ({
          name: `${assets.propertyType} in ${assets.city}`,
          accountNo: ``,
          beneficiary: assets.beneficiary,
        }));
      case assetTypes.safeDepositAccount:
        return selectedAssets.assetDetails.map((assets: any) => ({
          name: `${assets.name}`,
          accountNo: `Box#${assets.boxNumber}`,
          beneficiary: assets.beneficiary,
        }));
      case assetTypes.vehicleAccount:
        return selectedAssets.assetDetails.map((assets: any) => ({
          name: `${assets.make} ${assets.model}`,
          accountNo: `A/C ${assets.licenseNumber}`,
          beneficiary: assets.beneficiary,
        }));
      default:
        return selectedAssets.assetDetails;
    }
  };
  const isDashboard = location.pathname === '/dashboard';
  return (
    <Box
      className={
        isDashboard ? 'listConatiner dashListConatiner' : 'listConatiner'
      }
    >
      {selectedAssets &&
        selectedAssets.assetDetails &&
        selectedAssets.assetDetails.length !== 0 && (
          <Typography className="headerText listHeader">
            {t(assetType)}
          </Typography>
        )}
      {selectedAssets &&
      selectedAssets.assetDetails &&
      selectedAssets.assetDetails.length > 0 ? (
        <Box
          className="custom-scrollbar"
          sx={{
            overflowY: 'auto',
            maxHeight: isDashboard ? '65vh' : '42vh', // Adjust the maxHeight as needed
          }}
        >
          {getAssetListDetails().map((assets: any, index: number) => (
            <Paper
              key={index}
              sx={{
                padding: 1,
                marginBottom: 1,
                backgroundColor: '#ffffff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
                boxShadow:
                  editMode === index
                    ? '2px 2px 4px 0px rgba(0, 0, 0, 0.25)'
                    : 'none',
                cursor: 'pointer',
              }}
              onClick={() => handleEditClick(index)}
            >
              {/* Bank Info */}
              <Box
                sx={{
                  marginLeft: { sm: '16px' },
                  flexGrow: 1,
                  textAlign: { xs: 'center', sm: 'left' },
                  display: 'flex',
                }}
              >
                <Box sx={{ marginRight: '20px', width: '50%' }}>
                  <Typography
                    variant="body1"
                    className="assetName"
                    fontWeight={600}
                  >
                    {assets.name}
                  </Typography>

                  <Typography
                    variant="body1"
                    className="assetAccountNo"
                    fontWeight={500}
                  >
                    {assets.accountNo}
                  </Typography>
                </Box>
                <AvatarGroup
                  max={4}
                  sx={{
                    '& .MuiAvatar-root': {
                      width: 24,
                      height: 24,
                      fontSize: '10px',
                      border: 'none',
                    },
                  }}
                >
                  {assets.beneficiary.map((x: any, i: any) => (
                    <Avatar
                      key={i}
                      sx={{
                        backgroundColor: avatarColors[i % avatarColors.length],
                        '@media (min-resolution: 120dpi) and (max-resolution: 124dpi)':
                          {
                            width: '20px !important',
                            height: '20px !important',
                          },
                        '@media (min-resolution: 144dpi) and (max-resolution: 149dpi)':
                          {
                            width: '19px !important',
                            height: '19px !important',
                          },
                      }}
                    >
                      {getInitials(x.name)}
                    </Avatar>
                  ))}
                </AvatarGroup>
              </Box>

              {/* Avatars */}

              {/* Action Buttons (Edit and Delete) */}
              <Box sx={{ display: 'flex' }}>
                <BootstrapTooltip title="Edit">
                  <IconButton
                    onClick={() => handleEditClick(index)}
                    sx={{
                      color: '#fff',
                      '&:hover': { backgroundColor: '#D9D9D9' },
                    }}
                  >
                    <img src={EditSvg} className="editDeleteIcons" alt="edit" />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip title="Delete">
                  <IconButton
                    onClick={(event) => handleDeleteClick(index, event)}
                    sx={{
                      color: '#fff',
                      '&:hover': { backgroundColor: '#D9D9D9' },
                    }}
                  >
                    <img
                      className="editDeleteIcons"
                      src={DeletePng}
                      alt="delete"
                    />
                  </IconButton>
                </BootstrapTooltip>
              </Box>
            </Paper>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            boxShadow: 'none',
          }}
        >
          <img src={AssetIcon} alt="No Assets" width="30%" height="40%" />
          <Typography marginTop="20px" color="#9CA7B7" fontWeight={700}>
            {t('noAssetsAdded')}
          </Typography>
        </Box>
      )}
      {deletePopup && (
        <ConfirmPopUp
          isOpenDialog={true}
          confirmTitle={t('areYouSure')}
          onConfirmationClick={() => {
            handleConfirmDelete();
          }}
          onDialogClose={() => {
            handleCancleDelete();
          }}
          subTitle1={t('deleteAssetSub1')}
          subTitle2={t('deleteBeneficiarySub2')}
        />
      )}
    </Box>
  );
};

export default AssetsList;
