import React, { useEffect } from 'react';
import { Grid, Paper } from '@mui/material';

import BeneficiariesList from '../BeneficiariesList/BeneficiariesList';
import AddEditBeneficiary from './AddEditBeneficiary';
import { useDispatch } from 'react-redux';
import { beneficiarySliceActions } from '../../redux-store/reducers/beneficiaryReducer';
import { assetsSliceActions } from '../../redux-store/reducers/assetsreducer';

const OnboardingAddEditBeneficiary = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(beneficiarySliceActions.resetState());
    // dispatch(assetsSliceActions.resetState());
    dispatch(
      beneficiarySliceActions.setOnboardingScreenTime(new Date().toISOString())
    );
  }, []);
  return (
    <Paper
      sx={{
        borderRadius: '12px',
        border: '1px solid #D0D5DD',
        boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.05)',
        marginRight: '60px',
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          xl={6}
          sx={{ padding: '25px 5px 20px 25px' }}
        >
          <AddEditBeneficiary />
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{}}>
          <BeneficiariesList />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OnboardingAddEditBeneficiary;
