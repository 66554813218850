import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import FormField from '../common/FormField';
import CustomButton from '../common/CustomButton';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cityIcon from '../../assets/images/city.png';
import zipIcon from '../../assets/images/zip.png';
import bankIcon from '../../assets/images/bank.png';
import addressIcon from '../../assets/images/address.png';
import accountIcon from '../../assets/images/account.png';
import SuccessMsgInfo from '../common/SuccessMsgInfo';
import ErrorMessageInfo from '../common/ErrorMsgInfo';
import './addAssets.css';
import AddAssetsWrapper from './AddAccountsWraper';
import { useDispatch, useSelector } from 'react-redux';
import {
  IAssetFormProperties,
  IAssets,
} from '../../pages/onboarding/onboarding.type';
import {
  assetsSliceActions,
  IAssetBeneficiary,
} from '../../redux-store/reducers/assetsreducer';
import { handleNumberInputChange, handleOnSubmit } from './assetCommonFunction';
import {
  assetFormName,
  formatFileSize,
  getFileIcon,
  validateEmptyField,
} from '../../utils/commonFunctions';
import { propertyType } from '../../utils/constants';
import { useLocation } from 'react-router-dom';
import UploadFile from '../add-edit-documents/UploadFile';
import DeleteIcon from '../../assets/images/icons/delete.png';
import CustomAutocomplete from '../common/AutoCompleteField';
import { check } from 'prettier';

type IFormInput = {
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  boxNumber: string;
  descriptionofContent: string;
};
interface UploadedFile {
  name: string;
  size: number;
  type: string;
}
interface AddBankAccountProps {
  onDone: () => void;
  onAddEditDelete: (e: IAssets) => void;
  properties?: IAssetFormProperties[];
  selectedMenuItem: string; // Add a prop to track the selected menu item
}

const AddSafeBoxAccount: React.FC<AddBankAccountProps> = ({
  onDone,
  onAddEditDelete,
  properties,
  selectedMenuItem, // Destructure the prop
}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [errMessage, setErrMsg] = useState('');
  const formRef = useRef<HTMLFormElement>(null);
  const assetBeneficiary: IAssetBeneficiary[] = useSelector(
    (state: any) => state.assets.assetsBeneficiaryList
  );
  let editAsset = useSelector((state: any) => state.assets.editAsset);
  const assetType = useSelector((state: any) => state.assets.assetType);
  const dispatch = useDispatch();
  const location = useLocation();
  const isDashboard = location.pathname === '/dashboard';
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile>();
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const [autoData, setAutoData] = useState<any>({
    city: '',
    state: '',
    country: '',
    name: '',
  });
  const [disabled, setDisabled] = useState(true);
  const handleFileUpload = (files: FileList) => {
    const newFiles = {
      name: files[0].name,
      size: files[0].size,
      type: files[0].type,
      myFile: files[0],
    };
    setUploadedFiles(newFiles);
    getFileFromFormData(newFiles.myFile);
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset, // Destructure the reset function
  } = useForm<IFormInput>({
    defaultValues: properties?.reduce((acc, property) => {
      acc[property.name as keyof IFormInput] = '';
      return acc;
    }, {} as IFormInput),
    mode: 'onChange',
  });
  const mainProperties = properties?.filter(
    (property) => property.propertyType === propertyType.main
  )!;
  const watchedFields = mainProperties.reduce((acc, property) => {
    acc[property.name as keyof IFormInput] = watch(
      property.name as keyof IFormInput
    );
    return acc;
  }, {} as IFormInput);

  const banks = [
    { label: 'JPMorgan Chase' },
    { label: 'Bank of America' },
    { label: 'Citigroup' },
    { label: 'Wells Fargo' },
    { label: 'Goldman Sachs' },
    { label: 'Morgan Stanley' },
  ];
  const countries = [
    { label: 'United States' },
    { label: 'Canada' },
    { label: 'Australia' },
    { label: 'Germany' },
    { label: 'India' },
  ];

  const states = [
    { label: 'California' },
    { label: 'Texas' },
    { label: 'Florida' },
    { label: 'New York' },
    { label: 'Illinois' },
    { label: 'Pennsylvania' },
  ];

  const cities = [
    { label: 'New York City' },
    { label: 'Los Angeles' },
    { label: 'Chicago' },
    { label: 'Houston' },
    { label: 'Phoenix' },
  ];

  const resetForm = () => {
    properties?.forEach((property) =>
      setValue(property.name as keyof IFormInput, '')
    );
    setAutoData({
      city: '',
      state: '',
      country: '',
      name: '',
    });
    setDisabled(true);
    setUploadedFiles(undefined);
    dispatch(assetsSliceActions.setEditAssets(null));
  };
  const handleCancel = () => {
    resetForm();
    dispatch(assetsSliceActions.setEditAssets(null));
  };
  useEffect(() => {
    if (editAsset) {
      if (
        JSON.stringify(editAsset.beneficiaries) !==
        JSON.stringify(assetBeneficiary)
      ) {
        setDisabled(false);
      }
    }
  }, [assetBeneficiary]);
  //clean message after 2 seconds
  useEffect(() => {
    dispatch(assetsSliceActions.setEditAssets(null));
    const handleClickInsideForm = (event: MouseEvent) => {
      if (formRef.current && formRef.current.contains(event.target as Node)) {
        setMessage('');
        setErrMsg('');
      }
    };
    document.addEventListener('mousedown', handleClickInsideForm);
    return () => {
      document.removeEventListener('mousedown', handleClickInsideForm);
    };
  }, [properties]);
  useEffect(() => {
    if (message || errMessage) {
      const timer = setTimeout(() => {
        setMessage('');
        setErrMsg('');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message, errMessage]);
  const getFileFromFormData = (uploadFile: any) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setFilePreview(fileReader.result as string);
    };
    fileReader.readAsDataURL(uploadFile);
  };
  // Update form if user clicks on Edit button
  useEffect(() => {
    if (editAsset) {
      setUploadedFiles(editAsset.uploadDoc);
      let tempAutoData: any = { ...autoData };
      properties?.forEach((property) => {
        setValue(property.name as keyof IFormInput, editAsset[property.name]);
        if (
          property.name == 'name' ||
          property.name == 'city' ||
          property.name == 'state' ||
          property.name == 'country'
        ) {
          tempAutoData[property.name] = editAsset[property.name];
        }
      });
      setAutoData({ ...tempAutoData });
      setMessage('');
      setErrMsg('');
    } else {
      resetForm();
    }
  }, [editAsset]);

  // Reset form when selectedMenuItem changes
  useEffect(() => {
    reset();
  }, [assetType, reset]);
  const selectedAssets = useSelector(
    (state: any) => state.assets.assetListbasedOnSelectType
  );
  const onSubmit = async (res: any) => {
    Object.keys(autoData).map((cur: any) => {
      res[cur] = autoData[cur];
    });
    const response = { ...res, uploadDoc: uploadedFiles };
    const existingAssetIndex =
      selectedAssets && selectedAssets?.assetDetails?.length
        ? selectedAssets.assetDetails.findIndex(
            (asset: any) =>
              asset.name === res.name &&
              asset.boxNumber === res.boxNumber &&
              asset.zip === res.zip
          )
        : -1;
    if (existingAssetIndex !== -1 && existingAssetIndex !== editAsset?.id) {
      setErrMsg(t('duplicateAssets'));
    } else {
      handleOnSubmit(
        response,
        setMessage,
        resetForm,
        setErrMsg,
        onAddEditDelete
      );
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.replace(/^\s+/, ''); // Trim only leading whitespace
    setValue(event.target.name as any, trimmedValue);
    checkDisabled();
  };

  const handleDelete = () => {
    setUploadedFiles(undefined);
  };

  const handleAutoChange = (newValue: string, key: string) => {
    let tempAutoData: any = { ...autoData };
    tempAutoData[key] = newValue;
    setAutoData({ ...tempAutoData });
    checkDisabled();
  };
  const checkDisabled = () => {
    let disable = false;
    Object.values(autoData).map((cur: any) => {
      if (cur == '') {
        disable = true;
      }
    });
    if (
      watch('address') == '' ||
      watch('zip') == '' ||
      watch('boxNumber') == ''
    ) {
      disable = true;
    }
    setDisabled(disable);
  };
  return (
    <AddAssetsWrapper
      onDone={onDone}
      disabled={disabled}
      buttonText={t('doneAddingBankAccounts')}
      formHeaderText={assetFormName(assetType)}
      handleSubmit={handleSubmit(onSubmit)}
      onCancel={resetForm}
    >
      <Box
        className={isDashboard ? 'formDashBoardConatiner' : 'formBoxContainer'}
        component="form"
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        key={assetType}
      >
        <CustomAutocomplete
          name={properties![0].name}
          control={control}
          options={banks}
          value={autoData.name}
          handleAutoChange={(newValue, key) => handleAutoChange(newValue, key)}
          label={t(properties![0].label)}
          placeholder={t(properties![0].placeholder)}
          icon={bankIcon}
          handleAutoInputChange={handleAutoChange}
          error={errors.name?.message}
        ></CustomAutocomplete>

        <FormField
          name={properties![1].name}
          control={control}
          label={t(properties![1].label)}
          placeholder={t(properties![1].placeholder)}
          error={errors.address?.message}
          icon={addressIcon}
          rules={{
            required: t('required'),
            maxLength: {
              value: 20,
              message: t('maxLengthExceeded', { max: 20 }),
            },
          }}
          type="text"
          handlePhoneNumberChange={handleInputChange}
        />

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <CustomAutocomplete
              name={properties![2].name}
              control={control}
              options={cities}
              value={autoData.city}
              label={t(properties![2].label)}
              placeholder={t(properties![2].placeholder)}
              icon={cityIcon}
              handleAutoInputChange={handleAutoChange}
              handleAutoChange={(newValue, key) =>
                handleAutoChange(newValue, key)
              }
              error={errors.city?.message}
            ></CustomAutocomplete>
          </Grid>
          <Grid item xs={6}>
            <CustomAutocomplete
              name={properties![3].name}
              control={control}
              options={states}
              value={autoData.state}
              label={t(properties![3].label)}
              placeholder={t(properties![3].placeholder)}
              icon={cityIcon}
              handleAutoInputChange={handleAutoChange}
              handleAutoChange={(newValue, key) =>
                handleAutoChange(newValue, key)
              }
              error={errors.state?.message}
            ></CustomAutocomplete>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <FormField
              name={properties![4].name}
              control={control}
              label={t(properties![4].label)}
              placeholder={t(properties![4].placeholder)}
              error={errors.zip?.message}
              icon={zipIcon}
              rules={{
                required: t('required'),
                maxLength: {
                  value: 6,
                  message: t('maxLengthExceeded', { max: 6 }),
                },
              }}
              type="text"
              handlePhoneNumberChange={(e) => {
                handleNumberInputChange(e, setValue);
                checkDisabled();
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomAutocomplete
              name={properties![5].name}
              control={control}
              options={countries}
              value={autoData.country}
              label={t(properties![5].label)}
              placeholder={t(properties![5].placeholder)}
              icon={cityIcon}
              handleAutoInputChange={handleAutoChange}
              handleAutoChange={(newValue, key) =>
                handleAutoChange(newValue, key)
              }
              error={errors.country?.message}
            ></CustomAutocomplete>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2} alignItems="center">
          <Grid item xs={isDashboard ? 12 : 6}>
            <FormControl fullWidth>
              <FormField
                name={properties![6].name}
                control={control}
                label={t(properties![6].label)}
                placeholder={t(properties![6].placeholder)}
                error={errors.boxNumber?.message}
                icon={accountIcon}
                rules={{
                  required: t('required'),
                  maxLength: {
                    value: 15,
                    message: t('maxLengthExceeded', { max: 15 }),
                  },
                }}
                type="text"
                handlePhoneNumberChange={(e) => {
                  handleNumberInputChange(e, setValue);
                  checkDisabled();
                }}
              />
            </FormControl>
          </Grid>
          {!isDashboard && (
            <Grid display="flex" gap={2} item xs={6} marginTop={-3}>
              <CustomButton
                className="Custombutton"
                text={t('button.cancel')}
                buttonType="secondary"
                handleClick={() => handleCancel()}
                style={{
                  width: '47%',
                }}
              />
              <CustomButton
                text={editAsset ? t('button.save') : t('button.add')}
                disabled={disabled}
                style={{
                  width: '47%',
                }}
              />
            </Grid>
          )}
        </Grid>
        {isDashboard && (
          <Grid container alignItems="center" marginTop={-2}>
            <Grid item xs={12}>
              <Controller
                name="descriptionofContent"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={
                      <span style={{ color: '#252B42' }}>
                        {t('label.descriptionofContent')}
                      </span>
                    }
                    fullWidth
                    multiline
                    rows={3}
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&:hover fieldset': {
                          borderColor: '#0047FF',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#0047FF',
                        },
                      },
                    }}
                  />
                )}
              />
              <Typography className="labelCss">
                {t('label.photoofBoxContent')}
              </Typography>
              {!uploadedFiles && (
                <UploadFile
                  handleUploadFile={handleFileUpload}
                  className="uploadCss"
                ></UploadFile>
              )}

              {uploadedFiles && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 1,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'left' }}>
                    <Avatar
                      src={getFileIcon(uploadedFiles!.type)}
                      sx={{ width: 24, height: 24, mr: 2 }}
                    />
                    <Typography
                      variant="body1"
                      sx={{ color: '#252B42', fontWeight: 600 }}
                    >
                      {uploadedFiles!.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: '#00000099', ml: 2 }}
                    >
                      {formatFileSize(uploadedFiles!.size)}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => handleDelete()}
                    size="small"
                    sx={{ mt: -2, alignItems: 'end' }}
                  >
                    <img
                      src={DeleteIcon}
                      alt="delete"
                      style={{ width: 20, height: 20 }}
                    />
                  </IconButton>
                </Grid>
              )}
              {/* {filePreview && (
                <iframe
                  src={filePreview}
                  style={{ width: '100%', height: '400px', border: 'none' }}
                  title="File Preview"
                />
              )} */}
            </Grid>
          </Grid>
        )}

        <Grid container justifyContent="end">
          <SuccessMsgInfo successMsg={message}></SuccessMsgInfo>
          <ErrorMessageInfo errorMessage={errMessage}></ErrorMessageInfo>
        </Grid>
      </Box>
    </AddAssetsWrapper>
  );
};

export default AddSafeBoxAccount;
