import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Grid, Box, Typography, Paper, Button } from '@mui/material';
import { ReactComponent as LogoComponent } from '../../../assets/images/sh logo 1.svg';
import './DashboardHeader.css';
import { useDispatch, useSelector } from 'react-redux';
import { IUserData } from '../../../pages/auth/auth.type';
import notificationIcon from '../../../assets/images/notificationIcon.png';
import profileIcon from '../../../assets/images/dashboardIcons/menuItem1.png';
import AccountSettingsDialog from '../userProfile/userProfileDialog';
import logoutIcon from '../../../assets/images/logouticonblack.png';
import { useHttpClient } from '../../../hooks/usecorrelation';
import AuthService from '../../../pages/auth/auth.service';
import { appSliceActions } from '../../../redux-store/reducers/appReducer';
import { authSliceActions } from '../../../redux-store/reducers/authReducer';

const DashboardHeader = () => {
  const location = useLocation();
  const isDashboardRoute = location.pathname === '/dashboard';
  const httpClient = useHttpClient();
  const dispatch = useDispatch();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const userData: IUserData = useSelector((state: any) => state.auth.loginData);
  const userNameInitial = userData.userName
    ? userData.userName
        .split(' ')
        .map((part) => part.charAt(0).toUpperCase())
        .join(' ')
    : '';

  const notifications = [
    { message: 'New message from John Doe', time: '5H ago' },
    { message: 'Project update available', time: '1 day ago' },
    { message: 'Meeting scheduled for tomorrow', time: '2 days ago' },
  ];

  const toggleNotificationPopup = () => setIsNotificationOpen(false);

  const toggleProfilePopup = () => {
    if (isDashboardRoute) {
      setIsSettingsOpen(true);
      setIsProfilePopupOpen(false);
    } else {
      setIsProfilePopupOpen(!isProfilePopupOpen);
      setIsSettingsOpen(false);
    }
  };

  const handleLogout = async () => {
    dispatch(appSliceActions.setIsLoader(true));
    let res = await AuthService.logout(httpClient);
    setIsProfilePopupOpen(false);
    if (res) {
      dispatch(authSliceActions.setUserData(null));
      window.location.href = '/login';
    }
    dispatch(appSliceActions.setIsLoader(false));
  };

  return (
    <Grid container className="dashboard-header" borderBottom="none">
      <Grid item xs={6} className="dashboard-logo-container">
        <LogoComponent className="dashboard-logo" />
      </Grid>
      <Grid item xs={6} className="dashboard-profile-container">
        <Box className="dashboard-profile">
          {/* Notification Icon */}
          {/* <Box
            borderRadius="50%"
            onClick={toggleNotificationPopup}
            sx={{
              height: '25px',
              width: '25px',
              bgcolor: 'transparent',
              '&:hover': { bgcolor: '#4C65B4' },
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <img
              src={notificationIcon}
              alt="notification-icon"
              style={{ height: '20px', width: '16px' }}
            />
          </Box> */}

          {/* Username and Profile Popup */}
          <Box
            className="profile-initials"
            onClick={toggleProfilePopup}
            sx={{ cursor: 'pointer' }}
          >
            {userNameInitial}
          </Box>
          <Link
            to=""
            className="dashboard-profile-link"
            onClick={toggleProfilePopup}
          >
            {userData.userName}
          </Link>

          {/* Notification Popup */}
          {isNotificationOpen && (
            <Paper className="notification-popup" elevation={3}>
              {notifications.map((notification, index) => (
                <Box
                  key={index}
                  className="notification-item"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '8px 16px',
                  }}
                >
                  <Typography className="notificationTitle" variant="body2">
                    {notification.message}
                  </Typography>
                  <Typography
                    className="notificationTime"
                    variant="caption"
                    color="textSecondary"
                  >
                    {notification.time}
                  </Typography>
                </Box>
              ))}
            </Paper>
          )}

          {/* Profile Popup - Only shown when not on dashboard route */}
          {!isDashboardRoute && isProfilePopupOpen && (
            <Paper className="profile-popup" elevation={3}>
              <Box display="flex" alignItems="center" padding="8px">
                <img
                  src={logoutIcon}
                  alt="profile-icon"
                  style={{ height: '30px', width: '30px', marginRight: '8px' }}
                />
                <Button
                  variant="text"
                  onClick={handleLogout}
                  style={{ color: '#000' }}
                >
                  Logout
                </Button>
              </Box>
            </Paper>
          )}
        </Box>
      </Grid>

      {/* Account Settings Dialog - Only shown on dashboard route */}
      {isDashboardRoute && (
        <AccountSettingsDialog
          open={isSettingsOpen}
          onClose={() => setIsSettingsOpen(false)}
          onLogOut={handleLogout}
        />
      )}
    </Grid>
  );
};

export default DashboardHeader;
