import { TFunction } from 'i18next';
import store from '../redux-store/store';
import { authSliceActions } from '../redux-store/reducers/authReducer';
import docUploadIcon from '../assets/images/docUploadIcon.png';
import PdfIcon from '../assets/images/pdfIcon.png';
import JpgIcon from '../assets/images/jpgIcon.png';
import i18n from '../i18n';
import customBankIcon from '../assets/images/bankAvatar.png';
import customBrokerageIcon from '../assets/images/BrockerageAvatar.png';
import customPolicyIcon from '../assets/images/InsuranceAvatar.png';
import realEstateIcon from '../assets/images/realestate.png';
import customAddIcon from '../assets/images/AddAvatar.png';
import retirmentIcon from '../assets/images/retirment.png';
import safeboxIcon from '../assets/images/safebox.png';
import professionalicon from '../assets/images/professional.png';
import vehicalIcon from '../assets/images/vehical.png';
import cryptoIcon from '../assets/images/crypto.png';

export const validateEmail = (email: string, t: TFunction) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email) || t('invalid');
};

export const validateName = (name: string, t: TFunction) => {
  const nameWithSpacesRegex = /^[A-Za-z\s]+$/;
  return nameWithSpacesRegex.test(name) || t('invalidName');
};

export const validatePassword = (password: string, t: TFunction) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password); // Check for uppercase letters
  const hasLowerCase = /[a-z]/.test(password); // Check for lowercase letters
  const hasNumber = /[0-9]/.test(password); // Check for numbers
  // const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password); // Check for special characters

  if (password.length < minLength) {
    return t('minLength');
  }
  if (!hasUpperCase) {
    return t('upperCase');
  }
  if (!hasLowerCase) {
    return t('lowerCase');
  }
  if (!hasNumber) {
    return t('number');
  }
  // if (!hasSpecialChar) {
  //   return t('specialChar');
  // }
  // If all checks pass, the password is strong
  return true;
};

export const redirectLink = (onboardingCurrentPage: string) => {
  switch (onboardingCurrentPage) {
    case 'onboarding_personalization': {
      store.dispatch(authSliceActions.setActiveStep(0));
      return '/onboarding';
    }
    case 'onboarding_beneficiary': {
      store.dispatch(authSliceActions.setActiveStep(1));
      return '/onboarding';
    }
    case 'onboarding_assets': {
      store.dispatch(authSliceActions.setActiveStep(2));
      return '/onboarding';
    }
    case 'onboarding_wellness': {
      return '/dashboard';
    }
    case 'onboarding_subscription': {
      return '/dashboard';
    }
    default: {
      return '/personalize';
    }
  }
};
export const getInitials = (name: string) => {
  const initials = name
    .split(' ')
    .map((n) => n[0])
    .join('');
  return initials.toUpperCase();
};
export const formatPhoneNumber = (phoneNumberString: string) => {
  const match = checkIsMatch(phoneNumberString);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
};
export const removePhoneNumberFormatting = (phoneNumberString: string) => {
  if (phoneNumberString.startsWith('+1-')) {
    return phoneNumberString;
  }
  return '+1-' + ('' + phoneNumberString).replace(/\D/g, '');
};
export const checkIsMatch = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  return cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
};
export const validatePhoneNumber = (phoneNumber: string, t: TFunction) => {
  if (!phoneNumber || phoneNumber.length < 9) {
    return t('phoneNumberErrMsg');
  } else {
    return true;
  }
};

export const assetFormName = (assetType: string) => {
  switch (assetType) {
    case 'bankAccounts':
      return i18n.t('bankAccountInfo');
    case 'brokerageAccounts':
      return i18n.t('brokerageAccountInfo');
    case 'insurancePolicy':
      return i18n.t('insurancePolicyInfo');
    case 'realEstateAccount':
      return i18n.t('realEstateInfo');
    case 'cryptoAccounts':
      return i18n.t('cryptoAccountInfo');
    case 'trustAndWillDocuments':
      return i18n.t('trustAndWillInfo');
    case 'retirementAccounts':
      return i18n.t('retirementAccountInfo');
    case 'safeDepositAccount':
      return i18n.t('safeDepositBox');
    case 'vehicalAccount':
      return i18n.t('vehicleInfo');
    case 'professionalContactAccounts':
      return i18n.t('professionalInfo');
    case 'homeVaultInformation':
      return i18n.t('homeVaultInfo');
    default:
      return 'bankAccountInfo';
  }
};

export const validateEmptyField = (value: string, message: string) => {
  if (value.trim() === '') {
    return message;
  }
  return true;
};
export const getFileIcon = (fileType: string) => {
  if (fileType.includes('pdf')) return PdfIcon;
  if (fileType.includes('jpg') || fileType.includes('jpeg')) return JpgIcon;
  // if (fileType.includes('png')) return PngIcon;
  return docUploadIcon;
};
export const formatFileSize = (bytes: number) => {
  if (bytes < 1024) return bytes + ' B';
  else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + ' KB';
  else return (bytes / 1048576).toFixed(2) + ' MB';
};
export const handleInputChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: any
) => {
  const trimmedValue = event.target.value.replace(/^\s+/, ''); // Trim only leading whitespace
  setValue(event.target.name as any, trimmedValue);
};

export const getIcon = (assetType: string) => {
  switch (assetType) {
    case 'Bank Accounts':
      return customBankIcon;
    case 'Brokerage Accounts':
      return customBrokerageIcon;
    case 'Insurance Policy':
      return customPolicyIcon;
    case 'Retirement Accounts':
      return retirmentIcon;
    case 'Real Estate':
      return realEstateIcon;
    case 'Professional Contacts':
      return professionalicon;
    case 'Crypto Accounts':
      return cryptoIcon;
    case 'Safe Deposit Box':
      return safeboxIcon;
    case 'Vehicle Information':
      return vehicalIcon;
    default:
      return customBankIcon;
  }
};
export const formatPhoneNumberForSubmission = (phoneNumber: string) => {
  let phonenumbe1r = phoneNumber.replace(/\D/g, '');
  return `+1-${phonenumbe1r}`;
};
