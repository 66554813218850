import React, { ReactNode, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Menu,
  Typography,
} from '@mui/material';
import CustomButton from '../common/CustomButton';
import { useTranslation } from 'react-i18next';
import selectIcon from '../../assets/images/name-logo.svg';
import './addAssets.css';
import { avatarColors } from '../../utils/constants';
import { getInitials } from '../../utils/commonFunctions';
import { CheckBox } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { IAssets, IBeneficiary } from '../../pages/onboarding/onboarding.type';
import infoPng from '../../assets/images/icons/InfoOutlined.png';
import {
  assetsSliceActions,
  IAssetBeneficiary,
} from '../../redux-store/reducers/assetsreducer';
import ErrorMessageInfo from '../common/ErrorMsgInfo';
import { useLocation } from 'react-router-dom';

type Iprops = {
  children: ReactNode;
  onDone: () => void;
  disabled: boolean;
  buttonText: string;
  formHeaderText: string;
  onCancel?: () => void;
  handleSubmit?: () => void;
};
const AddAssetsWrapper: React.FC<Iprops> = ({
  children,
  onDone,
  disabled,
  buttonText,
  formHeaderText,
  onCancel,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const beneficiaries: IBeneficiary[] = useSelector(
    (state: any) => state.beneficiary.beneficieriesList
  );
  let editAsset = useSelector((state: any) => state.assets.editAsset);
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState<
    IAssetBeneficiary[]
  >([]);
  const selectedAssets: IAssets = useSelector(
    (state: any) => state.assets.assetListbasedOnSelectType
  );
  const dispatch = useDispatch();
  useEffect(() => {
    let selectedBeneficiary = [];
    if (editAsset) {
      selectedBeneficiary = editAsset.beneficiary;
      setSelectedBeneficiaries(editAsset.beneficiary);
    } else {
      selectedBeneficiary = beneficiaries.map((b) => ({
        beneficiaryId: b.beneficiaryId!,
        name: b.name,
      }));
      setSelectedBeneficiaries(selectedBeneficiary);
    }
    dispatch(assetsSliceActions.setBeneficiary(selectedBeneficiary));
  }, [editAsset, formHeaderText, selectedAssets]);
  const handleTogglePopup = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleBeneficiary = () => {
    if (editAsset) {
      setSelectedBeneficiaries(editAsset.beneficiary);
    } else {
      setSelectedBeneficiaries(
        beneficiaries.map((b) => ({
          beneficiaryId: b.beneficiaryId!,
          name: b.name,
        }))
      );
    }
  };
  const handleClosePopup = () => {
    handleBeneficiary();
    setAnchorEl(null);
  };
  // Logic to handle checkbox click for beneficiary

  const handleCheckBox = (beneficiary: IBeneficiary) => {
    const isSelected = selectedBeneficiaries.some(
      (item: IAssetBeneficiary) =>
        item.beneficiaryId === beneficiary.beneficiaryId
    );

    if (isSelected) {
      setSelectedBeneficiaries((prevSelected: IAssetBeneficiary[]) =>
        prevSelected.filter(
          (item: IAssetBeneficiary) =>
            item.beneficiaryId !== beneficiary.beneficiaryId
        )
      );
    } else {
      setSelectedBeneficiaries((prevSelected: IAssetBeneficiary[]) => [
        ...prevSelected,
        { beneficiaryId: beneficiary.beneficiaryId!, name: beneficiary.name },
      ]);
    }
  };

  const handleBeneficiaryDaone = () => {
    dispatch(assetsSliceActions.setBeneficiary(selectedBeneficiaries));
    setAnchorEl(null);
  };
  const location = useLocation();
  const isDashboard = location.pathname === '/dashboard';
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height={isDashboard ? '70vh' : '49vh'}
      className="assetsConatiner"
    >
      <Box>
        <Typography className="headerText formHeader">
          {editAsset
            ? `Edit ${formHeaderText}`
            : ` Add a New ${formHeaderText}`}
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container>
              <Grid
                item
                xs={9}
                sm={9}
                md={9}
                lg={9}
                height={isDashboard ? '55vh' : '42vh'}
                className="custom-scrollbar"
                sx={{ overflowY: 'auto' }}
              >
                {children}
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  // className="addBeneficiaryIconBox"
                >
                  <IconButton
                    className="addBeneficiaryIcon"
                    onClick={handleTogglePopup}
                  >
                    <img
                      className="addBeneficiaryImgIcon"
                      src={selectIcon}
                      alt="Select Beneficiary Icon"
                    ></img>
                  </IconButton>
                  <Typography className="addBeneficiaryIconText">
                    {t('selectBeneficiary')}
                  </Typography>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  disableEscapeKeyDown
                  hideBackdrop={false}
                  onClose={(event, reason) => {
                    if (reason === 'backdropClick') {
                      return;
                    }
                    handleClosePopup();
                  }}
                  // onClose={handleClosePopup}
                  PaperProps={{
                    sx: {
                      minWidth: '12vw',
                      maxWidth: '20vw',
                      maxHeight: '30vh',
                      display: 'flex',
                      flexDirection: 'column',
                      overflowY: 'auto',
                    },
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box>
                    {beneficiaries.map((beneficiary, index) => (
                      <Box className="beneficiaryMenuItem">
                        <Grid
                          key={index}
                          container
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid
                            item
                            xs={2}
                            display="flex"
                            justifyContent="start"
                            alignItems="start"
                          >
                            <Avatar
                              className="popupAvatar"
                              sx={{
                                backgroundColor:
                                  avatarColors[index % avatarColors.length],
                                // width: { xs: 24, md: 28 },
                                // height: { xs: 24, md: 28 },
                                // fontSize: '12px',
                                textAlign: 'center',
                              }}
                            >
                              {getInitials(beneficiary.name)}
                            </Avatar>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 'bold' }}
                              color="#252b42"
                              // fontSize="16px"
                              className="popupText"
                            >
                              {beneficiary.name}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                          >
                            <Checkbox
                              sx={{ padding: 0 }}
                              checked={selectedBeneficiaries.some(
                                (item: IAssetBeneficiary) =>
                                  item.beneficiaryId ===
                                  beneficiary.beneficiaryId
                              )}
                              onChange={() => handleCheckBox(beneficiary)}
                            />
                          </Grid>
                        </Grid>
                        <Divider sx={{ mt: 1 }}></Divider>
                      </Box>
                    ))}
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-end"
                      justifyContent="flex-end"
                      sx={{ m: 1 }}
                    >
                      {selectedBeneficiaries.length < 1 && (
                        <ErrorMessageInfo errorMessage="Select atleast one beneficiary"></ErrorMessageInfo>
                      )}
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-end"
                      justifyContent="flex-end"
                      sx={{ m: 1 }}
                    >
                      <Button
                        disabled={selectedBeneficiaries.length < 1}
                        variant="contained"
                        fullWidth={false}
                        onClick={handleBeneficiaryDaone}
                        sx={{ backgroundColor: '#112F8E' }}
                        className="popupbutton"
                      >
                        {t('button.done')}
                      </Button>
                    </Box>
                  </Box>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {!isDashboard && (
        <Box
          className="infoNote"
          bgcolor="white"
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          mt="-10px"
        >
          <Box
            className="infoImageIcon"
            component="img"
            src={infoPng}
            alt="Info Icon"
          />
          <Typography className="bottomText">
            {t('youCanEditInfoAnyTime')}
          </Typography>
        </Box>
      )}
      {isDashboard && (
        <Grid container>
          <Grid item xs={5}></Grid>
          <Grid item xs={7}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 3,
                mr: 2,
                mt: 2,
              }}
            >
              <CustomButton
                text={t('button.cancel')}
                buttonType="secondary"
                handleClick={() => onCancel && onCancel()}
              />
              <CustomButton
                text={t('button.save')}
                handleClick={() => handleSubmit && handleSubmit()}
                disabled={disabled}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default AddAssetsWrapper;
