import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';

import * as yup from 'yup';
import { getJwtToken, getToken } from './auth.service';
import store from '../redux-store/store';
import { appSliceActions } from '../redux-store/reducers/appReducer';
import { handleErrorStatusCode } from '../components/common/error-boundary/errorBoundary.service';

const createMethod = (xhr: AxiosInstance) => ({
  instance: xhr,

  post: async <D, S extends yup.SchemaOf<any>>(
    url: string,
    data: D,
    schema: S,
    config?: AxiosRequestConfig
  ): Promise<yup.InferType<S>> => {
    try {
      const response = await xhr.post(url, data, config);
      return schema
        .validate(response.data, { stripUnknown: true })
        .then(() => {
          return response.data;
        })
        .catch((e) => {
          return response.data;
        });
    } catch (err: any) {
      store.dispatch(appSliceActions.setIsLoader(false));
      // handleErrorStatusCode(err);
      return err.data;
    }
  },

  get: async <D, S extends yup.SchemaOf<any>>(
    url: string,
    data: D,
    schema: S,
    config?: AxiosRequestConfig
  ): Promise<yup.InferType<S>> => {
    try {
      const response = await xhr.get(url, {
        ...config,
        params: data,
      });
      return schema
        .validate(response.data, { stripUnknown: true })
        .then(() => {
          return response.data;
        })
        .catch((e) => {
          throw e.message;
        });
    } catch (errc: any) {
      if (errc?.status === 404) {
        return { data: [], Message: errc.data.Message };
      }
      throw errc;
    }
  },

  put: async <D, S extends yup.SchemaOf<any>>(
    url: string,
    data: D,
    schema: S,
    config?: AxiosRequestConfig
  ): Promise<yup.InferType<S>> => {
    try {
      const response = await xhr.put(url, data, config);
      console.log(response, 'index');
      return response.data;
      // return schema
      //   .validate(response.data)
      //   .then(() => {
      //     return response.data;
      //   })
      //   .catch((e) => {
      //     throw e.message;
      //   });
    } catch (err: any) {
      store.dispatch(appSliceActions.setIsLoader(false));
      return err.data;
    }
  },

  patch: async <D, S extends yup.SchemaOf<any>>(
    url: string,
    data: D,
    schema: S,
    config?: AxiosRequestConfig
  ): Promise<yup.InferType<S>> => {
    try {
      const response = await xhr.patch(url, data, config);
      console.log(response, 'index');
      return response.data;
      // return schema
      //   .validate(response.data)
      //   .then(() => {
      //     return response.data;
      //   })
      //   .catch((e) => {
      //     throw e.message;
      //   });
    } catch (err) {
      return null;
    }
  },

  delete: async <D, S extends yup.SchemaOf<any>>(
    url: string,
    data: D,
    schema: S,
    config?: AxiosRequestConfig
  ): Promise<yup.InferType<S>> => {
    try {
      const response = await xhr.delete(url, { ...config, data });
      return schema
        .validate(response.data, { stripUnknown: true })
        .then(() => {
          return response.data;
        })
        .catch((e: any) => {
          throw e.message;
        });
    } catch (err) {
      return null;
    }
  },
});

export const httpClient = (
  correlationId: string,
  isBasicAuth: boolean = false
): any => {
  const baseURL = process.env.REACT_APP_API_ENDPOINT;
  const xhr = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
    transformRequest: [
      (data) => {
        // This is for uploading files
        if (data instanceof FormData) {
          return data;
        }
        return JSON.stringify(data);
      },
    ],
  });

  xhr.interceptors.request.use(async (config: any) => {
    const token = await getToken();
    const jwtToken = await getJwtToken();
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.jwtToken = `${jwtToken}`;
    return config;
  });

  // We'll override Axios default error handler
  xhr.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        try {
          window.location.href = '/login'; // Retry the original request with the new access token.
        } catch (refreshError) {
          // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
          console.error('Token refresh failed:', refreshError);
          localStorage.removeItem('accessToken');
          window.location.href = '/login';
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error.response || error);
    }
  );
  return createMethod(xhr);
};
