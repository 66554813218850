import React, { useEffect, useState } from 'react';

import { Box, Divider, Grid, Link, Typography } from '@mui/material';
import i18n from '../../i18n';
import './wellnessCheck.css';
import { useDispatch } from 'react-redux';
import { beneficiarySliceActions } from '../../redux-store/reducers/beneficiaryReducer';
import congoIcon from '../../assets/images/congo.png';
import emailIcon from '../../assets/images/beniemails.png';
import peoplesIcon from '../../assets/images/peoplesIcon.png';
import SampleEmailOne from './sampleEmailOne';
import SampleEmailTwo from './sampleEmailTwo';
const Congratulation = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      beneficiarySliceActions.setOnboardingScreenTime(new Date().toISOString())
    );
  }, []);
  const [isEmailOpen, setIsEmailOpen] = useState(false);
  const [isEmailOpen2, setIsEmailOpen2] = useState(false);

  return (
    <Box className={'congo-container'}>
      <Box>
        <Box>
          <img className="congoIcon" src={congoIcon} alt="congo" />
        </Box>
        <div className="congoTextOne headerPadding font32 service-text">
          {i18n.t('congo.congoHeaderText')}
        </div>
      </Box>

      <Box className="">
        <div
          className="service-text boxHeaderFont"
          // style={{ marginTop: '50px' }}
        >
          {i18n.t('congo.serviceText')}
        </div>
      </Box>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} md={4} className="main-content">
          <Box>
            <img className="congoIcons" src={emailIcon} alt="email" />
          </Box>

          <Box className="congo-emailText">
            <div className="congo-text boxTextFont">
              {i18n.t('congo.emailText')}
            </div>
            <div
              onClick={() => setIsEmailOpen(true)}
              style={{
                cursor: 'pointer',
              }}
              className="congo-text boxTextFont sample-text"
            >
              {i18n.t('congo.sampleEmailText')}
            </div>
          </Box>
        </Grid>
        <SampleEmailOne
          open={isEmailOpen}
          onClose={() => setIsEmailOpen(false)}
        />
        <SampleEmailTwo
          open={isEmailOpen2}
          onClose={() => setIsEmailOpen2(false)}
        />

        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className="congo-divider"
        />

        <Grid item xs={12} md={4} className="main-content">
          <Box>
            <img className="congoIcons" src={peoplesIcon} alt="peoples" />
          </Box>

          <Box className="congo-emailText">
            <div className="congo-text boxTextFont">
              {i18n.t('congo.peopleText')}
            </div>
            <div
              onClick={() => setIsEmailOpen2(true)}
              style={{
                cursor: 'pointer',
              }}
              className="congo-text boxTextFont sample-text"
            >
              {i18n.t('congo.sampleEmailText')}
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Congratulation;
