import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // default is localStorage for web
import appReducer from './reducers/appReducer';
import authReducer from './reducers/authReducer';
import beneficiaryReducer from './reducers/beneficiaryReducer';
import assetsreducer from './reducers/assetsreducer';
import subscriptionReducer from './reducers/subscriptionReducer';
import professionReducer from './reducers/professionReducer';
import documentUploadReducer from './reducers/documentUploadReducer';

// Define the persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // Choose which reducers to persist
};

// Combine reducers
const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  beneficiary: beneficiaryReducer,
  assets: assetsreducer,
  subscription: subscriptionReducer,
  profession: professionReducer,
  documentUpload: documentUploadReducer,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
const store = configureStore({
  reducer: persistedReducer,
});

// Create a persistor to control persistence
export const persistor = persistStore(store);

// Export the store
export default store;
