import { createSlice } from '@reduxjs/toolkit';
import { IUserData } from '../../pages/auth/auth.type';

export type IInitialState = {
  loginData: IUserData | null;
  accessToken: string;
  loginErrorMessage: string;
  activeStep: number;
};
const initialState: IInitialState = {
  loginData: null,
  accessToken: '',
  loginErrorMessage: '',
  activeStep: 0,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData(state, { payload }) {
      state.loginData = payload;
    },
    setUserAccessToken(state, { payload }) {
      state.accessToken = payload;
    },
    setLoginErrorMessage(state, { payload }) {
      state.loginErrorMessage = payload;
    },
    setActiveStep(state, { payload }) {
      state.activeStep = payload;
    },
  },
});
const { actions } = authSlice;
export const authSliceActions = actions;
export default authSlice.reducer;
