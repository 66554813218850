import React from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  styled,
  Paper,
  Link,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../assets/images/sh_logo.svg';
import { useTranslation } from 'react-i18next';

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '1760px',
    height: '800px',
    maxWidth: '1760px',
    maxHeight: '940px',
    borderRadius: '20px',
    padding: theme.spacing(4),
    margin: theme.spacing(2),
    backgroundColor: '#FFFFFF',
  },
}));

const SampleBadge = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FDE3AA',
  padding: theme.spacing(1),
  display: 'inline-block',
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(3),
}));

const Logo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  height: '100%',
  paddingRight: theme.spacing(2),
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#555',
    },
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: '#1976d2',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

interface SampleEmailTwoProps {
  open: boolean;
  onClose: () => void;
}

const SampleEmailTwo: React.FC<SampleEmailTwoProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="email-dialog-title"
    >
      <DialogContent sx={{ padding: 0, overflow: 'hidden' }}>
        <ContentWrapper>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: '#666',
              '&:hover': {
                color: '#000',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Logo>
            <img src={logo} alt="Logo" />
          </Logo>

          <SampleBadge elevation={0}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 600,
                color: '#666',
              }}
            >
              {t('sampleEmail')}
            </Typography>
          </SampleBadge>

          <Box sx={{ color: '#4A4A4A' }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 600,
                mb: 3,
              }}
            >
              {t('subjectImportant')}
            </Typography>

            <Typography variant="body1" sx={{ mb: 3 }}>
              {t('dearBeneficiary')}
            </Typography>

            <Typography variant="body1" sx={{ mb: 3 }}>
              {t('hopeMessage')}
            </Typography>

            <Typography
              sx={{
                fontWeight: 700,
                mb: 2,
                color: '#2F3751',
              }}
            >
              {t('whatNeedToKnow')}
            </Typography>

            <Box component="ul" sx={{ pl: 2, mb: 4 }}>
              <li>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {t('alreadySetUp')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {t('beneficiaryAccess')}
                </Typography>
              </li>
            </Box>

            <Typography variant="body1" sx={{ mb: 3, fontWeight: 500 }}>
              <StyledLink href="#">{t('clickHereToAccess')}</StyledLink>
            </Typography>

            <Typography variant="body1" sx={{ mb: 3 }}>
              {t('anyQuestionsContact')}{' '}
              <StyledLink href="mailto:support@smartheritance.com">
                <strong>{t('supportEmail')}</strong>
              </StyledLink>
              .
            </Typography>

            <Typography variant="body1" sx={{ mb: 4 }}>
              {t('appreciateAttention')}
            </Typography>

            <Typography variant="body1" sx={{ mb: 2 }}>
              {t('warmRegards')}
            </Typography>

            <Typography variant="body1" sx={{ mb: 2, fontWeight: 500 }}>
              {t('smartHeritanceTeam')}
            </Typography>

            <Typography variant="body1" sx={{ color: '#666' }}>
              {t('websiteLink')}
            </Typography>
          </Box>
        </ContentWrapper>
      </DialogContent>
    </StyledDialog>
  );
};

export default SampleEmailTwo;
