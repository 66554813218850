import '@fontsource/roboto';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/400-italic.css';
import ForgotPassword from '../pages/auth/ForgotPassword';
import LoginForm from '../pages/auth/LoginForm';
import SetPassword from '../pages/auth/SetPassword';
import SignUpForm from '../pages/auth/SignUpForm';
import Onboarding from '../pages/onboarding/Onboarding';
import Personalize from '../pages/personalize-question/Personalize';
import DashboardComponent from '../pages/dasboard/dasboard';

export const routes = [
  {
    path: '/',
    component: SignUpForm,
    title: 'home',
  },
  {
    path: '/login',
    component: LoginForm,
    title: 'login',
  },
  {
    path: '/signup',
    component: SignUpForm,
    title: 'signup',
  },
  {
    path: '/forgotPassword',
    component: ForgotPassword,
    title: 'forgotPass',
  },
  {
    path: '/setNewPassword',
    component: SetPassword,
    title: 'setUpNew',
  },

  {
    path: '/onboarding',
    component: Onboarding,
    title: 'onbording',
  },

  {
    path: '/personalize',
    component: Personalize,
    title: 'personalize',
  },
  {
    path: '/dashboard',
    component: DashboardComponent,
    title: 'dashboard',
  },
];
